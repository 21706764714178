import { useEffect, useRef, useState } from "react";

import styles from "./QuestionSettings.module.scss";
import designStyles from "../SurveyDesign/SurveyDesign.module.scss";
import Button from "components/Button/Button";
import { Label } from "components/layouts/Label/Label";
import { ChoiceSettings } from "./Choice/ChoiceSettings";
import { TextSettings } from "./Text/TextSettings";
import { ScaleSettings } from "./Scale/ScaleSettings";
import { MatrixSettings } from "./Matrix/MatrixSettings";
import CombinedInput from "components/inputs/input_fields/CombinedInput/CombinedInput";
import FlexRow from "components/layouts/FlexRow/FlexRow";
import EditTags from "../EditTags/EditTags";
import Buckets from "../Buckets/Buckets";
import Icon from "components/Icon/Icon";
import { QuestionLogic } from "../QuestionLogic/QuestionLogic";
import { SelectFieldCustom } from "components/inputs";

export const questionTypes = [
  {
    label: "Multiple Choice",
    icon: "list-ul",
    value: "MultipleChoice",
  },

  {
    label: "Free Response",
    icon: "pencil",
    value: "Text",
  },

  {
    label: "Number Scale",
    icon: 123,
    value: "NumberScale",
  },
  {
    label: "Ranking",
    icon: "list-ol",
    value: "Ranking",
  },
  {
    label: "Number Slider",
    icon: "sliders",
    value: "NumberSlider",
  },
  {
    label: "Matrix",
    icon: "bar-chart-steps",
    value: "Matrix",
  },
];

export const QuestionSettings = ({
  question,
  onEditQ,
  onEditQs,
  onCancel,
  dependents,
  setUpLogicResolver,
  onRetract,
  allQuestions,
  setShowTriggers,
}) => {
  const [showLogic, setShowLogic] = useState(false);

  const sectionTypes = {
    FormRegistration: {
      label: "Form Registration",
      value: "Form Registration",
      name: "Form Registration",
      icon: <i className="bi bi-list-check"></i>,
    },
    Description: {
      label: "Description",
      value: "Description",
      name: "Description",
      icon: <i className="bi bi-chat-left-text"></i>,
    },
  };

  function handleChangeType(type) {
    if (question.type !== type) {
      if (dependents) {
        setUpLogicResolver(
          "change the type of question on",
          "edit",
          question,
          () => finishChangingType(type)
        );
      } else {
        finishChangingType(type);
      }
    } else if (
      question.choiceQuestion &&
      type === "MultipleChoice" &&
      question.choiceQuestion.isRanking
    ) {
      // won't affect dependents
      finishChangingType(type);
    }
  }

  function finishChangingType(type) {
    let oldType = question.type;
    question.type = type;
    if (type === "MultipleChoice") {
      question.choiceQuestion = {
        isMultiSelect: false,
        isRandomized: false,
        hasOtherOption: false,
        isRanking: false,
        otherOptionLabel: "Other",
        limit: null,
        choices: question.choiceQuestion?.choices
          ? question.choiceQuestion.choices
          : [],
      };
      question.scaleQuestion = null;
      question.textQuestion = null;
      question.matrixQuestion = null;
    } else if (type === "NumberScale" || type === "NumberSlider") {
      question.scaleQuestion = {
        min: 1,
        minDescription: "",
        max: 5,
        maxDescription: "",
        step: 1,
      };
      question.choiceQuestion = null;
      question.textQuestion = null;
    } else if (type === "Text") {
      question.textQuestion = {
        placeholder: "Enter Text",
        maxLength: 1000,
        autoBucket: true,
        bucket: [],
      };
      question.choiceQuestion = null;
      question.scaleQuestion = null;
      question.matrixQuestion = null;
    } else if (type === "Ranking") {
      question.choiceQuestion = {
        isMultiSelect: false,
        isRandomized: false,
        isRanking: true,
        hasOtherOption: false,
        otherOptionLabel: "Other",
        limit: null,
        choices: question.choiceQuestion?.choices
          ? question.choiceQuestion.choices
          : [],
      };
      question.type = "MultipleChoice";
      question.scaleQuestion = null;
      question.textQuestion = null;
      question.matrixQuestion = null;
    } else if (type === "Matrix") {
      question.matrixQuestion = {
        options: [],
        settings: {},
        type: oldType,
      };
    }
    question.isScore = false;
    question.isComment = false;
    question.questionTag = [];

    onEditQ(question);
    onRetract(question.id);
  }

  const currentType =
    question.choiceQuestion &&
    question.choiceQuestion?.isRanking &&
    !question.matrixQuestion
      ? questionTypes.find((t) => t.value === "Ranking")
      : questionTypes.find((t) => t.value === question.type);

  const [groups, setGroups] = useState();

  const [triggers, setTriggers] = useState();

  useEffect(() => {
    if (question?.triggers) {
      setTriggers(JSON.parse(JSON.stringify(question?.triggers)));
    } else {
      setTriggers([]);
    }

    if (showLogic) setShowLogic(false);
  }, [question]);

  function hasLogic() {
    if (question.conditionGroups?.length) return true;

    const downstream = [];
    const upstream = [];
    const thisInd = allQuestions.findIndex((q) => q.id === question.id);
    for (let i = 0; i < allQuestions.length; i++) {
      if (i !== thisInd) {
        let q = allQuestions[i];

        if ("questionText" in q && i < thisInd) {
          upstream.push(q);
        }

        if (i > thisInd) {
          downstream.push(q);
        }
      }
    }

    for (let comp of downstream) {
      if (comp.conditionGroups) {
        for (let g of comp.conditionGroups) {
          for (let c of g.conditions) {
            if (c.questionId === question.id) {
              return true;
            }
          }
        }
      }
    }

    return false;
  }

  const [isExpanded, setIsExpanded] = useState(false);

  const toggleAccordion = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className={designStyles.body} style={{ paddingBottom: "20rem" }}>
      {/* <div
        className={styles.section_label6}
        style={{
          margin: "0",
          width: "fit-content",
          color: "#15bcc7",
          padding: " 0rem 1rem",
        }}
      >
        Question Settings
        <HorizontalBar
          height={2}
          style={{ marginTop: ".1rem" }}
          backgroundColor={"#15bcc7"}
        ></HorizontalBar>
      </div> */}
      <div
        className={styles.label5}
        style={{
          margin: "0",
          width: "fit-content",
          padding: " 0rem 1rem",
          fontWeight: "500",
        }}
      >
        Question Settings
      </div>
      <div className={designStyles.settingVertical}>
        <SelectFieldCustom
          label="Question Type"
          value={currentType}
          options={questionTypes}
          icon={<i className={`bi-${currentType.icon}`}></i>}
          select
          onChange={(t) => handleChangeType(t?.value)}
          // icon={"bi-chevron-down"}
        />
      </div>
      {question.type === "MultipleChoice" && (
        <div className={designStyles.settingVertical}>
          <ChoiceSettings question={question} saveQuestion={onEditQ} />{" "}
        </div>
      )}
      {question.type === "Text" && (
        <div className={designStyles.settingVertical}>
          <TextSettings
            question={question}
            saveQuestion={onEditQ}
            dependents={dependents}
            setUpLogicResolver={setUpLogicResolver}
          />
        </div>
      )}
      {(question.type === "NumberScale" ||
        question.type === "NumberSlider") && (
        <div className={designStyles.settingVertical}>
          <ScaleSettings
            question={question}
            saveQuestion={onEditQ}
            dependents={dependents}
            setUpLogicResolver={setUpLogicResolver}
          />
        </div>
      )}
      {question.type === "Matrix" && (
        <div className={designStyles.settingVertical}>
          <MatrixSettings
            question={question}
            saveQuestion={onEditQ}
            dependents={dependents}
            setUpLogicResolver={setUpLogicResolver}
            onRetract={onRetract}
          />
        </div>
      )}
      {"questionText" in question && (
        <EditTags
          question={question}
          saveQuestion={onEditQ}
          allQuestions={allQuestions}
        />
      )}
      {/* Question Logic */}
      <div
        className={styles.body}
        style={showLogic ? { padding: "0px" } : undefined}
      >
        {!showLogic && (
          <>
            <FlexRow start gap={0}>
              <Icon iconName={"signpost-split-fill"} seafoam></Icon>
              <div
                className={styles.label5}
                style={{ margin: "0", fontWeight: "500" }}
              >
                Logic
              </div>
            </FlexRow>
            {hasLogic() && (
              <FlexRow start gap={0}>
                <Icon iconName={"check-circle"} green></Icon>
                <div
                  className={styles.section_label6}
                  style={{ margin: "0", fontWeight: "500" }}
                >
                  Has Logic
                </div>
              </FlexRow>
            )}
            <FlexRow style={{ justifyContent: "center" }} gap={0}>
              <Button shadow onClick={() => setShowLogic(true)}>
                Edit Logic
              </Button>
            </FlexRow>
          </>
        )}

        {showLogic && (
          <QuestionLogic
            question={question}
            onEditQ={onEditQ}
            allQuestions={allQuestions}
            onEditQs={onEditQs}
          />
        )}
      </div>

      {/* Question Alerts */}
      <div className={styles.body}>
        <FlexRow start gap={0}>
          <Icon iconName={"bell-fill"} seafoam></Icon>
          <div
            className={styles.label5}
            style={{ margin: "0", fontWeight: "500" }}
          >
            Answer Alerts
          </div>
        </FlexRow>
        {triggers && triggers.length > 0 && (
          <FlexRow start gap={0}>
            <Icon iconName={"check-circle"} green></Icon>
            <div
              className={styles.section_label6}
              style={{ margin: "0", fontWeight: "500" }}
            >
              Has Alerts
            </div>
          </FlexRow>
        )}
        <FlexRow style={{ justifyContent: "center" }} gap={0}>
          <Button shadow onClick={() => setShowTriggers(true)}>
            Edit Alerts
          </Button>
        </FlexRow>
      </div>
      {"questionText" in question && question?.textQuestion && (
        <Buckets question={question} close={undefined} status={""} />
      )}
      {question?.new && (
        <div
          style={{
            bottom: "0",
            right: "0",
            display: "flex",
            paddingTop: "1em",
            justifyContent: "space-between",
            flexDirection: "row-reverse",
            gap: "1em",
            alignItems: "end",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              gap: "1em",
            }}
          >
            <Button red shadow onClick={() => onCancel(question)}>
              Cancel
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export function ChangeQuestionType({ types, current, onSelect, label }) {
  const [show, setShow] = useState(false);

  const ref = useRef(null);

  return (
    <div className={styles.questionTypesContainer}>
      <Label
        style={{
          fontWeight: "600",
          fontSize: ".85em",
          paddingLeft: "1em",
          paddingBottom: "3px",
          textTransform: "none",
        }}
      >
        {label}
      </Label>
      <div
        className={`${styles.questionType} ${show ? styles.active : ""}`}
        onClick={() => setShow(!show)}
      >
        <div className={styles.type} ref={ref}>
          {current.label}
          {current.icon}
        </div>

        <div className={styles.select}>
          <i className="bi bi-chevron-down"></i>
        </div>
      </div>

      {show && (
        <ChangeTypeMenu
          types={types}
          onSelect={(val) => {
            onSelect(val);
            setShow(false);
          }}
          current={current}
          close={() => setShow(false)}
          topRef={ref}
        />
      )}
    </div>
  );
}

function ChangeTypeMenu({ types, current, onSelect, close, topRef }) {
  function onClickOut(e) {
    if (
      ref.current &&
      !ref.current.contains(e.target) &&
      topRef.current &&
      !topRef.current.contains(e.target)
    ) {
      close();
      document.removeEventListener("click", onClickOut, true);
      e.stopPropagation();
    }
  }

  useEffect(() => {
    setTimeout(() => document.addEventListener("click", onClickOut, true), 250);
    return () => document.removeEventListener("click", onClickOut, true);
  }, []);

  const ref = useRef();

  return (
    <div className={styles.optionsAnchor}>
      <div className={styles.options} ref={ref}>
        {types.map((type) => (
          <>
            {type.value !== current.value && (
              <div
                className={styles.option}
                onClick={() => onSelect(type.value)}
              >
                {type.label}
                {type.icon}
              </div>
            )}
          </>
        ))}
      </div>
    </div>
  );
}
