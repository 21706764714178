import Modal from "components/ReactModal/ReactModal";
import styles from "./AddContact.module.scss";
import {
  SelectField,
  SelectFieldCustom,
  TextFieldSimple,
} from "components/inputs";
import { useEffect, useState } from "react";

import blankProfile from "assets/images/blank-profile-picture.png";
import Button from "components/Button/Button";
import FlexRow from "components/layouts/FlexRow/FlexRow";
import { validateEmail } from "assets/functions/StringFunctions";
import { useFetchCurrOrgsFields } from "api/resources/organization/organization";
import { Loading } from "components/Loading/Loading";
import {
  useAddContact,
  useValidateEmailAvailable,
} from "api/resources/contacts/contacts";
import Checkbox from "components/inputs/input_fields/CheckboxBlue/Checkbox";
import TextEditorPlain from "components/inputs/input_fields/TextEditorPlain/TextEditorPlain";

export function AddContact({ onClose, org, audienceId, refetch }) {
  const [contact, setContact] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    columns: {},
  });
  const [adding, setAdding] = useState(false);
  const [newColumns, setNewColumns] = useState([]);
  const [addField, setAddField] = useState(false);
  const [editCol, setEditCol] = useState();

  const fetchColumns = useFetchCurrOrgsFields();
  const addContact = useAddContact();
  const checkAvailable = useValidateEmailAvailable(
    validateEmail(contact.email) ? contact.email : ""
  );

  function editField(field, val) {
    let copy = { ...contact };
    copy[field] = val;
    setContact(copy);
  }

  const fields = [
    { label: "Email*", value: "email", icon: "at" },
    { label: "Phone", value: "phone", icon: "telephone" },
  ];
  const fieldsName = [
    { label: "First Name", value: "firstName" },
    { label: "Last Name*", value: "lastName" },
  ];

  function onEnter(i) {
    if (i < fields.length - 1) {
      let input = document.getElementById(
        "contactInput: " + fields[i + 1].value
      );
      if (input) {
        input.focus();
      }
    }
  }

  function changeColumns(column, change) {
    let copy = { ...contact };
    copy.columns[column.id] = change;
    setContact(copy);
  }

  function onAdd() {
    let attributeIds = [];
    let newAtts = [];
    let newCols = [];

    for (let col of newColumns) {
      let column = {
        name: col.name,
        multiple: col.multiple,
        filterable: col.filterable,
        attributes: col.attribute.map((a) => {
          return {
            name: a,
            attatch: false,
          };
        }),
      };
      newCols.push(column);
    }

    function add(a, id) {
      a.__isNew__
        ? newAtts.push({ customFieldId: id, name: a.value })
        : attributeIds.push(a.value);
    }

    function addNew(a, newCol) {
      let att = newCol.attributes.find((newAtt) => newAtt.name === a.value);
      if (a.__isNew__ || !att) {
        newCol.attributes.push({
          name: a.value,
          attatch: true,
        });
      } else {
        att.attatch = true;
      }
    }

    for (let id in contact.columns) {
      let obj = contact.columns[id];

      const newCol = newCols.find((c) => c.name === id);
      if (newCol) {
        if (Array.isArray(obj)) {
          for (let a of obj) {
            addNew(a, newCol);
          }
        } else {
          addNew(obj, newCol);
        }
      } else {
        // normal column
        if (Array.isArray(obj)) {
          for (let a of obj) {
            add(a, id);
          }
        } else {
          add(obj, id);
        }
      }
    }

    addContact.mutate(
      {
        contact: {
          firstName: contact.firstName,
          lastName: contact.lastName,
          email: contact.email,
          phone: contact.phone,
        },
        attributeData: {
          attributeIds: attributeIds,
        },
        newAttData: {
          attributes: newAtts,
        },
        newColumns: {
          columns: newCols,
        },
        audienceId: audienceId ? audienceId : "",
      },
      {
        onSuccess: (data) => {
          if (data.addContact) {
            console.log("contact added!");
            if (refetch) {
              refetch();
            }
            onClose();
          }
        },
        onError: () => {
          console.log("error adding contact");
          setAdding(false);
        },
      }
    );

    setAdding(true);
  }

  function getInUseByName() {
    let name = "";
    if (checkAvailable.isSuccess) {
      let inUseBy = checkAvailable.data.inUseBy;
      if (inUseBy) {
        if (inUseBy.firstName) name += inUseBy.firstName;
        if (inUseBy.lastName) {
          if (name) name += " ";
          name += inUseBy.lastName;
        }
      }
    }

    return name;
  }

  function addNewColumn(column) {
    let copy = [...newColumns];

    if (editCol) {
      let ind = copy.findIndex((col) => col.name === editCol.name);
      copy[ind] = { ...column, id: column.name };

      if (contact.columns[editCol.name]) {
        let obj = contact.columns[editCol.name];
        delete contact.columns[editCol.name];

        let contactCopy = { ...contact };

        if (Array.isArray(obj)) {
          if (column.multiple) {
            contactCopy.columns[column.name] = [];
            for (let a of obj) {
              if (column.attribute.includes(a.value)) {
                contactCopy.columns[column.name].push(a);
              }
            }
          } else {
            for (let a of obj) {
              if (column.attribute.includes(a.value)) {
                contactCopy.columns[column.name] = a;
                break;
              }
            }
          }
        } else {
          if (column.attribute.includes(obj.value)) {
            contactCopy.columns[column.name] = column.multiple ? [obj] : obj;
          }
        }

        setContact(contactCopy);
      }
    } else {
      copy.push({ ...column, id: column.name });
    }

    setNewColumns(copy);
    closeAddField();
  }

  function closeAddField() {
    setAddField(false);
    setEditCol();
  }

  function setUpEdit(col) {
    setEditCol(col);
    setAddField(true);
  }

  function onDeleteNewCol() {
    let cols = [...newColumns];
    let ind = cols.findIndex((col) => col.name === editCol.name);
    if (ind > -1) cols.splice(ind, 1);

    if (contact.columns[editCol.name]) {
      let copy = { ...contact };
      delete copy.columns[editCol.name];
      setContact(copy);
    }

    setNewColumns(cols);
    closeAddField();
  }

  const valid =
    (contact.firstName || contact.lastName) &&
    validateEmail(contact.email) &&
    checkAvailable.isSuccess &&
    !checkAvailable.data.inUseBy;

  return (
    <>
      <Modal
        onClose={onClose}
        show
        modalStyle={{
          borderRadius: "7px",
          height: "100%",
          width: "800px",
        }}
        dark
      >
        <div className={styles.container} id="New Contact Input">
          <div className={styles.header}>
            <img src={blankProfile} className={styles.profile} />

            <div
              className={`${styles.header_3} ${styles.title}`}
              style={{ margin: "0" }}
            >
              New Contact Input
            </div>
          </div>

          <div className={styles.subtitle} style={{ marginBottom: "-5px" }}>
            Basic Information
          </div>

          {fields.map((field, i) => {
            const emailInUse =
              field.value === "email" &&
              checkAvailable.isSuccess &&
              checkAvailable.data.inUseBy;

            const invalid =
              contact.email &&
              field.value === "email" &&
              !validateEmail(contact.email);

            return (
              <TextFieldSimple
                label={field.label}
                valid={!emailInUse && !invalid}
                invalidMessage={
                  emailInUse
                    ? `Email in use by ${getInUseByName()}`
                    : invalid
                    ? "Invalid email address"
                    : ""
                }
                onChange={(val) => editField(field.value, val)}
                onEnter={() => onEnter(i)}
                value={contact[field.value]}
                id={"contactInput: " + field.value}
                type={field.value}
                icon={field?.icon}
              />
            );
          })}
          <FlexRow>
            {" "}
            {fieldsName.map((field, i) => {
              return (
                <TextFieldSimple
                  label={field.label}
                  onChange={(val) => editField(field.value, val)}
                  onEnter={() => onEnter(i)}
                  value={contact[field.value]}
                  id={"contactInput: " + field.value}
                  // type={field.value}
                  icon={"person-circle"}
                />
              );
            })}
          </FlexRow>

          {fetchColumns.isLoading && <Loading height={100} width={100} />}
          {fetchColumns.isSuccess && (
            <>
              {fetchColumns.data.getCurrOrgFields.length > 4 && (
                <AddButton onAdd={onAdd} adding={adding} valid={valid} />
              )}
              <div className={styles.subtitle} style={{ marginBottom: "-5px" }}>
                Custom Information
              </div>
              {fetchColumns.data.getCurrOrgFields.map((column) =>
                !column.generic ? (
                  <SelectFieldCustom
                    label={
                      column.displayName ? column.displayName : column.name
                    }
                    options={column.attribute.map((a) => {
                      return {
                        label: a.name,
                        value: a.id,
                      };
                    })}
                    selectMultiple={column.multiple}
                    onChange={(change) => changeColumns(column, change)}
                    value={contact.columns[column.id]}
                    containerStyle={{ fontSize: ".85rem" }}
                    searchable
                    placeholder={""}
                    icon={column.icon ? <i className={`bi-${column?.icon}`}></i> : undefined}
                  />
                ) : (
                  ""
                )
              )}

              {newColumns.map((column) => (
                <div className={styles.newColumn}>
                  <SelectField
                    label={column.name}
                    options={column.attribute.map((a) => {
                      return {
                        label: a,
                        value: a,
                      };
                    })}
                    selectMultiple={column.multiple}
                    onChange={(change) => changeColumns(column, change)}
                    value={
                      contact.columns[column.name]
                        ? contact.columns[column.name]
                        : null
                    }
                    containerStyle={{ fontSize: ".85rem" }}
                    isSearchable
                    placeholder={""}
                  />
                  <div
                    className={styles.editCol}
                    onClick={() => setUpEdit(column)}
                  >
                    Edit
                  </div>
                </div>
              ))}

              {/* {!addField && (
                <div
                  className={styles.addField}
                  onClick={() => setAddField(true)}
                >
                  + Add Field
                </div>
              )} */}
              {addField && (
                <NewColumn
                  addNewColumn={addNewColumn}
                  close={closeAddField}
                  orgFields={[
                    ...fetchColumns.data.getCurrOrgFields,
                    ...newColumns,
                  ]}
                  init={editCol}
                  onDelete={onDeleteNewCol}
                />
              )}
            </>
          )}

          <AddButton onAdd={onAdd} adding={adding} valid={valid} />

          <div id="bottom of container"></div>
        </div>
      </Modal>
    </>
  );
}

function AddButton({ onAdd, adding, valid }) {
  return (
    <FlexRow
      justify={"center"}
      style={{
        padding: "10px 0px",
      }}
    >
      <Button shadow onClick={onAdd} disable={!valid}>
        <FlexRow gap={"5px"}>
          {adding ? (
            <>
              <Loading height={20} width={20} /> Adding
            </>
          ) : (
            <>
              <i className="bi-plus"></i> Add
            </>
          )}
        </FlexRow>
      </Button>
    </FlexRow>
  );
}

function NewColumn({ addNewColumn, close, orgFields, init, onDelete }) {
  useEffect(() => {
    let container = document.getElementById("New Contact Input");
    if (container) {
      if (container.scrollHeight > container.clientHeight) {
        // container.scrollTop = container.scrollHeight - container.clientHeight;
        let bottom = document.getElementById("bottom of container");
        if (bottom)
          bottom.scrollIntoView({ behavior: "smooth", inline: "end" });
      }
    }
  }, []);

  const [newField, setNewField] = useState(
    init
      ? { ...init }
      : {
          name: "",
          multiple: false,
          filterable: true,
          attribute: [],
        }
  );

  function editNew(field, val) {
    let copy = { ...newField };
    copy[field] = val;
    setNewField(copy);
  }

  function editAtt(i, val) {
    let list = [...newField.attribute];
    if (val) {
      list[i] = val;
    } else {
      list.splice(i, 1);
    }

    editNew("attribute", list);
  }

  function removeAtt(i) {
    let list = [...newField.attribute];
    list.splice(i, 1);
    editNew("attribute", list);
  }

  function addAtt(i) {
    let list = [...newField.attribute];
    list.splice(i, 0, "");
    editNew("attribute", list);

    setTimeout(() => {
      let input = document.getElementById("att num " + i);
      if (input) {
        input.focus();
      }
    }, 100);
  }

  function onEnter(i, val) {
    if (val) {
      addAtt(i + 1);
    }
  }

  function onDeleteEmpty(e, i) {
    e.target.blur();
    setTimeout(() => {
      removeAtt(i);
    }, 100);
  }

  function onDone() {
    let column = { ...newField };
    for (let att of [...column.attribute]) {
      if (!att) {
        let ind = column.attribute.indexOf(att);
        column.attribute.splice(ind, 1);
      }
    }
    addNewColumn(column);
  }

  const valid =
    !orgFields.some((c) => c.name === newField.name) ||
    (init ? init.name === newField.name : false);

  return (
    <div className={styles.newField}>
      <TextFieldSimple
        label={"Name"}
        valid={valid}
        invalidMessage={"Already in use"}
        onChange={(val) => editNew("name", val)}
        value={newField.name}
        shadow
      />

      <FlexRow start>
        <Checkbox
          checked={newField.filterable}
          onChange={(e) => editNew("filterable", e.target.checked)}
        ></Checkbox>
        <span className={styles.text_2}>
          Show as a filter <i className="bi-funnel"></i>
        </span>
      </FlexRow>

      <div className={styles.subtitle}>Attributes</div>

      <FlexRow start>
        <Checkbox
          checked={newField.multiple}
          onChange={(e) => editNew("multiple", e.target.checked)}
        ></Checkbox>
        <span className={styles.text_2}>Allow multiple</span>
      </FlexRow>

      {newField.attribute.map((att, i) => (
        <div className={styles.att}>
          <TextEditorPlain
            text={att}
            onSave={(val) => editAtt(i, val)}
            onEnter={(e, val) => onEnter(i, val)}
            editable
            extraClass={styles.attEntry}
            onDeleteEmpty={(e) => onDeleteEmpty(e, i)}
            id={"att num " + i}
          />
          <div className={styles.removeAtt} onClick={() => removeAtt(i)}>
            <i className="bi bi-dash-circle"></i>
          </div>
        </div>
      ))}

      <div
        className={styles.addAtt}
        onClick={
          !newField.attribute.length ||
          newField.attribute[newField.attribute.length - 1]
            ? () => addAtt(newField.attribute.length)
            : undefined
        }
      >
        + Attribute
      </div>

      <FlexRow justify={"center"} style={{ paddingTop: "8px" }}>
        <Button shadow onClick={close}>
          <FlexRow gap={5}>
            <i className="bi bi-x"></i> Cancel
          </FlexRow>
        </Button>
        {init && (
          <Button shadow onClick={onDelete}>
            <FlexRow gap={5}>
              <i className="bi bi-trash"></i> Delete
            </FlexRow>
          </Button>
        )}
        {newField.name && valid && (
          <Button shadow onClick={onDone}>
            Done
          </Button>
        )}
      </FlexRow>
    </div>
  );
}
