// External
import React, { useEffect, useRef, useState } from "react";

// Internal
import styles from "./SelectFieldCustom.module.scss";
import { Label } from "components/layouts/Label/Label";
import Icon from "components/Icon/Icon";
import Button from "components/Button/Button";
import FlexRow from "components/layouts/FlexRow/FlexRow";

export const SelectFieldCustom = ({
  options,
  value,
  icon,
  values,
  selectMultiple,
  searchable,
  onChange,
  newOptionText,
  onNewOptionClick,
  placeholder,
  label,
  disable,
  containerStyle,
  itemStyle,
  onItemClick,
  searchString,
  setSearchString,
  className,
  style,
  dropdownStyle,
  onClick,
  onSave,
  saveLabel,
  noCreate,
}) => {
  const [show, setShow] = useState(false);
  const [search, setSearch] = useState(value?.label ? value?.label : "");
  const [chosenValues, setChosenValues] = useState(values ? values : []);
  const listRef = useRef(null);
  const ref = useRef(null);
  const searchRef = useRef(null);

  useEffect(() => {
    if (selectMultiple && searchable && searchRef.current) {
      const currentDiv = searchRef.current;
      // Add the 'input' event listener for content changes
      currentDiv.addEventListener("input", searchOptions);

      return () => {
        // Cleanup the event listener on unmount
        currentDiv.removeEventListener("input", searchOptions);
      };
    }
  }, []);

  const [topOfList, setTopOfList] = useState();
  // options && options?.length > 0
  //   ? options?.length > 100
  //     ? options?.slice(0, 100)
  //     : options
  //   : []

  useEffect(() => {
    setTopOfList(
      options && options?.length > 0
        ? options?.length > 100
          ? options?.slice(0, 100)
          : options
        : []
    );
  }, [options]);

  function onScroll(e) {
    if (topOfList.length < options.length && !search) {
      var scrollableContainer = e.target;

      let distanceToBottom =
        scrollableContainer.scrollHeight -
        (scrollableContainer.scrollTop + scrollableContainer.clientHeight);

      if (distanceToBottom <= 50) {
        let nextlength = topOfList.length + 100;
        setTopOfList(options.slice(0, nextlength));
      }
    }
  }

  function handleSearch(userInput) {
    let results = [];
    if (userInput || userInput === "") {
      options?.map((option) => {
        if (
          typeof option === "string" &&
          option?.toLowerCase()?.includes(userInput?.toLowerCase())
        ) {
          results.push(option);
        } else if (
          typeof option != "string" &&
          typeof option?.value === "string" &&
          option.value?.toLowerCase()?.includes(userInput?.toLowerCase())
        ) {
          results.push(option);
        } else if (
          typeof option != "string" &&
          typeof option?.label === "string" &&
          option.label?.toLowerCase()?.includes(userInput?.toLowerCase())
        ) {
          results.push(option);
        }
      });
    } else {
      results = options;
    }
    setTopOfList(results ? results.slice(0, 100) : []);
  }

  const handleClickOutside = (event) => {
    if (
      ref.current &&
      listRef.current &&
      !ref.current.contains(event.target) &&
      !listRef.current.contains(event.target)
    ) {
      setShow(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  useEffect(() => {
    if (values) {
      setChosenValues(values);
    }
  }, [values]);

  const handleChange = (option) => {
    // debugger;
    if (!selectMultiple) {
      if (!value || option.value !== value.value) {
        //Checking value.value, but displaying just 'value' below
        setSearch(option?.label);
        if (onChange) {
          onChange(option);
        }
      }
    } else {
      setSearch("");
      if (searchRef.current) {
        searchRef.current.innerText = "";
      }
      let newList = [...chosenValues];
      newList.push(option);
      setChosenValues(newList);
      if (onChange) {
        onChange(newList);
      }
      setTopOfList(
        options && options?.length > 0
          ? options?.length > 100
            ? options?.slice(0, 100)
            : options
          : []
      );
    }
    setShow(false);
  };

  function searchOptions() {
    //this will be replaced with a search to the database  i.e. searchFunction
    setSearch(searchRef.current.innerText);
    handleSearch(searchRef.current.innerText);
  }

  function searchValue(val) {
    //this will be replaced with a search to the database  i.e. searchFunction
    setSearch(val);
    handleSearch(val);
  }

  function handleRemoveOption(item, e) {
    //remove chosen option
    e.stopPropagation();

    let newValues = [...chosenValues];
    let index = newValues.indexOf(item);
    newValues.splice(index, 1);
    if (onChange) {
      onChange(newValues, true);
    }
    setChosenValues(newValues);
  }

  function handleOnSearchClick(e) {
    if (!search || !show) {
      setShow(true);
      setSearch(true);
    }
  }

  function commaList(array) {
    if (array && array?.length && array?.length > 0) {
      return array?.map((item, i) => (
        <div key={i} className={styles.chosenOption} style={itemStyle}>
          <span onClick={onItemClick ? () => onItemClick(item) : undefined}>
            {item?.name ? item?.name : item?.label}{" "}
          </span>
          <span
            className={`bi bi-x ${styles.close}`}
            onClick={(e) => handleRemoveOption(item, e)}
          ></span>
        </div>
      ));
    }
  }

  function getHeight(num) {
    if (
      search &&
      !topOfList?.find((o) => o?.value === search || o?.label === search) &&
      num <= 6
    ) {
      return num * 40 + 40 + "px";
    }
    if (num <= 6) {
      return num * 40 + "px";
    } else {
      return "";
    }
  }

  function handleKeyDown(e) {
    if (e.key === "Enter" || e.keyCode === 13) {
      e.preventDefault();
      if (!noCreate && search) {
        handleChange({ value: search, label: search });
      }
    }
  }

  return (
    <div
      className={styles.selectContainer}
      onClick={onClick ? onClick : undefined}
    >
      {label && <div className={styles.label6}>{label}</div>}
      <div className={styles.selector}>
        <div
          className={`${styles.select} ${
            disable && styles.disabled
          } ${className}`}
          style={{
            // paddingLeft: selectMultiple ? ".2rem" : "",
            ...containerStyle,
          }}
          onClick={() => setShow(!show)}
          ref={ref}
        >
          {!selectMultiple && (
            <div
              className={`${styles.activeOption} `}
              style={{ flexWrap: "nowrap" }}
            >
              {icon}
              {!searchable && <>{value && value.label ? value.label : ""}</>}
              {searchable && (
                <input
                  type="text"
                  onChange={(e) => searchValue(e.target.value)}
                  className={styles.searchInputer}
                  placeholder={placeholder}
                  disabled={disable}
                  value={search}
                ></input>
              )}
            </div>
          )}
          {selectMultiple && (
            <div className={styles.activeOption}>
              {icon}
              {chosenValues
                ? commaList(chosenValues)
                : options.find((o) => o.value === value).name}
              {searchable && (
                <div
                  contentEditable
                  className={`${styles.input}`}
                  value={search}
                  onChange={(e) => searchOptions(e.target.value)}
                  ref={searchRef}
                  onKeyDown={handleKeyDown}
                  // onClick={!showOptions ? () => setShowOptions(true) : undefined}
                />
              )}
              {!searchable && (
                <div
                  contentEditable
                  className={`${styles.input}`}
                  value={search}
                  // onChange={(e) => searchOptions(e.target.value)}
                  // ref={searchRef}
                  // onClick={!showOptions ? () => setShowOptions(true) : undefined}
                />
              )}
            </div>
          )}

          <i className={`bi bi-chevron-down ${styles.icon}`}></i>
        </div>
        {onSave && (
          <Button onClick={onSave} blue>
            {saveLabel ? saveLabel : "Save"}
          </Button>
        )}{" "}
      </div>

      {show && !disable && (
        <div
          className={!searchable ? styles.dropdown : styles.searchableDropdown}
          style={{ height: getHeight(topOfList?.length), ...dropdownStyle }}
          ref={listRef}
          onScroll={onScroll}
        >
          {newOptionText && (
            <div className={styles.newOptionText} onClick={onNewOptionClick}>
              {newOptionText}...
            </div>
            // <div className={styles.newOptionText}><Button gray>{newOptionText}</Button></div>
            // <div className={styles.newOptionText}><Button white>{newOptionText}</Button></div>
          )}

          {topOfList.map((option, index) => (
            <div key={index}>
              {"value" in option && (
                <div
                  className={`${styles.option}`}
                  onClick={() =>
                    chosenValues.find((v) => v?.value === option?.value) ||
                    chosenValues.find((v) => v === option)
                      ? undefined
                      : handleChange(option)
                  }
                >
                  <FlexRow>
                    <FlexRow start style={{ width: "fit-content" }}>
                      {option?.icon ? (
                        <i className={`bi-${option?.icon}`}></i>
                      ) : (
                        ""
                      )}
                      {option?.label}
                    </FlexRow>
                    {(value === option ||
                      value === option?.value ||
                      chosenValues.find((v) => v?.value === option?.value) ||
                      chosenValues.find((v) => v === option)) && (
                      <Icon
                        blue
                        iconName={"check"}
                        style={{ fontSize: "1.3rem" }}
                      ></Icon>
                    )}
                  </FlexRow>
                </div>
              )}
              {/* {!("value" in option) && (
                <div
                  className={`${
                    value === option ? styles.optionActive : styles.option
                  }`}
                  onClick={() =>
                    chosenValues.find((v) => v?.value === option?.value) ||
                    chosenValues.find((v) => v === option)
                      ? undefined
                      : handleChange(option)
                  }
                >
                  {option?.label}
                  {(value === option ||
                    value === option?.value ||
                    chosenValues.find((v) => v?.value === option?.value) ||
                    chosenValues.find((v) => v === option)) && (
                    <Icon
                      blue
                      iconName={"check"}
                      style={{ fontSize: "1.3rem" }}
                    ></Icon>
                  )}
                </div>
              )} */}
            </div>
          ))}
          {!noCreate &&
            search &&
            search != "" &&
            !topOfList?.find(
              (o) => o?.value === search || o?.label === search
            ) && (
              <div
                className={`${styles.option}`}
                onClick={() => {
                  handleChange({ value: search, label: search });
                }}
              >
                Create...
              </div>
            )}

          {topOfList.length == 0 && !search && (
            <div style={{ padding: "1rem" }}>No Data...</div>
          )}
        </div>
      )}
    </div>
  );
};
