import { useState, useEffect, useRef } from "react";
import styles from "./Visualization.module.scss";
import Chart from "components/Charts/Chart";
import QuestionChart, {
  MetricType,
  TableType,
  ValueType,
} from "./QChart/QuestionChart";
import { useFetchQChartRoles } from "api/resources/organization/roles";
import {
  useDeleteOneVisualization,
  useGetViz,
  useGetVizPreview,
  useUpdateVisualization,
} from "api/resources/projects/visualizations";
import { getSortedArray } from "assets/functions/ArrayFunctions";
import ScoreChart from "./ScoreChart";
import TextBox from "components/Charts/TextBox";
import { shortId } from "components/tables/EditableTable/utils";
import TableViz from "./TableViz";
import TableChart from "components/Charts/Table/TableChart";
import { QuickPivots } from "./Settings/QuickPivots";
import Icon from "components/Icon/Icon";
import Note, { NoteType } from "./Note";
import { Link, LinkType } from "./Link/Link";
import {
  useCopyVizToDash,
  useGetCopyToDashInfo,
} from "api/resources/account/dashboard";
import { Loading } from "components/Loading/Loading";
import { CopyToDashes } from "pages/dashboard/PickDash/PickDash";
import { Player } from "@lottiefiles/react-lottie-player";
import BackendStatChart from "./StatChart/BackendStatChart";
import StatChart from "./StatChart/StatChart";
import BackendQChart from "./QChart/BackendQChart";

export function combinedQs(viz) {
  let Qs = [...viz.question];
  if (viz.dynamicQs) {
    for (let q of viz.dynamicQs) {
      if (!Qs.some((qst) => qst.id === q.id)) {
        Qs.push(q);
      }
    }
  }
  return Qs;
}

export function combinedProjIds(viz) {
  let ids = [...viz.projectIdsArray];
  if (viz.dynamicQs) {
    for (let q of viz.dynamicQs) {
      if (!ids.includes(q.projectId)) {
        ids.push(q.projectId);
      }
    }
  }

  return ids;
}

export function combineFilters(viz, externalFilter) {
  if (externalFilter && externalFilter !== "{}") {
    let filter = viz.filters ? JSON.parse(viz.filters) : {};
    let external = JSON.parse(externalFilter);
    for (let key in external) {
      if (key in filter) {
        if (key === "surveys") {
          for (let survey of external[key]) {
            if (!filter[key].some((s) => s.id === survey.id)) {
              filter[key].push(survey);
            }
          }
        } else if (key === "answers") {
          filter[key] = [...filter[key], ...external[key]];
        } else {
          for (let prop of external[key].properties) {
            if (!filter[key].properties.includes(prop)) {
              filter[key].properties.push(prop);
            }
          }
        }
      } else {
        filter[key] = external[key];
      }
    }

    return JSON.stringify(filter);
  } else {
    return viz.filters ? JSON.stringify(JSON.parse(viz.filters)) : "{}";
  }
}

export function parsedViz(viz) {
  if (typeof viz.designSettings === "string") {
    viz.designSettings = JSON.parse(viz.designSettings);
  }
  if (typeof viz.tagIdsArray === "string") {
    viz.tagIdsArray = JSON.parse(viz.tagIdsArray);
  }
  if (typeof viz.projectIdsArray === "string") {
    viz.projectIdsArray = JSON.parse(viz.projectIdsArray);
  }
  if (typeof viz.programIds === "string") {
    viz.programIds = JSON.parse(viz.programIds);
  }
  if (typeof viz.questionTags === "string") {
    viz.questionTags = JSON.parse(viz.questionTags);
  }
  if (typeof viz.comparison === "string") {
    viz.comparison = JSON.parse(viz.comparison);
  }

  if (!viz.designSettings) {
    viz.designSettings = {};
  }
  if (!viz.tagIdsArray) {
    viz.tagIdsArray = [];
  }
  if (!viz.projectIdsArray) {
    viz.projectIdsArray = [];
  }

  return viz;
}

export default function Visualization({
  vizId,
  chart,
  data,
  setData,
  setUpDataCounter,
  canSave,
  canDelete,
  canEdit,
  inDash,
  onCopy,
  onCopyToPage,
  pages,
  setLabelInfo,
  deleteFunction,
  newChart,
  seeDataInDash,
  closeSeeDataInDash,
  idAddOn,
  redrawCounter,
  custom_fields,
  externalFilter,
  update,
  height,
  width,
  isDragging,
  active,
  setCurrViz,
  editing,
  setVisibleItems,
  visibleItems,
  setShowDrill,
  onEditChart,
  onQuickSave,
}) {
  const [copy, setCopy] = useState();

  const fetchViz = useGetViz(vizId);

  return (
    <>
      {fetchViz.isLoading && <Loading />}
      {fetchViz.isSuccess && fetchViz.data.viz && (
        <Shell
          viz={copy ? copy : chart ? chart : parsedViz(fetchViz.data.viz)}
          saveCopy={(viz) => setCopy(viz)}
          clearCopy={() => setCopy(null)}
          data={data}
          setData={setData}
          setUpDataCounter={setUpDataCounter}
          refetch={fetchViz.refetch}
          canSave={canSave}
          canDelete={canDelete}
          canEdit={canEdit}
          inDash={inDash}
          onCopy={onCopy}
          onCopyToPage={onCopyToPage}
          pages={pages}
          setLabelInfo={setLabelInfo}
          deleteFunction={deleteFunction}
          newChart={newChart}
          seeDataInDash={seeDataInDash}
          closeSeeDataInDash={closeSeeDataInDash}
          idAddOn={idAddOn}
          redrawCounter={redrawCounter}
          custom_fields={custom_fields}
          externalFilter={externalFilter}
          update={update}
          height={height}
          width={width}
          isDragging={isDragging}
          active={active}
          setCurrViz={setCurrViz}
          editing={editing}
          setVisibleItems={setVisibleItems}
          visibleItems={visibleItems}
          setShowDrill={setShowDrill}
          onEditChart={onEditChart}
          onQuickSave={onQuickSave}
        />
      )}
    </>
  );
}

function Shell({
  viz,
  saveCopy,
  clearCopy,
  data,
  setData,
  setUpDataCounter,
  refetch,
  canSave,
  canDelete,
  canEdit,
  inDash,
  onCopy,
  onCopyToPage,
  pages,
  setLabelInfo,
  deleteFunction,
  newChart,
  seeDataInDash,
  closeSeeDataInDash,
  idAddOn,
  redrawCounter,
  custom_fields,
  externalFilter,
  update,
  height,
  width,
  isDragging,
  active,
  setCurrViz,
  editing,
  setVisibleItems,
  visibleItems,
  setShowDrill,
  onEditChart,
  onQuickSave,
}) {
  const getRole = useFetchQChartRoles();
  const deleteViz = useDeleteOneVisualization();
  const updateViz = useUpdateVisualization();

  const [filters, setFilters] = useState(combineFilters(viz, externalFilter));

  function updateFilters() {
    setFilters(combineFilters(viz, externalFilter));
  }

  useEffect(() => {
    updateFilters();
  }, [externalFilter, viz.filters]);

  const [expand, setExpand] = useState(false);
  const [openSettings, setOpenSettings] = useState(false);

  const [reDraw, setReDraw] = useState(redrawCounter ? redrawCounter : 0);
  const [addingToDash, setAddingToDash] = useState(false);
  // const [addedToDash, setAddedToDash] = useState(false);
  const [configure, setConfigure] = useState(false);
  const [seeData, setSeeData] = useState(false);
  const [filterSubtitle, setFilterSubtitle] = useState("");
  const [saving, setSaving] = useState(false);

  const [showCopyChartMenu, setShowCopyChartMenu] = useState(false);
  const [closeCopyMenu, setCloseCopyMenu] = useState(false);
  const [showPagesMenu, setShowPagesMenu] = useState(false);

  const [edited, setEdited] = useState(false);
  const [quickPivot, setQuickPivot] = useState(false);

  useEffect(() => {
    if (redrawCounter) {
      setReDraw(reDraw + redrawCounter);
    }
  }, [redrawCounter]);

  useEffect(() => {
    if (!openSettings) {
      if (showCopyChartMenu) {
        setShowCopyChartMenu(false);
        setCloseCopyMenu(false);
        setShowPagesMenu(false);
      }
    }
  }, [openSettings]);

  function addAnswerFilterSubtitle(conditions) {
    let string = "";
    for (let i = 0; i < conditions.length; i++) {
      let condition = conditions[i];
      let op = condition.operator;

      string += condition.surveyName + ": ";

      if (op === "have answered") {
        string += "answered '" + condition.qName + "'";
      } else if (op === "have not answered") {
        string += "did not answer '" + condition.qName + "'";
      } else if (
        op === "passives" ||
        op === "promoters" ||
        op === "detractors"
      ) {
        string += op;
      } else if (Array.isArray(condition.answer)) {
        if (op === "answer" || op === "do not answer" || op === "falls in") {
          if (op === "do not answer") {
            string += "not ";
          }
          for (let i = 0; i < condition.answer.length; i++) {
            if (i > 0) {
              if (i < condition.answer.length - 1) {
                string += ", ";
              } else if (i == condition.answer.length - 1) {
                string += " or ";
              }
            }
            string += condition.answer[i];
          }
        } else if (op === "between") {
          let them = getSortedArray(condition.answer, (a, b) => a - b);
          string += "between " + them[0] + " and " + them[1];
        }
      } else if (op === "below" || op === "above") {
        string += op + " " + condition.answer;
      } else if (op === "contains") {
        string += "'" + condition.answer + "'";
      } else if (op === "does not contain") {
        string += op + "  '" + condition.answer + "'";
      }

      if (op === "is longer than" || op === "is shorter than") {
        if (op === "is longer than") {
          string += "more than ";
        } else {
          string += "less than ";
        }
        string += condition.answer + " characters";
      }

      if (i < conditions.length - 1) {
        string += " & ";
      }
    }

    return string;
  }

  function getFilterSubtitle() {
    if (viz.filters === "{}") {
      return "";
    }
    let filterObj = JSON.parse(viz.filters);

    let string = "";
    for (let one in filterObj) {
      if (one === "orAnswers") {
        continue;
      }
      if (string) {
        string += " & ";
      }
      if (one === "surveys") {
        let all = filterObj.surveys;
        string += "Survey: ";
        for (let i = 0; i < all.length; i++) {
          string += all[i].name;
          if (i < all.length - 1) {
            string += ", ";
          } else {
            string += " ";
          }
        }
      } else if (one === "answers") {
        string += addAnswerFilterSubtitle(filterObj[one]);
      } else {
        let field = filterObj[one];
        let name = field.name.charAt(0).toUpperCase() + field.name.slice(1);
        string += name + ": ";
        for (let i = 0; i < field.properties.length; i++) {
          string += field.properties[i];
          if (i < field.properties.length - 1) {
            string += ", ";
          } else {
            string += " ";
          }
        }
      }
    }
    return string;
  }

  useEffect(() => {
    let it = "";
    if (viz.designSettings?.showFilterSubtitle && filters && filters !== "{}") {
      it = getFilterSubtitle();
    }
    setFilterSubtitle(it);
  }, [filters, viz.designSettings?.showFilterSubtitle]);

  function setUpPopout() {
    setExpand(true);
    reload();
  }

  function clickOutsidePopout(event) {
    if (
      event.target ===
      document.getElementById(editing ? "outside editor" : "outside " + viz.id)
    ) {
      closePopout();
    }
  }

  useEffect(() => {
    if (expand) {
      document.addEventListener("click", clickOutsidePopout, true);
      return () => {
        document.removeEventListener("click", clickOutsidePopout, true); //will hit when turned to false
      };
    }
  }, [expand]);

  function closePopout() {
    setExpand(false);
    reload();
  }

  function reload() {
    setReDraw(reDraw + 1);
  }

  const ref = useRef();

  function clickOutsideMenu(event) {
    if (ref.current && !ref.current?.contains(event.target)) {
      setOpenSettings(false);
    }
  }

  useEffect(() => {
    if (openSettings) {
      document.addEventListener("click", clickOutsideMenu, true);
      return () => {
        document.removeEventListener("click", clickOutsideMenu, true); // Run on unmounting
      };
    }
  }, [openSettings]);

  function menuClick(callback) {
    callback();
    setOpenSettings(false); // Should trigger the release of the event listener
  }

  function pdfExport() {
    setTimeout(() => {
      let input = window.document.getElementById("outside " + viz.id);
      html2canvas(input, { scale: 5 }).then((canvas) => {
        const img = canvas.toDataURL("image/png", 100);
        // img.save("newimgage");
        const pdf = new jsPDF("p", "pt");
        // console.log(input.clientHeight);
        pdf.internal.pageSize.height = input.clientHeight;
        pdf.internal.pageSize.width = input.clientWidth;
        pdf.addImage(img, "png", 0, 0, input.clientWidth, input.clientHeight);
        //give pdf a name and download.
        let str = "chart.pdf";
        str = str.replace(/\s+/g, "");
        str = str.toLocaleLowerCase();
        pdf.save(str);
      }, 5000);
    });
  }

  function changeSeeData(val) {
    setSeeData(val);
    setShowDrill(val);
  }

  function deleteVisualization() {
    if (canDelete) {
      if (deleteFunction) {
        deleteFunction(viz.id);
      } else {
        deleteViz.mutate(
          {
            id: viz.id,
          },
          {
            onSuccess: () => {
              if (refetch) {
                refetch();
              }
            },
          }
        );
      }
    }
  }

  const handleSave = (newViz) => {
    if (canSave) {
      setSaving(true);
      let data = {
        title: newViz.title,
        titleValue: newViz.titleValue,
        type: newViz.type,
        questionIds: newViz.question ? newViz.question.map((q) => q.id) : null,
        designSettings: JSON.stringify(newViz.designSettings),
        pivotString: newViz.pivotString,
        filters:
          typeof newViz.filters !== "string"
            ? JSON.stringify(newViz.filters)
            : newViz.filters,
        tagIdsArray: JSON.stringify(newViz.tagIdsArray),
        projectIdsArray: JSON.stringify(newViz.projectIdsArray),
        programIds: newViz.programIds
          ? JSON.stringify(newViz.programIds)
          : null,
        questionTags: newViz.questionTags
          ? JSON.stringify(newViz.questionTags)
          : null,
        comparison: newViz.comparison
          ? JSON.stringify(newViz.comparison)
          : null,
      };
      updateViz.mutate(
        {
          id: viz.id,
          data: data,
        },
        {
          onSuccess: (data) => {
            setSaving(false);
            clearCopy();
            setEdited(false);
            setQuickPivot(false);
            if (refetch) {
              refetch();
            }
            if (onQuickSave) {
              onQuickSave();
            }
          },
        }
      );
    }
  };

  const saveNote = (text, encoding, designSettings) => {
    if (canSave) {
      setSaving(true);
      let data = {
        title: text,
        data: encoding,
        designSettings: JSON.stringify(designSettings),
      };
      updateViz.mutate(
        {
          id: viz.id,
          data: data,
        },
        {
          onSuccess: (data) => {
            setSaving(false);
            clearCopy();
            if (refetch) {
              refetch();
            }
            if (onQuickSave) {
              onQuickSave();
            }
          },
        }
      );
    }
  };

  const [offsetData, setOffsetData] = useState("");

  function drag_over(event) {
    var offset;
    try {
      offset = event.dataTransfer.getData("text/plain").split(",");
    } catch (e) {
      offset = offsetData.split(",");
    }

    var container = document.getElementById(viz.id);
    let newleft =
      parseInt(event.clientX + parseInt(offset[0], 10)) / container.clientWidth;
    newleft = newleft * 100;

    let newtop =
      parseInt(event.clientY + parseInt(offset[1], 10)) /
      container.clientHeight;
    newtop = newtop * 100;

    let settings = viz.designSettings;
    if (settings.textboxes.length > 0 && offset[2]) {
      settings.textboxes[offset[2]].left = newleft;
      settings.textboxes[offset[2]].top = newtop;
    }

    event.preventDefault();
    return false;
  }

  function drop(event) {
    if (setCurrViz) {
      // if editing

      var offset;
      try {
        offset = event.dataTransfer.getData("text/plain").split(",");
      } catch (e) {
        offset = offsetData.split(",");
      }

      var container = document.getElementById(viz.id);
      let newleft =
        parseInt(event.clientX + parseInt(offset[0], 10)) /
        container.clientWidth;
      newleft = newleft * 100;

      let newtop =
        parseInt(event.clientY + parseInt(offset[1], 10)) /
        container.clientHeight;
      newtop = newtop * 100;

      let settings = viz.designSettings;
      if (
        settings.textboxes.length > 0 &&
        offset[2] &&
        settings.textboxes[offset[2]]
      ) {
        settings.textboxes[offset[2]].left = newleft;
        settings.textboxes[offset[2]].top = newtop;
      }

      setCurrViz({ ...viz });
      setVisibleItems("TextBoxSettings");
    }

    event.preventDefault();
    return false;
  }

  function getAddOn() {
    let it = idAddOn ? idAddOn : "normal";
    if (expand) {
      it += "expanded";
    }
    return it;
  }

  const addOn = getAddOn();

  const score = viz.type === MetricType || viz.type === ValueType;
  const note = viz.type === NoteType;
  const link = viz.type === LinkType;

  const tableContainerStyle = {
    height: isDragging ? height : "fit-content",
    maxHeight: height,
  };

  function getTitleContainerStyle() {
    return {
      alignItems: viz.designSettings.titleAlignment,
      backgroundColor: viz.designSettings.titleBackgroundColor,
      borderRadius: viz.designSettings.titleBorderRadius,
      paddingTop: viz.designSettings.paddingTopTitle + 10,
      paddingBottom: viz.designSettings.paddingBottomTitle,
      paddingLeft: viz.designSettings.paddingLeftTitle + 10,
      paddingRight: viz.designSettings.paddingRightTitle + 10,
    };
  }

  function getTitleStyle() {
    return {
      color: viz.designSettings.titleColor,
      fontSize: viz.designSettings.valueTitleSize,
    };
  }

  function onCopyClick() {
    if (inDash) {
      onCopy(viz);
      setOpenSettings(false);
      return;
    }

    if (showCopyChartMenu) {
      if (!closeCopyMenu) {
        closeCopying();
      }
    } else {
      if (onCopyToPage && pages) {
        setShowCopyChartMenu(true);
      } else {
        menuClick(() => onCopy(viz));
      }
    }
  }

  function closeCopying() {
    setCloseCopyMenu(true);
    setTimeout(() => {
      setShowCopyChartMenu(false);
      setCloseCopyMenu(false);
      setShowPagesMenu(false);
    }, 500);
  }

  function onEdit(copy) {
    saveCopy(copy);
    setEdited(true);
  }

  // useEffect(() => {
  //   console.log("Mounting");
  // }, []);

  return (
    <>
      <div
        className={`${
          expand ? styles.popoutBackground : styles.chartContainer
        } ${active && !note ? styles.active : ""}`}
        id={"outside " + viz.id}
        style={{
          boxShadow:
            viz.designSettings.containerShadow === false ||
            (note && !viz.designSettings.showBackground)
              ? "none"
              : "",
          padding: note || score || viz.type === TableType ? "0px" : "",
          backgroundColor:
            note && !viz.designSettings.showBackground ? "transparent" : "",
        }}
        onClick={(e) => {
          e.stopPropagation();
          if (editing && !active && e.target.id !== "Quick Pivots " + viz.id) {
            setCurrViz(
              viz.type === NoteType || viz.type === LinkType ? null : viz
            );
            setVisibleItems(null);
          }
        }}
      >
        {!openSettings && !quickPivot && (
          <div
            className={styles.settingsBtn}
            // style={{ opacity: active ? "1" : "" }}
            onClick={(e) => {
              e.stopPropagation();
              setOpenSettings(!openSettings);
            }}
          >
            <Icon iconName={"three-dots"}></Icon>
          </div>
        )}

        {openSettings && (
          <div
            className={styles.settingsMenu}
            ref={ref}
            onMouseLeave={() => setOpenSettings(!openSettings)}
            onClick={(e) => e.stopPropagation()}
          >
            {!editing && canEdit && !link && (
              <>
                <div
                  className={styles.menuItem}
                  onClick={() =>
                    menuClick(() => {
                      if (editing) {
                        setCurrViz(viz);
                      } else {
                        onEditChart(viz);
                      }
                    })
                  }
                >
                  <i className="bi bi-hammer" style={{ color: "#15BCC7" }}></i>
                  Edit
                </div>

                {!score && !active && !note && (
                  <div
                    className={`${styles.menuItem} ${styles.quickPivot}`}
                    onClick={() => menuClick(() => setQuickPivot(true))}
                  >
                    <i
                      className="bi bi-wrench"
                      style={{ color: "#15BCC7" }}
                    ></i>
                    Quick Pivot
                  </div>
                )}
              </>
            )}
            {getRole.data.role.canSeeContactInfo === true &&
              !score &&
              !note &&
              !link && (
                <div
                  className={styles.menuItem}
                  onClick={() => menuClick(() => changeSeeData(true))}
                >
                  <i
                    className="bi bi-list-columns-reverse"
                    style={{ color: "#15BCC7" }}
                  ></i>
                  See Data
                </div>
              )}

            {editing && canSave && canEdit && onCopy && (
              <div className={styles.menuItem} onClick={onCopyClick}>
                <i className="bi bi-printer" style={{ color: "#15BCC7" }}></i>
                Copy
              </div>
            )}
            {showCopyChartMenu && (
              <div
                className={`${styles.copyMenu} ${
                  closeCopyMenu ? styles.closeMenu : ""
                } `}
              >
                <div
                  className={`${styles.menuItem}`}
                  onClick={() => menuClick(() => onCopy(viz))}
                >
                  {" "}
                  <i
                    className="bi bi-arrow-down"
                    style={{ color: "#15BCC7" }}
                  ></i>
                  Right Below
                </div>
                <div
                  className={`${styles.menuItem} ${
                    showPagesMenu ? styles.toPage : ""
                  }`}
                  style={{ gap: "10px" }}
                  onClick={() => setShowPagesMenu(!showPagesMenu)}
                >
                  <i
                    className="bi bi-arrow-90deg-right"
                    style={{ color: "#15BCC7" }}
                  ></i>
                  To page
                </div>
                {showPagesMenu && (
                  <>
                    {pages.map((page) => (
                      <div
                        className={`${styles.menuItem} ${styles.pageMenuItem}`}
                        onClick={() =>
                          menuClick(() => onCopyToPage(viz, page.id))
                        }
                        key={page.id}
                      >
                        <span>{page.name}</span>
                      </div>
                    ))}
                    <div
                      className={`${styles.menuItem} ${styles.pageMenuItem} ${styles.newPage}`}
                      onClick={() => menuClick(() => onCopyToPage(viz, ""))}
                    >
                      + New Page
                    </div>
                  </>
                )}
              </div>
            )}

            {getRole.isSuccess &&
              (getRole.data.role.canEditPersonalDashboard ||
                getRole.data.role.canEditOthersDashboards ||
                getRole.data.role.canEditOrgDashboard ||
                getRole.data.role.canEditRoleDashboards) && (
                <CopyToDash
                  vizId={viz.id}
                  setShowDrill={setShowDrill}
                  role={getRole.data.role}
                />
              )}

            {/* {!expand && !closeAboveExpand && (
                <div
                  className={styles.menuItem}
                  onClick={() => menuClick(setUpPopout)}
                >
                  <i
                    className="bi bi-arrows-angle-expand"
                    style={{ color: "#15BCC7" }}
                  ></i>
                  Expand
                </div>
              )} */}

            {editing && canDelete && (
              <div
                className={styles.menuItem}
                onClick={() => menuClick(deleteVisualization)}
              >
                <i className="bi bi-trash3" style={{ color: "#FF8878" }}></i>
                Delete
              </div>
            )}
          </div>
        )}
        {quickPivot && (
          <QuickPivots
            viz={viz}
            close={() => setQuickPivot(false)}
            filters={filters}
            custom_fields={custom_fields}
            externalFilter={externalFilter}
            onEdit={onEdit}
            onSave={() => handleSave(viz)}
            edited={edited}
            inEdit={editing}
          />
        )}

        <div
          className={`${expand ? styles.popout : styles.asChart}`}
          style={{
            ...(expand
              ? {
                  paddingTop: viz.designSettings.paddingTop + 10,
                  paddingBottom: viz.designSettings.paddingBottom + 10,
                  paddingLeft: viz.designSettings.paddingLeft + 5,
                  paddingRight: viz.designSettings.paddingRight + 5,
                }
              : {
                  paddingTop: viz.designSettings.paddingTop,
                  paddingBottom: viz.designSettings.paddingBottom,
                  paddingLeft: viz.designSettings.paddingLeft,
                  paddingRight: viz.designSettings.paddingRight,
                }),
          }}
        >
          {expand && (
            <div className={styles.exitBtn} onClick={closePopout}>
              <i className="bi bi-x"></i>
            </div>
          )}

          {getRole.isSuccess && (
            <>
              <div
                className={styles.vizDisplay}
                id={viz.id}
                onDragOver={
                  editing &&
                  viz.designSettings.textboxes &&
                  viz.designSettings.textboxes.length
                    ? drag_over
                    : undefined
                }
                onDrop={
                  editing &&
                  viz.designSettings.textboxes &&
                  viz.designSettings.textboxes.length
                    ? drop
                    : undefined
                }
              >
                {!score && !note && !link && (
                  <>
                    {viz.type === TableType && (
                      <>
                        {!data && (
                          <TableViz
                            viz={viz}
                            inEdit={editing}
                            newChart={newChart}
                            custom_fields={custom_fields}
                            height={expand ? undefined : height}
                            role={getRole.data.role}
                            seeData={seeData || seeDataInDash}
                            closeSeeData={
                              seeDataInDash
                                ? closeSeeDataInDash
                                : () => changeSeeData(false)
                            }
                            setVizCopy={setCurrViz}
                            setOutsideData={setData}
                            setUpOutsideDataCounter={setUpDataCounter}
                            filters={filters}
                            filterSubtitle={filterSubtitle}
                            reDraw={reDraw}
                            setShowDrill={setShowDrill}
                            onQuickSave={onQuickSave}
                          />
                        )}{" "}
                        {data && (
                          <TableChart
                            initHeaders={data.headers}
                            data={data.dataArray}
                            asChart
                            tableTitle={
                              viz.designSettings.hasTitle
                                ? viz.titleValue
                                  ? viz.titleValue
                                  : viz.title
                                : ""
                            }
                            titleContainerStyle={getTitleContainerStyle()}
                            titleStyle={getTitleStyle()}
                            subtitle={filterSubtitle ? filterSubtitle : false}
                            tableSort={viz.designSettings?.tableSort}
                            color={viz.designSettings?.tableColor}
                            height={height}
                          />
                        )}
                      </>
                    )}
                    {viz.type !== TableType && (
                      <>
                        {!data && (
                          <>
                            {viz.dataMethod === "survey" && (
                              <BackendStatChart
                                viz={viz}
                                refetch={refetch}
                                setLabelInfo={setLabelInfo}
                                newChart={newChart}
                                custom_fields={custom_fields}
                                update={update}
                                height={height}
                                reDraw={reDraw}
                                role={getRole.data.role}
                                seeData={seeData}
                                closeSeeData={() => changeSeeData(false)}
                                idAddOn={addOn}
                                filters={filters}
                                filterSubtitle={filterSubtitle}
                                setVisibleItems={setVisibleItems}
                                editing={editing}
                                visible={visibleItems}
                                active={active}
                                setCurrViz={setCurrViz}
                                visibleItems={visibleItems}
                                setShowDrill={setShowDrill}
                              />
                            )}
                            {(!viz.dataMethod ||
                              viz.dataMethod === "question") && (
                              <>
                                {/* <QuestionChart
                                  viz={viz}
                                  setOutsideData={setData}
                                  setUpOutsideDataCounter={setUpDataCounter}
                                  setLabelInfo={setLabelInfo}
                                  newChart={newChart}
                                  custom_fields={custom_fields}
                                  update={update}
                                  height={height}
                                  reDraw={reDraw}
                                  role={getRole.data.role}
                                  seeData={seeData || seeDataInDash}
                                  closeSeeData={
                                    seeDataInDash
                                      ? closeSeeDataInDash
                                      : () => changeSeeData(false)
                                  }
                                  idAddOn={addOn}
                                  filters={filters}
                                  filterSubtitle={filterSubtitle}
                                  setVisibleItems={setVisibleItems}
                                  editing={editing}
                                  visible={visibleItems}
                                  active={active}
                                  setCurrViz={setCurrViz}
                                  setShowDrill={setShowDrill}
                                /> */}

                                <BackendQChart
                                  viz={viz}
                                  setOutsideData={setData}
                                  setUpOutsideDataCounter={setUpDataCounter}
                                  setLabelInfo={setLabelInfo}
                                  custom_fields={custom_fields}
                                  update={update}
                                  height={height}
                                  reDraw={reDraw}
                                  role={getRole.data.role}
                                  seeData={seeData || seeDataInDash}
                                  closeSeeData={
                                    seeDataInDash
                                      ? closeSeeDataInDash
                                      : () => changeSeeData(false)
                                  }
                                  idAddOn={addOn}
                                  filters={filters}
                                  filterSubtitle={filterSubtitle}
                                  setVisibleItems={setVisibleItems}
                                  editing={editing}
                                  visible={visibleItems}
                                  active={active}
                                  setCurrViz={setCurrViz}
                                  setShowDrill={setShowDrill}
                                />
                              </>
                            )}
                          </>
                        )}
                        {data && (
                          <div
                            className={styles.chartWithTitle}
                            id={viz.id + idAddOn}
                          >
                            {viz.designSettings.hasTitle && (
                              <div
                                className={styles.titleContainer}
                                id={"title for " + viz.id + idAddOn}
                                style={{
                                  justifyContent:
                                    viz.designSettings.titleAlignment,
                                  backgroundColor:
                                    viz.designSettings.titleBackgroundColor,
                                  borderRadius:
                                    viz.designSettings.titleBorderRadius,
                                  paddingTop:
                                    viz.designSettings.paddingTopTitle,
                                  paddingBottom:
                                    viz.designSettings.paddingBottomTitle,
                                  paddingLeft:
                                    viz.designSettings.paddingLeftTitle,
                                  paddingRight:
                                    viz.designSettings.paddingRightTitle,
                                }}
                              >
                                <div
                                  className={styles.title}
                                  style={{
                                    color: viz.designSettings.titleColor,
                                    fontSize: viz.designSettings.valueTitleSize,
                                    textAlign:
                                      viz.designSettings.titleAlignment,
                                  }}
                                >
                                  {viz.title}
                                </div>
                                {filterSubtitle &&
                                  viz.designSettings.hasSubtitle &&
                                  combinedQs(viz).length > 0 && (
                                    <div className={styles.subtitle}>
                                      {filterSubtitle}
                                    </div>
                                  )}
                              </div>
                            )}

                            <Chart
                              data={data}
                              viz={viz}
                              idAddOn={idAddOn}
                              setVisibleItems={setVisibleItems}
                              editing={editing}
                              visible={visibleItems}
                              active={active}
                              setCurrViz={setCurrViz}
                              visibleItems={visibleItems}
                            />
                          </div>
                        )}
                      </>
                    )}
                  </>
                )}
                {score && (
                  <>
                    <ScoreChart
                      viz={viz}
                      filters={filters}
                      filterSubtitle={filterSubtitle}
                      outsideData={data}
                      setOutsideData={setData}
                      setUpOutsideDataCounter={setUpDataCounter}
                      width={width}
                      height={height}
                    />
                  </>
                )}
                {note && (
                  <Note
                    viz={viz}
                    updateViz={saveCopy}
                    encoding={viz.data}
                    editable={canSave && editing}
                    onSave={saveNote}
                  />
                )}
                {link && (
                  <Link viz={viz} editing={editing} role={getRole.data.role} height={height} />
                )}

                {viz.designSettings.textboxes &&
                  viz.designSettings.textboxes.map((box, index) => (
                    <TextBox
                      key={index}
                      viz={viz}
                      box={box}
                      settings={viz.designSettings}
                      index={index}
                      setOffsetData={setOffsetData}
                      inEdit={editing}
                      filters={filters}
                    ></TextBox>
                  ))}
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}

function CopyToDash({ vizId, setShowDrill, role }) {
  const copyToDashInfo = useGetCopyToDashInfo();
  const copyToDash = useCopyVizToDash();
  const [copying, setCopying] = useState(false);
  const [coppied, setCoppied] = useState(false);
  const [pullingDash, setPullingDash] = useState(false);
  const [dashList, setDashList] = useState();
  const [pickDash, setPickDash] = useState(false);

  function onCopyToDash() {
    if (dashList) {
      setDashList();
      setPullingDash(false);
      return;
    }

    copyToDashInfo.mutate(
      {},
      {
        onSuccess: (data) => {
          setPullingDash(false);
          let list = [];
          if (data.info.mine) {
            list.push({
              label: "My Dash",
              dashId: data.info.mine,
            });
          }
          if (data.info.orgs) {
            list.push({
              label: "Org Dash",
              dashId: data.info.orgs,
            });
          }
          if (role.canEditOthersDashboards) {
            list.push({ label: "Others'", users: true });
          }
          if (data.info.myRoles) {
            list.push({
              label: role.name + "'s Dash",
              dashId: data.info.myRoles,
            });
          }
          if (role.canEditRoleDashboards) {
            list.push({ label: "Other Roles'", roles: true });
          }
          if (list.length == 1 && list[0].dashId) {
            copyToADash(list[0].dashId);
          } else {
            setDashList(list);
          }
        },
        onError: () => setPullingDash(false),
      }
    );
    setPullingDash(true);
  }

  function copyToADash(dashId) {
    setCopying(true);
    copyToDash.mutate(
      { vizId: vizId, dashId: dashId },
      {
        onSuccess: (data) => {
          setCopying(false);
          if (data.coppied) {
            setCoppied(true);
            setTimeout(() => setCoppied(false), 2000);
          }
        },
        onError: (data) => {
          setCopying(false);
        },
      }
    );
  }

  function onPickDash(d) {
    setShowDrill(true);
    setPickDash(d);
  }

  return (
    <>
      <div className={styles.menuItem} onClick={onCopyToDash}>
        <i className="bi bi-speedometer2" style={{ color: "#15BCC7" }}></i>
        Copy to Dash{" "}
        <div className={styles.dashLoading}>
          {(pullingDash || copying) && <Loading height={20} width={20} />}
          {coppied && (
            <Player
              autoplay
              src={require("assets/animations/checkmarkblue.json")}
              style={{
                height: "20px",
              }}
            />
          )}
        </div>
      </div>

      {dashList && (
        <div className={`${styles.copyMenu} ${styles.dashList}`}>
          {dashList.map((d, i) => (
            <DashItem dashItem={d} onPickDash={onPickDash} vizId={vizId} />
          ))}
        </div>
      )}

      {pickDash && (
        <CopyToDashes
          roles={pickDash.roles}
          users={pickDash.users}
          vizId={vizId}
          onClose={() => {
            setPickDash();
            setShowDrill(false);
          }}
        />
      )}
    </>
  );
}

function DashItem({ dashItem, onPickDash, vizId }) {
  const [copying, setCopying] = useState(false);
  const [coppied, setCoppied] = useState(false);
  const copyToDash = useCopyVizToDash();

  function onClick() {
    if (dashItem.dashId) {
      copy();
    } else {
      onPickDash(dashItem);
    }
  }

  function copy() {
    copyToDash.mutate(
      { vizId: vizId, dashId: dashItem.dashId },
      {
        onSuccess: (data) => {
          setCopying(false);
          if (data.coppied) {
            setCoppied(true);
            setTimeout(() => setCoppied(false), 2000);
          }
        },
        onError: (data) => {
          setCopying(false);
        },
      }
    );

    setCopying(true);
  }

  return (
    <div className={`${styles.menuItem} ${styles.dashItem}`} onClick={onClick}>
      <div className={styles.dashLoading}>
        {coppied && (
          <Player
            autoplay
            src={require("assets/animations/checkmarkblue.json")}
            style={{
              height: "20px",
            }}
          />
        )}{" "}
        {copying && <Loading height={20} width={20} />}
      </div>

      {dashItem.label}
    </div>
  );
}

export function VizPreview({ vizId }) {
  const fetchViz = useGetVizPreview(vizId ? vizId : "");

  return (
    <>
      {fetchViz.isSuccess && fetchViz.data.viz && (
        <ChartPreview viz={fetchViz.data.viz} />
      )}
    </>
  );
}

function ChartPreview({ viz }) {
  if (typeof viz.designSettings === "string") {
    viz.designSettings = JSON.parse(viz.designSettings);
  }
  function getData() {
    if (
      viz.type != MetricType &&
      viz.type != TableType &&
      typeof viz.data === "string"
    ) {
      return JSON.parse(viz.data);
    }

    return viz.data;
  }

  function getTitle() {
    if (!viz.designSettings.hasTitle) {
      return "";
    }
    return viz.title;
  }

  const isScore = viz.type == ValueType || viz.type === MetricType;

  return (
    <div
      className={`${styles.chartContainer}`}
      style={{
        boxShadow: viz.designSettings.containerShadow === false ? "none" : "",
        padding: viz.type != ValueType ? "" : "0px",
        borderRadius: "3em",
      }}
    >
      {viz.type !== TableType && !isScore && (
        <div className={styles.chartWithTitle} id={viz.id + "expanded"}>
          {viz.designSettings.hasTitle && (
            <div
              className={styles.titleContainer}
              id={"title for " + viz.id + shortId()}
              style={{
                justifyContent: viz.designSettings.titleAlignment,
                backgroundColor: viz.designSettings.titleBackgroundColor,
                borderRadius: viz.designSettings.titleBorderRadius,
                paddingTop: viz.designSettings.paddingTopTitle,
                paddingBottom: viz.designSettings.paddingBottomTitle,
                paddingLeft: viz.designSettings.paddingLeftTitle,
                paddingRight: viz.designSettings.paddingRightTitle,
              }}
            >
              <div
                className={styles.title}
                style={{
                  color: viz.designSettings.titleColor,
                  fontSize: viz.designSettings.valueTitleSize,
                }}
              >
                {getTitle()}
              </div>
            </div>
          )}
          {getData() && (
            <Chart
              data={getData()}
              // onSegClick={onSegClick}
              viz={viz}
              idAddOn={shortId()}

              // reDraw={reDraw}
              // update={update}
              // saveData={saveData}
            />
          )}
        </div>
      )}

      {isScore && <ScoreChart viz={viz} preview />}
      {viz.type === TableType && (
        <div className={styles.mocktableContainer}>
          <div className={styles.thumbnailtext}>{getTitle()}</div>
          <div className={styles.mocktable}>
            <div
              className={styles.mockrowfirst}
              style={{ backgroundColor: viz.designSettings.tableColor }}
            ></div>
            <div className={styles.mockrow}></div>
            <div className={styles.mockrow}></div>
            <div className={styles.mockrow}></div>
            <div className={styles.mockrow}></div>
            <div className={styles.mockrow}></div>
            <div className={styles.mockrow}></div>
            <div className={styles.mockrow}></div>
            <div className={styles.mockrow}></div>
          </div>
        </div>
      )}
    </div>
  );
}
