import { useState, useEffect } from "react";
import styles from "../AnswerTable.module.scss";
import ContactDetails from "components/Popout/ContactProfile";
import TableChart from "components/Charts/Table/TableChart";
import { capitalize } from "assets/functions/StringFunctions";
import {
  getStatChartCompSurveyPullData,
  getStatChartSurveyPullData,
  statBars,
} from "../../StatChart/StatChart";
import { getTitleContainerStyle, getTitleStyle } from "../Table";
import { useFetchStatChartDrillData } from "api/resources/projects/projects";
import { Loading } from "components/Loading/Loading";

export default function BackendStatDrillTable({
  onClose,
  viz,
  inEdit,
  drill,
  filters,
  toggleSpreadsheet,
}) {
  const fetchData = useFetchStatChartDrillData();

  function handleClickOutside(event) {
    if (event.target === document.getElementById("outsideDrill")) {
      document.removeEventListener("click", handleClickOutside, true);
      if (onClose) {
        onClose();
      }
    }
  }

  useEffect(() => {
    fetchData.mutate({
      projData: getStatChartSurveyPullData(viz),
      designSettings: JSON.stringify(viz.designSettings),
      filters: filters,
      tagIds: {
        ids: viz.tagIdsArray,
      },
      pivot: viz.pivotString,
      compData: getStatChartCompSurveyPullData(viz),
      drill: drill,
    });

    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  return (
    <div className={styles.popoutBackground} id="outsideDrill">
      <div className={styles.popout}>
        {onClose && (
          <i
            className={`${"bi bi-x-lg"} ${styles.exitBtn}`}
            onClick={onClose}
          ></i>
        )}
        {fetchData.isLoading && <Loading />}
        {fetchData.isSuccess && (
          <DrillTable
            data={fetchData.data.drillData.rows}
            columns={fetchData.data.drillData.columns}
            viz={viz}
            inEdit={inEdit}
            drill={drill}
            toggleSpreadsheet={toggleSpreadsheet}
          />
        )}
      </div>
    </div>
  );
}

function DrillTable({ data, columns, viz, inEdit, drill, toggleSpreadsheet }) {
  const [dataArray, setDataArray] = useState(JSON.parse(data));

  const style = (value) => <span className={styles.text}>{value}</span>;

  const [headers, setHeaders] = useState(
    columns.map((h) => {
      return {
        ...h,
        cell_style: style,
      };
    })
  );
  const [contact, setContact] = useState(null);

  function handleRowClick(obj) {
    var selection = window.getSelection();
    if (!selection.toString()) {
      let person = { ...obj };
      if (!person.anon) {
        delete person.anon;
        setContact(person);
      }
    }
  }

  function getDrillTitle() {
    let string = "";
    const pivot = viz.pivotString;
    const split = viz.designSettings.split;
    if (pivot) {
      if (split) {
        let bar = viz.designSettings.showBars.find(
          (b) => b.value === viz.designSettings.answerType
        );
        if (!bar) {
          bar = statBars.find((b) => b.value === viz.designSettings.answerType);
        }
        string = bar?.label;
      } else {
        string = drill.dataset;
      }
    } else {
      string = drill.segment;
    }

    if (pivot) {
      string += " with " + drill.segment + " as " + capitalize(pivot);
      if (split) {
        string += " and " + capitalize(split) + " as " + drill.dataset;
      }
    } else if (split) {
      string += " with " + drill.dataset + " as " + capitalize(split);
    }

    return string;
  }

  return (
    <>
      {(!headers || !dataArray) && (
        <div style={{ width: "65vw", height: "80%" }}></div>
      )}
      {headers && dataArray && (
        <TableChart
          initHeaders={headers}
          data={dataArray}
          threeDots
          onRowClick={inEdit ? undefined : handleRowClick}
          titleStyle={getTitleStyle(viz)}
          tableTitle={viz.designSettings.hasTitle ? viz.title : ""}
          subtitle={getDrillTitle()}
          downloadCsv
          toggleSpreadsheet={toggleSpreadsheet}
          titleContainerStyle={getTitleContainerStyle(viz)}
          color={viz.designSettings.tableColor}
          showScroll
        />
      )}
      {contact && (
        <ContactDetails
          onClose={() => setContact(null)}
          contact={contact}
        ></ContactDetails>
      )}
    </>
  );
}
