/*
======================= START OF LICENSE NOTICE =======================
  Copyright (C) 2023 Reaction. All Rights Reserved

  NO WARRANTY. THE PRODUCT IS PROVIDED BY DEVELOPER "AS IS" AND ANY
  EXPRESS OR IMPLIED WARRANTIES, INCLUDING, BUT NOT LIMITED TO, THE
  IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR
  PURPOSE ARE DISCLAIMED. IN NO EVENT SHALL DEVELOPER BE LIABLE FOR
  ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, OR CONSEQUENTIAL
  DAMAGES (INCLUDING, BUT NOT LIMITED TO, PROCUREMENT OF SUBSTITUTE
  GOODS OR SERVICES; LOSS OF USE, DATA, OR PROFITS; OR BUSINESS
  INTERRUPTION) HOWEVER CAUSED AND ON ANY THEORY OF LIABILITY, WHETHER
  IN CONTRACT, STRICT LIABILITY, OR TORT (INCLUDING NEGLIGENCE OR
  OTHERWISE) ARISING IN ANY WAY OUT OF THE USE OF THE PRODUCT, EVEN
  IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGE.
======================== END OF LICENSE NOTICE ========================
  Primary Author: natehanson
*/
import { GraphQLClient } from "graphql-request";
import { useQuery, useMutation, useSubscription } from "react-query";
import { useState } from "react";


const endpoint = process.env.REACT_APP_API_KEY; //process.env.REACT_APP_API_KEY

const getGqlRequestFn = (request, variables) => {
  const headers = { credentials: "include", mode: "cors" }; //REACTION SERVER
  //  const headers = {}; //MOCK SERVER
  const graphQLClient = new GraphQLClient(endpoint, headers);
  return async () => await graphQLClient.request(request, variables);
};

export const useGqlQuery = (key, query, variables, options = {
  staleTime: 5000, // Keep data fresh for 5 seconds
  cacheTime: 10000, // Remove unused queries from memory after 10 seconds
}) => {
  const queryFn = getGqlRequestFn(query, variables);
  return useQuery(key, queryFn, options);
};

export const useGqlMutation = (mutation, options = {
  staleTime: 5000, // Keep data fresh for 5 seconds
  cacheTime: 10000, // Remove unused queries from memory after 10 seconds
}) => {
  const headers = { credentials: "include", mode: "cors" }; //REACTION SERVER
  // const headers = {}; //MOCK SERVER
  const graphQLClient = new GraphQLClient(endpoint, headers);
  const mutationFn = async (variables) =>
    await graphQLClient.request(mutation, variables);
  return useMutation(mutationFn, options);
};

export const useGqlSubscription = (subscription, variables, options = {}) => {
  const subscriptionFn = getGqlRequestFn(subscription, variables);
  return useSubscription(subscriptionFn, options);
};

// // REST - JSON SERVER

export const useApi = (apiFunc) => {
  const [data, setData] = useState(null);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const request = async (...args) => {
    setLoading(true);
    try {
      const result = await apiFunc(...args);
      setData(result.data);
    } catch (err) {
      setError(err.message || "Unexpected Error!");
    } finally {
      setLoading(false);
    }
  };

  return {
    data,
    error,
    loading,
    request,
  };
};