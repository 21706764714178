import styles from "../SettingsAccordion.module.scss";
import { NumberInput } from "components/inputs/input_fields/NumberInput/NumberInput";
import {
  DoughnutType,
  LineType,
  MetricType,
  TableType,
  ValueType,
} from "../../QChart/QuestionChart";
import FlexRow from "components/layouts/FlexRow/FlexRow";
import { ChartLabel, NumSliderTool, Switch } from "../Tools";

export const LayoutSettings = ({
  viz,
  title,
  changeSettingsField,
  visible,
  setVisible,
}) => {
  function handleBorderRadiusChange(val) {
    changeSettingsField("borderRadius", val);
  }

  function handleBarWidthChange(val) {
    val = val / 10;
    changeSettingsField("barPercentage", val);
  }

  function handleArcWidthChange(val) {
    // if (val === 10) {
    //   val = 0;
    // } else {
    //   val = (val - 10) * -1;
    //   val *= 10;
    // }

    val *= 10;
    changeSettingsField("cutout", val);
  }

  function getCutoutVal() {
    // if (viz.designSettings.cutout === 0) {
    //   return 10;
    // }
    // if (viz.designSettings.cutout) {
    //   return 10 - viz.designSettings.cutout / 10;
    // }
    // return 5;
    return (viz.designSettings.cutout ? viz.designSettings.cutout : 0) / 10;
  }

  return (
    <>
      <div
        key={viz.id}
        className={`${styles.header} ${visible ? styles.headervisible : ""}`}
        onClick={!visible ? setVisible : undefined}
      >
        <FlexRow
          onClick={visible ? setVisible : undefined}
          style={{ cursor: "pointer" }}
          className={styles.accordionTitle}
        >
          <div style={{ display: "flex", alignItems: "center", gap: "1em" }}>
            {title} <i style={{ fontSize: "1.2em" }} className="bi-columns"></i>
          </div>

          <span className={styles.accordionicon}>
            <i className="bi bi-caret-left-fill"></i>
          </span>
        </FlexRow>
        {visible && (
          <div className={styles.body} style={{ gap: "8px" }}>
            <div
              className={styles.setting}
              style={{
                gap: "0em",
                justifyContent: "center",
                paddingRight: "0px",
                // padding: "10px 0px",
              }}
            >
              <ChartLabel>Padding</ChartLabel>
              <div
                className={styles.setting}
                style={{
                  padding: "0px",
                  marginTop: "5px",
                  gap: ".5em",
                }}
              >
                <div
                  className={`${styles.itemB} `}
                  style={{ flexDirection: "column" }}
                >
                  <i className="bi-arrow-bar-up"></i>
                  <NumberInput
                    startNumber={
                      viz.designSettings?.paddingTop
                        ? viz.designSettings.paddingTop
                        : 0
                    }
                    handleNumberChange={(val) =>
                      changeSettingsField("paddingTop", val)
                    }
                    min={0}
                    max={500}
                  ></NumberInput>
                </div>
                <div
                  className={styles.setting2}
                  style={{ gap: ".5em", paddingRight: "0px" }}
                >
                  {" "}
                  <div className={`${styles.itemB} `}>
                    <i className="bi-arrow-bar-left"></i>
                    <NumberInput
                      startNumber={
                        viz.designSettings?.paddingLeft
                          ? viz.designSettings.paddingLeft
                          : 0
                      }
                      handleNumberChange={(val) =>
                        changeSettingsField("paddingLeft", val)
                      }
                      min={0}
                      max={500}
                    ></NumberInput>
                  </div>
                  <div className={`${styles.itemB} `}>
                    <NumberInput
                      startNumber={
                        viz.designSettings?.paddingRight
                          ? viz.designSettings.paddingRight
                          : 0
                      }
                      handleNumberChange={(val) =>
                        changeSettingsField("paddingRight", val)
                      }
                      min={0}
                      max={500}
                    ></NumberInput>
                    <i className="bi-arrow-bar-right"></i>
                  </div>
                </div>
                <div
                  className={`${styles.itemB} `}
                  style={{ flexDirection: "column" }}
                >
                  <NumberInput
                    startNumber={
                      viz.designSettings?.paddingBottom
                        ? viz.designSettings.paddingBottom
                        : 0
                    }
                    handleNumberChange={(val) =>
                      changeSettingsField("paddingBottom", val)
                    }
                    min={0}
                    max={500}
                  ></NumberInput>
                  <i className="bi-arrow-bar-down"></i>
                </div>
              </div>
            </div>

            <Switch
              checked={
                viz.designSettings.containerShadow === false ? false : true
              }
              onCheck={(val) => changeSettingsField("containerShadow", val)}
            >
              <div
                className={styles.borderShadowLabel}
                style={
                  !viz.designSettings.containerShadow
                    ? { boxShadow: "none" }
                    : undefined
                }
              >
                Shadow
              </div>
            </Switch>

            {viz.type != MetricType &&
              viz.type != ValueType &&
              viz.type != TableType && (
                <NumSliderTool
                  val={
                    viz.designSettings.zoomOut ? viz.designSettings.zoomOut : 0
                  }
                  min={0}
                  max={15}
                  step={1}
                  onChange={(val) => changeSettingsField("zoomOut", val)}
                >
                  Zoom
                </NumSliderTool>
              )}

            {viz.type === DoughnutType && (
              <NumSliderTool
                val={getCutoutVal()}
                min={0}
                max={9}
                step={1}
                onChange={handleArcWidthChange}
              >
                Cut Out Width
              </NumSliderTool>
            )}

            {viz.type === LineType && (
              <>
                <NumSliderTool
                  val={viz.designSettings.pointRadius}
                  min={0}
                  max={5}
                  step={1}
                  onChange={(val) => changeSettingsField("pointRadius", val)}
                >
                  Point Size
                </NumSliderTool>

                <NumSliderTool
                  val={viz.designSettings.lineGraphWidth}
                  min={0}
                  max={5}
                  step={1}
                  onChange={(val) => changeSettingsField("lineGraphWidth", val)}
                  title={" Line Width"}
                >
                  Line Width
                </NumSliderTool>
              </>
            )}
          </div>
        )}
      </div>
    </>
  );
};
