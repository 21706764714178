/*
======================= START OF LICENSE NOTICE =======================
  Copyright (C) 2023 Reaction. All Rights Reserved

  NO WARRANTY. THE PRODUCT IS PROVIDED BY DEVELOPER "AS IS" AND ANY
  EXPRESS OR IMPLIED WARRANTIES, INCLUDING, BUT NOT LIMITED TO, THE
  IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR
  PURPOSE ARE DISCLAIMED. IN NO EVENT SHALL DEVELOPER BE LIABLE FOR
  ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, OR CONSEQUENTIAL
  DAMAGES (INCLUDING, BUT NOT LIMITED TO, PROCUREMENT OF SUBSTITUTE
  GOODS OR SERVICES; LOSS OF USE, DATA, OR PROFITS; OR BUSINESS
  INTERRUPTION) HOWEVER CAUSED AND ON ANY THEORY OF LIABILITY, WHETHER
  IN CONTRACT, STRICT LIABILITY, OR TORT (INCLUDING NEGLIGENCE OR
  OTHERWISE) ARISING IN ANY WAY OUT OF THE USE OF THE PRODUCT, EVEN
  IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGE.
======================== END OF LICENSE NOTICE ========================
  Primary Author: natehanson, brodyspencer

*/
//External
import React, { useEffect, useState, useRef } from "react";

//Internal
import styles from "./QuestionConstruction.module.scss";
import Button from "components/Button/Button";
import {
  useCreateQuestionGql,
  useDeletePageGql,
  useDeleteQuestion,
  useMovePages,
  useUpdateQuestionGql,
} from "api/resources/projects/questions";
import { shortId } from "components/tables/EditableTable/utils";
import { useNavigate, useParams } from "react-router-dom";
import {
  useUpdateNumPages,
  useUpdateProjectDesignSettingsGql,
} from "api/resources/projects/projects";
import { SurveyDesign } from "./SurveyDesign/SurveyDesign";
import Survey from "components/Survey/Survey";
import {
  useCreateSectionGql,
  useDeleteSectionGql,
  useUpdateSectionGql,
} from "api/resources/projects/sections";
import {
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  closestCorners,
  DragOverlay,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { Loading } from "components/Loading/Loading";
import EditableNavigation from "./EditableNavigation/EditableNavigation";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import EditLogo from "./EditableLogo/EditLogo";
import EditableProgressBar from "./EditableProgressBar/EditableProgressBar";
import SettleLogic from "./QuestionLogic/SettleLogic";
import SurveyComponent from "./SurveyComponent/SurveyComponent";
import { forEach, getSortedArray } from "assets/functions/ArrayFunctions";
import { createEncodingCopy } from "components/inputs/input_fields/TextEditorTry/TextEditorTry";
import Stat from "components/tables/BasicTable/SurveyWidgets/Stat";
import { Pie } from "components/inputs/input_fields/CircleProgress/Circle";
import {
  calculateQuestionHealth,
  calculateSurveyHealth,
} from "assets/functions/SurveyHealth";
import DoYouWantToSave from "components/Popout/DoYouWantToSave";
import SurveyCompletedInConstruction from "./Finished/SurveyCompleted";
import FlexRow from "components/layouts/FlexRow/FlexRow";
import Icon from "components/Icon/Icon";
import { HorizontalBar } from "components/layouts/HorizontalBar/HorizontalBar";
import { trimDate } from "assets/functions/DateFunctions";
import Header from "components/tables/EditableTable/Header";
import TriggersModal from "./Triggers/TriggersModal";
import SurveyLogic from "./QuestionLogic/SurveyLogic";
import { isConditionComplete } from "assets/functions/ObjectFunctions";

const sectionSettings = {
  bodySize: 14,
  bodyColor: "#616565",
  showBody: true,
  bodyItalics: true,
  headerSize: 22,
  headerColor: "#616565",
  showHeader: true,
  headerItalics: false,
  sectionShadow: '',
};

/**
 * A component that when passed a list of questions will display them and let you edit them
 * @param {Array} initQuestions used to handle clicking outside of the popout to close it
 * @returns {React.ReactElement} a QuestionConstruction component
 */
export function QuestionConstruction({
  initFinishedMessage,
  maxPage,
  setMaxPage,
  status,
  roles,
  designSettings,
  setDesignSettings,
  currPage,
  setCurrPage,
  questions,
  setQuestions,
  allQuestions,
  setAllQuestions,
  refetchQs,
  refetchDSettings,
  showLoading,
  setShowLoading,
  projectId,
  projectOrgId,
  orgId,
  survey,
  setFullscreen,
  fullscreen,
  seeAll,
  setSeeAll,
  pages,
  setPages,
}) {
  const [activeId, setActiveId] = useState("");
  const [activeQ, setActiveQ] = useState("");
  const [overInd, setOverInd] = useState(-1);
  const [close, setClose] = useState(-2);

  const { id } = useParams();
  const [activeTab, setActiveTab] = useState();
  const [savingStatus, setSavingStatus] = useState(null);
  const [showFinshedPage, setShowFinishedPage] = useState(false);
  const [finishedMsg, setFinishedMsg] = useState(initFinishedMessage);
  const [seeDesignSettings, setSeeDesignSettings] = useState(false);
  const [updatedAt, setUpdatedAt] = useState(survey?.updatedAt);
  const [triggers, setTriggers] = useState(false);
  // const [copy, setCopy] = useState();
  const [answers, setAnswers] = useState({});
  const [snapShot, setSnapShot] = useState();
  const [dependencies, setDependencies] = useState([]);
  const [dependencyResolver, setDependencyResolver] = useState();
  const [editedDesign, setEditedDesign] = useState(false);
  const [editedQs, setEditedQs] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const [active, setActive] = useState("");

  const [headerActive, setHeaderActive] = useState(false);
  const [footerActive, setFooterActive] = useState(false);
  const [previewMode, setPreviewMode] = useState("Desktop");
  const [finishedActive, setFinishedActive] = useState(false);
  const [save, setSave] = useState(false);

  useEffect(() => {
    if (save) {
      saveQuestions();
      setSave(false);
    }
  }, [save]);

  const saveQuestion = useUpdateQuestionGql();
  const createQuestion = useCreateQuestionGql();
  const deleteQuestion = useDeleteQuestion();
  const deleteSection = useDeleteSectionGql();
  const updateNumPages = useUpdateNumPages();
  const deletePage = useDeletePageGql();
  const updateSettings = useUpdateProjectDesignSettingsGql(id);
  const createSection = useCreateSectionGql();
  const updateSection = useUpdateSectionGql();
  const movePage = useMovePages();

  function checkRole() {
    if (orgId === projectOrgId) {
      return roles.canCreateEditOrgSurveys;
    } else {
      return true;
    }
  }

  useEffect(() => {
    setUpDependencies();
  }, [allQuestions]);

  function setUpDependencies() {
    let dependentRecord = [];

    for (let q of allQuestions) {
      if (
        q.type !== "Description" &&
        q?.conditionGroups &&
        q.conditionGroups.length
      ) {
        for (let group of q.conditionGroups) {
          if (group?.operator && group?.conditions) {
            for (let condition of group.conditions) {
              if (!isConditionComplete(condition)) continue;

              let record = dependentRecord.find(
                (d) => d.questionId === condition.questionId
              );
              if (!record) {
                // Create a dependency record on this question
                record = {
                  questionId: condition.questionId,
                  dependencies: [],
                };
                dependentRecord.push(record);
              }

              // Keep the record of each dependent question contained in one, in other words every dependency is a seperate question
              let subRecord = record.dependencies.find(
                (d) => d.dependentId === q.id
              );
              if (!subRecord) {
                subRecord = {
                  dependentId: q.id,
                  dependentName: q.questionText,
                };
                record.dependencies.push(subRecord);
              }

              const dependsOn = allQuestions.find(
                (q) => q.id === condition.questionId
              );

              if (!dependsOn) {
                continue;
              }

              if (
                condition.operator === "answer" ||
                condition.operator === "do not answer"
              ) {
                if (subRecord.keep) {
                  subRecord.keep = [...subRecord.keep, ...condition.answer];
                } else {
                  subRecord.keep = condition.answer;
                }
              } else if (
                dependsOn.type === "NumberScale" ||
                dependsOn.type === "NumberSlider"
              ) {
                let keep = [];

                if (condition.operator === "between") {
                  keep = getSortedArray(condition.answer, (a, b) => a - b);
                } else if (
                  condition.operator === "above" ||
                  condition.operator === "below"
                ) {
                  keep.push(condition.answer);
                }

                if (subRecord.keep) {
                  subRecord.keep = [...subRecord.keep, ...keep];
                } else {
                  subRecord.keep = keep;
                }
              } else if (
                dependsOn.type === "Text" &&
                condition.operator === "is longer than"
              ) {
                if (
                  !("keepLimitAbove" in subRecord) ||
                  subRecord.keepLimitAbove < condition.answer
                ) {
                  subRecord.keepLimitAbove = condition.answer;
                }
              }

              if (dependsOn.type === "Matrix") {
                if (subRecord.keepOptions) {
                  subRecord.keepOptions = [
                    ...subRecord.keepOptions,
                    condition.option,
                  ];
                } else {
                  subRecord.keepOptions = [condition.option];
                }
              }
            }
          }
        }
      }
    }
    setDependencies(dependentRecord);
  }

  function clearsBaselineLogic(question) {
    // Checks to see if there are any dependencies. Returns false if so. For operations like delete, moving below, and changing the quesion type
    // Not to check specific changes in the question
    let dependents = dependencies.find((d) => d.questionId === question.id);
    if (dependents) {
      return false;
    }
    return true;
  }

  function editFinishMessage(msg) {
    setFinishedMsg(msg);
    setSavingStatus(<Loading height={50} width={50}></Loading>);

    updateSettings.mutate(
      {
        updateProjectId: id,
        data: {
          finishedMessage: msg,
        },
      },
      {
        onSuccess: (data) => {
          setUpdatedAt(data.updateProject.updatedAt);
          fixSaveStatus();
        },
      }
    );
  }

  function setUpLogicResolver(areYouSure, action, question, onContinue) {
    setDependencyResolver(
      <SettleLogic
        areYouSure={areYouSure}
        action={action}
        question={question}
        onCarryThrough={() => {
          onContinue();
          setDependencyResolver(null);
          setSave(true);
        }}
        onNevermind={() => {
          setDependencyResolver(null);
        }}
        dependencies={dependencies}
        allQuestions={allQuestions}
        saveQuestions={onEditAllQs}
      />
    );
  }

  function onEditQ(question) {
    let index = questions.findIndex((item) => item.id === question.id);
    let allIndex = allQuestions.findIndex((q) => q.id === question.id);
    let pageInd = pages[currPage - 1].findIndex((q) => q.id == question.id);

    let tempQs = [...questions];
    let tempAll = [...allQuestions];
    let tempPages = [...pages];

    tempQs[index] = { ...question };
    tempAll[allIndex] = { ...question };
    tempPages[currPage - 1][pageInd] = { ...question };

    tempAll[allIndex].changed = true;

    setQuestions(tempQs);
    setAllQuestions(tempAll);
    setPages(tempPages);

    saveIndividualItem(tempAll[allIndex], allIndex);
  }

  function saveIndividualItem(item, quesitonNum) {
    if (item?.changed || item?.new) {
      setSavingStatus(<Loading height={50} width={50}></Loading>);

      updateSettings.mutate(
        {
          updateProjectId: id,
          data: {
            updatedAt: new Date(),
          },
        },
        {
          onSuccess: (data) => {
            setUpdatedAt(data.updateProject.updatedAt);
          },
        }
      );
      delete item.changed;
      if ("questionText" in item) {
        let copy = { ...item, index: quesitonNum };
        copy.conditionGroups = item?.conditionGroups
          ? JSON.stringify(item.conditionGroups)
          : null;
        copy.triggers = item?.triggers
          ? JSON.stringify(item.triggers)
          : undefined;

        copy.buckets =
          item?.buckets && typeof item.buckets !== "string"
            ? JSON.stringify(item.buckets)
            : item.buckets;

        if (copy.choiceQuestion) {
          delete copy.choiceQuestion.id;
        }
        if (copy.textQuestion) {
          copy.textQuestion = { ...copy.textQuestion };
          delete copy.textQuestion.id;
          delete copy.textQuestion.bucket; // Taken care of in Buckets.jsx
        }
        if (copy.scaleQuestion) {
          delete copy.scaleQuestion.id;
        }
        if (copy.matrixQuestion) {
          copy.matrixQuestion = JSON.parse(JSON.stringify(copy.matrixQuestion)); // don't alter the current state
          copy.matrixQuestion.settings = JSON.stringify(
            copy.matrixQuestion.settings
          );
          delete copy.matrixQuestion.id;
        }

        delete copy.id;
        if (copy?.answer) {
          delete copy.answer;
        }
        delete copy.questionTag; // Taken care of in EditTags.jsx

        if (copy?.new) {
          delete copy.new;
          createQuestion.mutate(
            {
              data: copy,
            },
            {
              onSuccess: (data) => {
                fixSaveStatus();
                refetchQs();
              },
            }
          );
        } else {
          saveQuestion.mutate(
            {
              id: item.id,
              data: copy,
            },
            {
              onSuccess: (data) => {
                fixSaveStatus();
              },
            }
          );
        }
      } else {
        // It's a section
        let copy = { ...item };

        copy.sectionSettings = JSON.stringify(item.sectionSettings);
        copy.conditionGroups = item?.conditionGroups
          ? JSON.stringify(item.conditionGroups)
          : undefined;
        delete copy.id;

        if (copy?.new) {
          delete copy.new;

          createSection.mutate(
            {
              data: copy,
            },
            {
              onSuccess: (data) => {
                fixSaveStatus();
                refetchQs();
              },
            }
          );
        } else {
          updateSection.mutate(
            {
              id: item.id,
              data: copy,
            },
            {
              onSuccess: (data) => {
                fixSaveStatus();
              },
            }
          );
        }
      }
    }
  }

  function onEditAllQs(copyOfAll) {
    let page = [];
    for (let one of copyOfAll) {
      if (one.pageNumber == currPage) {
        page.push(one);
      }
    }
    setQuestions(page);
    setAllQuestions(copyOfAll);

    let pages = [[]];
    let pageInd = 1;
    for (let q of copyOfAll) {
      if (q.pageNumber !== pageInd) {
        pageInd++;
        pages.push([q]);
      } else {
        pages[pages.length - 1].push(q);
      }
    }
    setPages(pages);

    setSave(true);
  }

  function onEditQs(qArray) {
    let copyOfAll = [...allQuestions];
    for (let q of qArray) {
      q.changed = true;
      let ind = copyOfAll.findIndex((qst) => q.id === qst.id);
      if (ind > -1) copyOfAll[ind] = q;
    }

    onEditAllQs(copyOfAll);
  }

  function deleteQ(question, e) {
    if (window.confirm("Are you sure you want to delete this question?")) {
      if (!("questionText" in question) || clearsBaselineLogic(question)) {
        finishDeleteQ(question, e);
      } else {
        setDependencyResolver(
          <SettleLogic
            areYouSure={"delete"}
            action={"delete"}
            question={question}
            onCarryThrough={() => {
              finishDeleteQ(question, e);
              setDependencyResolver(null);
            }}
            onNevermind={() => {
              setDependencyResolver(null);
              if (!activeId) {
                // just on hover
                setClose(overInd - 1);
                setTimeout(() => {
                  setClose(overInd);
                  setOverInd(-1);
                }, 50);
              }
            }}
            dependencies={dependencies}
            allQuestions={allQuestions}
            saveQuestions={onEditAllQs}
          />
        );

        if (!activeId || activeId !== question.id) {
          e.stopPropagation(); // don't run the onQuestionClick
        }
      }
    }
  }

  function finishDeleteQ(question, e, update) {
    setSavingStatus(<Loading height={50} width={50}></Loading>);
    let index = questions.findIndex((q) => q.id === question.id);
    let tempAll = [...allQuestions];
    let allInd = allQuestions.findIndex((q) => q.id === question.id);
    tempAll.splice(allInd, 1);

    let thisPage = [];
    let questionNumber = 1;
    for (let i = 0; i < tempAll.length; i++) {
      let q = tempAll[i];
      if ("questionText" in q) {
        q.index = questionNumber;
        q.changed = true;
        questionNumber += 1;
      }
      if (q.pageNumber === question.pageNumber) {
        if (q.pageOrderIndex > question.pageOrderIndex) {
          q.pageOrderIndex--;
          q.changed = true;
        }
        thisPage.push(q);
      }
    }
    let pages2 = [];
    for (let i = 0; i < maxPage; i++) {
      pages2.push([]);
    }
    for (let q of tempAll) {
      pages2[q?.pageNumber - 1].push(q);
    }

    setPages(pages2);
    setQuestions(thisPage);
    setAllQuestions(tempAll);

    if (activeId === question.id) {
      setOverInd(-1);
      setActiveId("");
      setActive("");
      setActiveQ(null);
    } else if (!activeId) {
      // just hovering
      setOverInd(-1);
    } else {
      // while active on a different Q
      e.stopPropagation(); // Don't let the onQuestionClick function run
      if (overInd > index) {
        setOverInd(overInd - 1);
      }
    }

    if (!question?.new) {
      // It decrements the others' pageOrderIndex if needed in the backend as well.

      if (question.type === "Description") {
        deleteSection.mutate(
          {
            id: question.id,
          },
          {
            onSuccess: () => {},
          }
        );
      } else {
        deleteQuestion.mutate(
          {
            id: question.id,
          },
          {
            onSuccess: () => {
              setSavingStatus(null);
            },
          }
        );
      }
    }

    setSave(true);
  }

  function getAppendIndeces() {
    let pageNumber = 0;
    let pageOrderIndex = 0;
    if (seeAll) {
      pageNumber = pages.length;
      pageOrderIndex = pages[pages.length - 1].length;
    } else {
      pageNumber = currPage;
      pageOrderIndex = questions.length;
    }

    return [pageOrderIndex, pageNumber];
  }

  function appendQ() {
    let [index, page] = getAppendIndeces();
    handleCreateSection(index, page);
  }

  function appendSection() {
    let [index, page] = getAppendIndeces();
    handleCreateSection(index, page);
  }

  function handleCreateQuestion(index, page) {
    let tempId = "temporaryid:" + shortId();
    const question = {
      id: tempId,
      type: "MultipleChoice",
      projectId: id,
      pageOrderIndex: index,
      pageNumber: page ? page : currPage,
      isHidden: false,
      description: "",
      buckets: [],
      questionTag: [],
      hasBuckets: false,
      new: true,
      choiceQuestion: {
        isMultiSelect: false,
        isRandomized: false,
        hasOtherOption: false,
        otherOptionLabel: "Other",
        choices: [],
        isRanking: false,
      },
      questionText: "New Question",
      encoding: "",
      descriptionEncoding: "",
    };
    addQToState(question, page ? page : currPage);
  }

  function handleCreateSection(index, page) {
    let tempId = "temporarySectionId:" + shortId();
    const section = {
      id: tempId,
      type: "Description",
      projectId: id,
      pageOrderIndex: index,
      new: true,
      pageNumber: page ? page : currPage,
      sectionSettings: sectionSettings,
      bodyText: null,
      encoding: null,
    };
    addQToState(section, page ? page : currPage);
  }

  function addQToState(question, page) {
    setSavingStatus(<Loading height={50} width={50}></Loading>);
    let tempAll = [...allQuestions];
    let ind = 0;

    for (let i = 0; i < allQuestions.length; i++) {
      if (allQuestions[i].pageNumber === page) {
        ind += question.pageOrderIndex;
        break;
      }
      ind++;
    }

    tempAll.splice(ind, 0, question);

    let questionNumber = 1;
    let thisPage = [];
    let curPage = 1;
    let pageIndex = 1;
    for (let i = 0; i < tempAll.length; i++) {
      let q = tempAll[i];
      if ("questionText" in q || q?.questionText === "") {
        q.index = questionNumber;
        q.changed = true;
        questionNumber += 1;
      }
      if (curPage != q?.pageNumber) {
        curPage++;
        pageIndex = 1;
      }
      if (q.pageOrderIndex != pageIndex) {
        q.pageOrderIndex = pageIndex;
        q.changed = true;
      }
      pageIndex++;
    }

    let pages2 = [];
    for (let i = 0; i < maxPage; i++) {
      pages2.push([]);
    }
    for (let q of tempAll) {
      pages2[q?.pageNumber - 1].push(q);
    }

    setPages(pages2);
    setAllQuestions(tempAll);
    setQuestions(thisPage);
    setOverInd(question.pageOrderIndex);
    setSave(true);
  }

  //functionality for pages
  function nextPage() {
    if (currPage + 1 <= maxPage) {
      setCurrPage(currPage + 1);
      setActiveId("");
      scrollToTop();
      refetchQs(currPage + 1);
    }
  }

  function previousPage() {
    if (currPage - 1 > 0) {
      setCurrPage(currPage - 1);
      setActiveId("");
      scrollToTop();
      refetchQs(currPage - 1);
    }
  }

  function scrollToTop() {
    let container = document.getElementById("constructionZone");
    if (container) {
      container.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    }
  }

  function addPage() {
    updateNumPages.mutate(
      {
        id: id,
        data: { numPages: maxPage + 1 },
      },
      {
        onSuccess: () => {
          setMaxPage((page) => page + 1);
          setCurrPage(maxPage + 1);
          setActiveId("");
        },
      }
    );
  }

  function saveQuestions() {
    let quesitonNum = 1;

    for (let item of allQuestions) {
      if (item?.changed || item?.new) {
        delete item.changed;
        if ("questionText" in item) {
          let copy = { ...item, index: quesitonNum };
          copy.conditionGroups = item?.conditionGroups
            ? JSON.stringify(item.conditionGroups)
            : null;
          copy.triggers = item?.triggers
            ? JSON.stringify(item.triggers)
            : undefined;

          copy.buckets =
            item?.buckets && typeof item.buckets !== "string"
              ? JSON.stringify(item.buckets)
              : item.buckets;

          if (copy.choiceQuestion) {
            delete copy.choiceQuestion.id;
          }
          if (copy.textQuestion) {
            copy.textQuestion = { ...copy.textQuestion };
            delete copy.textQuestion.id;
            delete copy.textQuestion.bucket; // Taken care of in Buckets.jsx
          }
          if (copy.scaleQuestion) {
            delete copy.scaleQuestion.id;
          }
          if (copy.matrixQuestion) {
            copy.matrixQuestion = JSON.parse(
              JSON.stringify(copy.matrixQuestion)
            ); // don't alter the current state
            copy.matrixQuestion.settings = JSON.stringify(
              copy.matrixQuestion.settings
            );
            delete copy.matrixQuestion.id;
          }

          delete copy.id;
          if (copy?.answer) {
            delete copy.answer;
          }
          delete copy.questionTag; // Taken care of in EditTags.jsx

          if (copy?.new) {
            delete copy.new;
            createQuestion.mutate(
              {
                data: copy,
              },
              {
                onSuccess: (data) => {
                  refetchQs();
                  fixSaveStatus();
                },
              }
            );
          } else {
            saveQuestion.mutate(
              {
                id: item.id,
                data: copy,
              },
              {
                onSuccess: (data) => {
                  refetchQs();
                  fixSaveStatus();
                },
              }
            );
          }
        } else {
          // It's a section
          let copy = { ...item };

          copy.sectionSettings = JSON.stringify(item.sectionSettings);
          copy.conditionGroups = item?.conditionGroups
            ? JSON.stringify(item.conditionGroups)
            : undefined;
          delete copy.id;

          if (copy?.new) {
            delete copy.new;

            createSection.mutate(
              {
                data: copy,
              },
              {
                onSuccess: (data) => {
                  refetchQs();
                  fixSaveStatus();
                },
              }
            );
          } else {
            updateSection.mutate(
              {
                id: item.id,
                data: copy,
              },
              {
                onSuccess: (data) => {
                  refetchQs();
                  fixSaveStatus();
                },
              }
            );
          }
        }
      }
      if ("questionText" in item) {
        quesitonNum += 1;
      }
    }
  }

  function saveDesign(settings) {
    setSavingStatus(<Loading height={50} width={50}></Loading>);

    setDesignSettings(settings);
    let json =
      typeof settings === "string" ? settings : JSON.stringify(settings);

    updateSettings.mutate(
      {
        updateProjectId: id,
        data: {
          designSettings: json,
          surveyInstruction: "",
          finishedMessage: finishedMsg,
        },
      },
      {
        onSuccess: (data) => {
          // let newSettings = JSON.parse(data.updateProject.designSettings);
          // setDesignSettings(newSettings);
          setUpdatedAt(data.updateProject.updatedAt);
          fixSaveStatus();
        },
      }
    );
    setEditedDesign(false);
  }

  function fixSaveStatus() {
    setSavingStatus(null);
  }

  function onSaveClick() {
    if (editedQs || editedDesign) {
      setSave(true);
    } else {
      setSavingStatus(<Loading height={50} width={50}></Loading>);
      setTimeout(() => fixSaveStatus(), 1000); // Purely for user's peace of mind.
      refetchQs();
    }
  }

  function switchPages(activeInd, overInd) {
    if (dependencies.length) {
      let activeQs = [];
      forEach(allQuestions, (q) => {
        if (q.pageNumber == activeInd) {
          activeQs.push(q);
        }
      });

      if (activeInd < overInd) {
        // moving down
        // Can break other's
        // focus on active page's
        let dependedOn_WillBreak = [];
        for (let q of activeQs) {
          let dependents = dependencies.find((d) => d.questionId === q.id);
          if (dependents) {
            for (let dependent of dependents.dependencies) {
              let depQ = allQuestions.find(
                (que) => que.id === dependent.dependentId
              );
              if (depQ.pageNumber > activeInd && depQ.pageNumber <= overInd) {
                // they will now be behind it.
                dependedOn_WillBreak.push(q);
                break;
              }
            }
          }
        }

        if (dependedOn_WillBreak.length) {
          setDependencyResolver(
            <SettleLogic
              areYouSure={"move"}
              action={"move"}
              questions={dependedOn_WillBreak}
              onCarryThrough={() => {
                setDependencyResolver(null);
                setSave(true);
                setShowLoading(true);
                setTimeout(() => {
                  finishSwitchingPages(activeInd, overInd);
                }, 1000);
              }}
              onNevermind={() => {
                setDependencyResolver(null);
              }}
              dependencies={dependencies}
              allQuestions={allQuestions}
              saveQuestions={onEditAllQs}
            />
          );
          return;
        }
      } else {
        // moving up
        // Can break its own
        let shouldBeRemoved = [];
        for (let q of activeQs) {
          if (q.conditionGroups) {
            for (let g of q.conditionGroups) {
              for (let c of g.conditions) {
                let dependency = allQuestions.find(
                  (qst) => qst.id === c.questionId
                );
                if (
                  dependency.pageNumber < activeInd &&
                  dependency.pageNumber >= overInd
                ) {
                  shouldBeRemoved.push(q);
                  break;
                }
              }
            }
          }
        }

        if (shouldBeRemoved.length) {
          setDependencyResolver(
            <SettleLogic
              removeItsOwn
              areYouSure={"move"}
              action={"move"}
              questions={shouldBeRemoved}
              onCarryThrough={() => {
                setDependencyResolver(null);
                setSave(true);
                setShowLoading(true);
                setTimeout(() => {
                  finishSwitchingPages(activeInd, overInd);
                }, 1000);
              }}
              onNevermind={() => {
                setDependencyResolver(null);
              }}
              dependencies={dependencies}
              allQuestions={allQuestions}
              saveQuestions={onEditAllQs}
            />
          );
          return;
        }
      }
    }

    finishSwitchingPages(activeInd, overInd);
  }

  function finishSwitchingPages(activeInd, overInd) {
    setShowLoading(true);

    if (designSettings?.pageSettings) {
      let newSettings = JSON.parse(JSON.stringify(designSettings));
      let changed = false;
      let oldActive = newSettings.pageSettings[activeInd];

      if (activeInd > overInd) {
        // moving up
        for (let i = activeInd - 1; i >= overInd; i--) {
          if (newSettings.pageSettings[i]) {
            newSettings.pageSettings[i + 1] = newSettings.pageSettings[i];
            delete newSettings.pageSettings[i];
            changed = true;
          }
        }
      } else {
        // moving down
        for (let i = activeInd + 1; i <= overInd; i++) {
          if (newSettings.pageSettings[i]) {
            newSettings.pageSettings[i - 1] = newSettings.pageSettings[i];
            delete newSettings.pageSettings[i];
            changed = true;
          }
        }
      }
      if (oldActive) {
        newSettings.pageSettings[overInd] = oldActive;
        changed = true;
      }

      if (changed) {
        saveDesign(JSON.stringify(newSettings));
      }
    }

    movePage.mutate(
      {
        projectId: id,
        activePageNum: activeInd,
        overPageNum: overInd,
      },
      {
        onSuccess: (data) => {
          refetchQs();
        },
      }
    );
  }

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  function handleDragStart(event) {
    const { active } = event;
    setMovingId(active.id);
    setClose(overInd - 1);
    setTimeout(() => {
      setClose(overInd);
      setOverInd(-1);
    }, 50);
    setActiveId("");

    let copy = questions.map((q) => q.id);
    setSnapShot(copy);
  }

  function handleDragOver(event) {
    const { active, over } = event;
    if (seeAll) {
      if (active.id !== over.id) {
        //update allQuestions
        let oldIndex = allQuestions.findIndex((q) => q.id === active.id);
        let newIndex = allQuestions.findIndex((q) => q.id === over.id);
        let overQ = allQuestions.find((q) => q.id === over.id);
        let temparray = arrayMove(allQuestions, oldIndex, newIndex);
        temparray[newIndex].pageNumber = overQ.pageNumber;

        let currpage = 1;
        let index = 1;
        let questionNumber = 1;
        for (let i = 0; i < temparray.length; i++) {
          if ("questionText" in temparray[i]) {
            temparray[i].index = questionNumber;
            questionNumber += 1;
          }
          while (temparray[i].pageNumber != currpage) {
            currpage += 1;
            index = 1;
          }
          if (temparray[i].pageNumber === currpage) {
            temparray[i].pageOrderIndex = index;
            index += 1;
          }
          temparray[i].changed = true;
        }
        let tempall = [...temparray];

        let tempQArrays = [];
        currpage = 1;
        let tempQs = [];
        for (let q of tempall) {
          if (q.pageNumber === currpage) {
            tempQs.push(q);
          } else {
            currpage += 1;
            tempQArrays.push(tempQs);
            tempQs = [];
            tempQs.push(q);
          }
        }
        tempQArrays.push(tempQs);
        if (currpage < maxPage) {
          while (currpage < maxPage) {
            tempQArrays.push([]);
            currpage += 1;
          }
        }

        let pages2 = [];
        for (let i = 0; i < maxPage; i++) {
          pages2.push([]);
        }
        for (let q of temparray) {
          pages2[q?.pageNumber - 1].push(q);
        }

        setAllQuestions(temparray);
        setQuestions(tempQArrays);
        setPages(pages2);
      }
    } else {
      if (active.id !== over.id) {
        const oldAllInd = allQuestions.findIndex((q) => q.id === active.id);
        const newAllInd = allQuestions.findIndex((q) => q.id === over.id);

        let all = arrayMove(allQuestions, oldAllInd, newAllInd);
        all[oldAllInd].changed = true;
        all[newAllInd].changed = true;

        let thisPage = [];
        let questionNumber = 1;
        for (let q of all) {
          if ("questionText" in q) {
            q.index = questionNumber;
            questionNumber += 1;
          }
          if (q.pageNumber === currPage) {
            q.pageOrderIndex = thisPage.length;
            thisPage.push(q);
          }
        }

        setQuestions(thisPage);
        setAllQuestions(all);
        setEditedQs(true);
      }
    }
  }

  function handleDragEnd(event) {
    // checking for dependency issues.
    // moving down
    // by moving down it will only break other's logic
    let resolve = false;
    let dependents = dependencies.find((d) => d.questionId === movingId);
    if (dependents) {
      let movingInd = questions.findIndex((q) => q.id === movingId);
      for (let dependent of dependents.dependencies) {
        let dependentIndex = allQuestions.findIndex(
          (q) => q.id === dependent.dependentId
        );
        if (movingInd > dependentIndex) {
          setDependencyResolver(
            <SettleLogic
              areYouSure={"move"}
              action={"move"}
              question={questions.find((q) => q.id === movingId)}
              onCarryThrough={() => {
                setDependencyResolver(null);
                setSave(true);
              }}
              onNevermind={() => {
                setDependencyResolver(null);
                setBackToSnapShot();
              }}
              dependencies={dependencies}
              allQuestions={allQuestions}
              saveQuestions={onEditAllQs}
            />
          );
          resolve = true;
          break;
        }
      }
    }

    if (!resolve) {
      // moving Up
      // by moving up it will only break it's own
      let movingQ = questions.find((q) => q.id === movingId);
      if (movingQ?.conditionGroups && movingQ?.conditionGroups?.length) {
        let dependsOn = [];
        forEach(movingQ?.conditionGroups, (g) => {
          forEach(g.conditions, (c) => {
            if (!dependsOn.includes(c.questionId)) {
              dependsOn.push(c.questionId);
            }
          });
        });

        let movingInd = questions.findIndex((q) => q.id === movingId);
        for (let id of dependsOn) {
          let index = allQuestions.findIndex((q) => q.id === id);
          if (movingInd < index) {
            setDependencyResolver(
              <SettleLogic
                removeItsOwn
                areYouSure={"move"}
                action={"move"}
                question={questions.find((q) => q.id === movingId)}
                onCarryThrough={() => {
                  setDependencyResolver(null);
                  setSave(true);
                }}
                onNevermind={() => {
                  setDependencyResolver(null);
                  setBackToSnapShot();
                }}
                dependencies={dependencies}
                allQuestions={allQuestions}
                saveQuestions={onEditAllQs}
              />
            );
            resolve = true;
            break;
          }
        }
      }
    }

    setMovingId("");
    setSavingStatus(<Loading height={50} width={50}></Loading>);
    setSave(true);
  }

  function setBackToSnapShot() {
    let order = [];

    for (let i = 0; i < snapShot.length; i++) {
      let q = questions.find((q) => q.id === snapShot[i]);
      q.pageOrderIndex = i;
      order.push(q);
    }

    setSnapShot(undefined);
    setQuestions(order);
    // no need for all, since nothing should've updated
  }

  const [movingId, setMovingId] = useState("");

  function getActive() {
    let question = allQuestions.find((q) => q.id === movingId);

    return (
      <div>
        <SurveyComponent
          component={question}
          active={activeId === question.id}
          status={status}
          moveTo={moveTo}
          canEdit={checkRole()}
          designSettings={designSettings}
          roles={roles}
          answers={answers}
          survey={survey}
          calculateQuestionHealth={calculateQuestionHealth}
          questionNumber={
            question?.index ? question?.index : getNumber(question)
          }
        ></SurveyComponent>
      </div>
    );
  }

  function onDeletePage(number) {
    let hadPageSettings =
      designSettings?.pageSettings && number in designSettings.pageSettings;
    let name = hadPageSettings ? designSettings.pageSettings[number] : number;
    if (
      window.confirm(
        "Are you sure you want to delete page " +
          name +
          "? All questions on it will also be deleted"
      )
    ) {
      let newSettings = JSON.parse(JSON.stringify(designSettings));
      if (hadPageSettings) {
        delete newSettings.pageSettings[number];
      }
      if (newSettings?.pageSettings) {
        for (let i = number + 1; i < maxPage + 1; i++) {
          if (i in newSettings.pageSettings) {
            let copy = newSettings.pageSettings[i];
            newSettings.pageSettings[i - 1] = copy;
            delete newSettings.pageSettings[i];
          }
        }
      }

      deletePage.mutate(
        {
          projectId: id,
          pageNumber: number,
          designSettings: JSON.stringify(newSettings),
        },
        {
          onSuccess: () => {
            let newPage = currPage;
            if (currPage >= number && currPage > 1) {
              newPage = currPage - 1;
            }
            refetchQs(newPage);
            refetchDSettings();
            setMaxPage((max) => max - 1);
          },
        }
      );
    }
  }

  function onCopy(question, e, placement) {
    let copy = {};
    let index = pages[question?.pageNumber - 1].length;
    if (placement === "abovepage") {
      index = 0;
    } else if (placement === "above") {
      let q = allQuestions.find((q) => q.id === question.id);
      if (q.pageOrderIndex <= 1) {
        index = 0;
      } else {
        index = q.pageOrderIndex - 1;
      }
    } else if (placement === "below") {
      let q = allQuestions.find((q) => q.id === question.id);
      index = q.pageOrderIndex;
    }
    if ("questionText" in question) {
      copy = {
        id: "temporaryid:" + shortId(),
        type: question.type,
        projectId: id,
        pageOrderIndex: index,
        pageNumber: question?.pageNumber,
        isHidden: false,
        description: "",
        // buckets: [],
        // hasBuckets: false,
        new: true,
        questionText: question.questionText,
        description: question.description,
        encoding: createEncodingCopy(question.encoding),
        descriptionEncoding: createEncodingCopy(question.descriptionEncoding),
      };
      if (question.choiceQuestion) {
        copy.choiceQuestion = JSON.parse(
          JSON.stringify(question.choiceQuestion)
        ); // has second level of items
      }
      if (question.scaleQuestion) {
        copy.scaleQuestion = { ...question.scaleQuestion };
      }
      if (question.textQuestion) {
        copy.textQuestion = { ...question.textQuestion };
      }
      if (question.matrixQuestion) {
        copy.matrixQuestion = JSON.parse(
          JSON.stringify(question.matrixQuestion)
        ); // has second level of items
      }
    } else {
      copy = {
        id: "temporarySectionId:" + shortId(),
        type: "Description",
        projectId: id,
        pageOrderIndex: index,
        new: true,
        pageNumber: question?.pageNumber,
        sectionSettings: JSON.parse(JSON.stringify(question.sectionSettings)),
        bodyText: question.bodyText,
        encoding: createEncodingCopy(question.encoding),
      };
    }

    addQToState(copy, question?.pageNumber);
  }

  function onPaste(index) {
    if (copy) {
      let it = { ...copy };
      it.pageNumber = currPage;
      it.pageOrderIndex = index;
      addQToState(it);
      setCopy(null);
    }
  }

  function onAnswer(qId, answer) {
    let copy = { ...answers };
    copy[qId] = answer;
    setAnswers(copy);
  }

  function onRetract(qId) {
    let copy = { ...answers };
    delete copy[qId];
    setAnswers(copy);
  }

  const getNumQs = () => {
    let count = 0;
    forEach(allQuestions, (q) => {
      if (q.type !== "Description") {
        count++;
      }
    });
    return count;
  };
  const numQs = getNumQs();

  function checkToSave(navAway) {
    return <DoYouWantToSave navAway={navAway} onSave={() => setSave(true)} />;
  }

  function changeSettings(newSettings) {
    saveDesign(newSettings);
    // setBeforeNavAway({
    //   getDisplay: checkToSave,
    // });
  }

  function setUpActiveQ(question) {
    if (activeId !== question.id) {
      setActiveTab("");
      setActiveId(question?.id);
      setActiveQ(question);
      if ("questionText" in question) {
        setActive("question");
      } else {
        setActive("section");
      }

      let newInd = questions.findIndex((q) => q.id === question.id);
      if (newInd !== overInd) {
        setOverInd(newInd);
      }
    }
  }

  function moveTo(ind) {
    if (activeId || movingId || ind == overInd) {
      return;
    }

    // check to see if it is already closing, if so, leave it there?

    //decide which direction to go
    if (overInd >= 0) {
      // we were hovering over one
      if (ind > overInd) {
        // moving down
        setClose(overInd - 1);
      } else if (ind < overInd) {
        // moving up
        setClose(overInd);
      }
    }
    setOverInd(ind);
  }

  function closeAll() {
    if (activeId || movingId || dependencyResolver) {
      return;
    }

    setClose(overInd - 1);
    setTimeout(() => {
      setClose(overInd);
      setOverInd(-1);
    }, 50);
  }

  function setUpDesignEditing(string) {
    setActiveId(string);
    setActive(string);
    if (activeId) {
      setOverInd(-1);
    }
  }

  function exitDesignEditing() {
    setActiveId("");
    setSave(true);
  }

  const navigate = useNavigate();
  function goToDistribute() {
    localStorage.setItem("activeprojecttab", 2);
    localStorage.setItem("activedelivertab", 1);
    if (survey.isProgram) {
      navigate(`/program/${survey.id}/delivery/messages`, { replace: true });
    } else {
      navigate(`/project/${survey.id}/delivery/messages`, {
        replace: true,
      });
    }
  }

  const ref = useRef(null);
  const ref2 = useRef(null);

  function surveyHealth() {
    let health = calculateSurveyHealth(survey, questions);

    return (
      <>
        {" "}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            gap: "1em",
          }}
        >
          <Stat
            title="Survey Health"
            value={
              <div>
                <Pie percentage={health?.health}></Pie>
              </div>
            }
            header
            titleStyle={{ fontSize: ".9em", fontWeight: "300" }}
            valueStyle={{
              fontSize: "1em",
            }}
            style={{ alignItems: "center", paddingTop: "1em" }}
          />

          {activeId && (
            <Stat
              title="Question Health"
              value={
                <div>
                  <Pie
                    percentage={
                      calculateQuestionHealth(
                        questions.find((q) => q.id === activeId)
                      )?.health
                    }
                  ></Pie>
                </div>
              }
              header
              titleStyle={{ fontSize: ".9em", fontWeight: "300" }}
              valueStyle={{
                fontSize: "1em",
              }}
              style={{ alignItems: "center", paddingTop: "1em" }}
            />
          )}
        </div>
        {health?.tips.length != 0 && (
          <div className={styles.tip}>
            <span>
              <a>Tip:</a>
              {health?.tips[0]}
            </span>
          </div>
        )}
      </>
    );
  }

  function getNumber(q) {
    let num = 1;
    for (let question of allQuestions) {
      if ("questionText" in question) {
        if (question?.id === q?.id) {
          return num;
        }
        num += 1;
      }
    }
  }

  function setUpPreview() {
    setSave(true);
    setTimeout(() => setShowPreview(!showPreview), 250);
  }

  function closeDownSettings() {
    setActiveId("");
    setActive("");
    setActiveQ(null);
    setOverInd(-1);
  }

  function setUpActiveSetting(setting) {
    setActive(setting);
    setActiveId("");
    setActiveQ(null);
    setOverInd(-1);
  }

  function onZoneClick(e) {
    if (activeQ || active) {
      for (let comp of allQuestions) {
        let container = document.getElementById("survey component: " + comp.id);
        if (container && container.contains(e.target)) {
          return;
        }
      }

      if (designSettings?.showHeader) {
        let header = document.getElementById("survey_header");
        if (header && header.contains(e.target)) {
          return;
        }
      }

      let finished = document.getElementById("finished_page");
      if (finished && finished.contains(e.target)) {
        return;
      }

      closeDownSettings();
    }
  }

  return (
    <div className={styles.pageContainer}>
      <div ref={ref2}>
        <TriggersModal
          saveQuestion={onEditQ}
          question={activeQ}
          showTriggers={triggers}
          setShowTriggers={setTriggers}
        ></TriggersModal>
      </div>

      {checkRole() && (window.innerWidth < 830 ? seeDesignSettings : true) && (
        <div
          className={styles.settingsContainer}
          style={{
            position: window.innerWidth < 830 ? "absolute" : "",
            right: "0",
            boxShadow:
              window.innerWidth < 830 ? "rgba(0, 0, 0, 0.15) 0px 5px 15px" : "",
          }}
          ref={ref}
        >
          <div className={styles.settingsContent}>
            {window.innerWidth < 830 && (
              <i
                className="bi bi-x-lg"
                style={{
                  position: "absolute",
                  top: "1em",
                  right: "1em",
                  cursor: "pointer",
                }}
                onClick={() => setSeeDesignSettings(false)}
              ></i>
            )}
            <SurveyDesign
              settings={designSettings}
              setSettings={changeSettings}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              numPages={maxPage}
              setGetAll={setSeeAll}
              currPage={currPage}
              setCurrPage={setCurrPage}
              allQuestions={allQuestions}
              onDeletePage={onDeletePage}
              addPage={addPage}
              switchPages={switchPages}
              status={status}
              active={active}
              setActive={setActive}
              setActiveId={setActiveId}
              setActiveQ={setActiveQ}
              question={activeQ}
              dependents={dependencies.find((d) => d.questionId === activeId)}
              setUpLogicResolver={setUpLogicResolver}
              onRetract={onRetract}
              onEditQ={onEditQ}
              onEditQs={onEditQs}
              projId={id}
              setShowTriggers={setTriggers}
            ></SurveyDesign>
          </div>
        </div>
      )}
      <div className={styles.container}>
        <div className={styles.nav}>
          {" "}
          <FlexRow
            style={{
              width: "100%",
              flexWrap: "wrap",
              justifyContent: "space-between",
            }}
          >
            <div
              className={styles.section_label6}
              style={{
                whiteSpace: "nowrap",
                margin: "0",
                marginLeft: ".5rem",
              }}
            >
              {savingStatus ? (
                <FlexRow>
                  Saving...
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "20px",
                      width: "20px",
                      overflow: "hidden",
                    }}
                  >
                    {savingStatus}
                  </div>{" "}
                </FlexRow>
              ) : (
                `Saved ${trimDate(updatedAt, true)}`
              )}
            </div>
            <FlexRow style={{ width: "fit-content" }}>
              {fullscreen && (
                <Button
                  height="35px"
                  onClick={() => setFullscreen(!fullscreen)}
                  style={{ gap: ".5rem" }}
                  shadow
                >
                  <Icon iconName={"arrows-angle-contract"}></Icon> Exit Full
                  Screen
                </Button>
              )}
              {!fullscreen && (
                <Button
                  height="35px"
                  onClick={() => setFullscreen(!fullscreen)}
                  style={{ gap: ".5rem" }}
                  shadow
                >
                  <Icon iconName={"arrows-angle-expand"}></Icon> Full Screen
                </Button>
              )}

              {/* <Button
                  height="35px"
                  onClick={() => setLogic(true)}
                  style={{ gap: ".5rem" }}
                  shadow
                >
                  <Icon iconName={"bezier2"}></Icon> Logic and Flow
                </Button> */}
              <Button
                height="35px"
                onClick={setUpPreview}
                style={{ gap: ".5rem" }}
                shadow
              >
                <Icon iconName={"eye"}></Icon> Preview
              </Button>

              <BlueAddButton onAddQ={appendQ} onAddSection={appendSection} />

              {/* <Button
                height="35px"
                onClick={setUpPreview}
                style={{ gap: ".5rem" }}
                shadow
                blue
              >
                + Add
              </Button> */}
            </FlexRow>
          </FlexRow>
        </div>

        {active === "logic" && (
          <SurveyLogic
            pages={pages}
            designSettings={designSettings}
            allQuestions={allQuestions}
            activeQ={activeQ}
            setActiveQ={setActiveQ}
          />
        )}
        {active !== "logic" && (
          <div
            className={styles.constructionZone}
            id="constructionZone"
            onClick={onZoneClick}
          >
            {showLoading && <Loading />}

            {!showLoading && (
              <div
                className={styles.survey}
                // onClick={(e) => e.stopPropagation()}
                id="survey"
              >
                {!showFinshedPage && (
                  <>
                    {!seeAll &&
                      designSettings.showLogo &&
                      (designSettings.logoOnEveryPage || currPage == 1) && (
                        <EditLogo
                          settings={designSettings}
                          setSettings={changeSettings}
                          projId={id}
                          active={active === "editLogo"}
                          setActive={setActive}
                          close={exitDesignEditing}
                        />
                      )}

                    {!seeAll && !showPreview && designSettings.showProgBar && (
                      <EditableProgressBar
                        settings={designSettings}
                        setSettings={changeSettings}
                        active={active === "progress"}
                        setActive={setActive}
                        close={exitDesignEditing}
                        numQs={numQs}
                        numAnswered={Object.keys(answers).length}
                      />
                    )}

                    <div
                      className={styles.questionsContainer}
                      id="questionsContainer"
                      onMouseLeave={() => closeAll()}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "1rem",
                      }}
                    >
                      <DndContext
                        sensors={sensors}
                        collisionDetection={closestCorners}
                        onDragEnd={handleDragEnd}
                        onDragOver={handleDragOver}
                        onDragStart={handleDragStart}
                        modifiers={[
                          restrictToVerticalAxis,
                          // restrictToParentElement,
                        ]}
                      >
                        {!seeAll && questions.length > 0 && (
                          <SortableContext
                            id={"list"}
                            items={questions}
                            strategy={verticalListSortingStrategy}
                          >
                            {questions.map((question, ind) => (
                              <React.Fragment key={ind}>
                                <SurveyComponent
                                  key={question.id}
                                  component={question}
                                  active={activeId === question.id}
                                  setActive={() => setUpActiveQ(question)}
                                  onClose={() => setSave(true)}
                                  status={status}
                                  moveTo={moveTo}
                                  pageInd={ind}
                                  canEdit={checkRole()}
                                  allQuestions={allQuestions}
                                  onEditQ={onEditQ}
                                  deleteQ={deleteQ}
                                  designSettings={designSettings}
                                  roles={roles}
                                  onCopy={onCopy}
                                  onAnswer={onAnswer}
                                  onRetract={onRetract}
                                  answers={answers}
                                  onSaveSomething={() => setSave(true)}
                                  dependents={dependencies.find(
                                    (d) => d.questionId === question.id
                                  )}
                                  setUpLogicResolver={setUpLogicResolver}
                                  survey={survey}
                                  calculateQuestionHealth={
                                    calculateQuestionHealth
                                  }
                                  questionNumber={
                                    question?.index
                                      ? question?.index
                                      : getNumber(question)
                                  }
                                ></SurveyComponent>
                              </React.Fragment>
                            ))}
                          </SortableContext>
                        )}

                        {seeAll && pages && pages?.length > 0 && (
                          <SortableContext
                            id={"list"}
                            items={questions}
                            strategy={verticalListSortingStrategy}
                          >
                            {(!pages || pages?.length === 0) &&
                              allQuestions?.length > 0 && <Loading></Loading>}
                            {pages &&
                              pages?.length > 0 &&
                              pages?.map((pageQuestions, ind2) => (
                                <React.Fragment key={ind2}>
                                  <FlexRow>
                                    <FlexRow
                                      start
                                      gap={"0px"}
                                      style={{ width: "fit-content" }}
                                    >
                                      {" "}
                                      <div className={styles.section_label6}>
                                        Page {ind2 + 1}
                                      </div>
                                      {checkRole() && (
                                        <div className={styles.section_label6}>
                                          <Icon
                                            iconName={"trash"}
                                            red
                                            onClick={() =>
                                              onDeletePage(ind2 + 1)
                                            }
                                          ></Icon>
                                        </div>
                                      )}
                                    </FlexRow>
                                    {/* <Button shadow onClick={addPage}>
                                  + New Page
                                </Button> */}
                                  </FlexRow>
                                  <div
                                    className={`${styles.apage}`}
                                    style={{
                                      gap: designSettings?.questionGap
                                        ? `${designSettings?.questionGap * 2}px`
                                        : "0px",

                                      backgroundColor:
                                        designSettings.backgroundColor
                                          ? designSettings.backgroundColor
                                          : "transparent",
                                      backgroundImage:
                                        designSettings?.backgroundImage
                                          ? designSettings?.backgroundImage
                                          : "",
                                    }}
                                    key={ind2}
                                  >
                                    {designSettings?.showHeader != false && (
                                      <div
                                        className={`${styles.pageHeader} ${
                                          (active === "header" ||
                                            headerActive) &&
                                          styles.aactive
                                        }`}
                                        style={{
                                          backgroundColor:
                                            designSettings?.headerBackgroundColor
                                              ? designSettings?.headerBackgroundColor
                                              : "transparent",
                                          boxShadow:
                                            designSettings?.headerShadow
                                              ? designSettings?.headerShadow
                                              : "",
                                          borderRadius:
                                            designSettings?.headerRadius ||
                                            designSettings?.headerRadius === 0
                                              ? designSettings?.headerRadius +
                                                "rem"
                                              : ".5rem",
                                          gap:
                                            designSettings?.headerGap ||
                                            designSettings?.headerGap === 0
                                              ? designSettings?.headerGap +
                                                "rem"
                                              : "",
                                        }}
                                        onClick={() =>
                                          setUpActiveSetting("header")
                                        }
                                        id="survey_header"
                                      >
                                        {designSettings.showLogo &&
                                          (designSettings.logoOnEveryPage ||
                                            ind2 + 1 == 1) && (
                                            <EditLogo
                                              settings={designSettings}
                                              setSettings={changeSettings}
                                              projId={id}
                                              active={active === "editLogo"}
                                              setActive={setActive}
                                              close={exitDesignEditing}
                                            />
                                          )}

                                        {designSettings.showProgBar && (
                                          <EditableProgressBar
                                            settings={designSettings}
                                            setSettings={changeSettings}
                                            active={active === "progress"}
                                            setActive={setActive}
                                            close={exitDesignEditing}
                                            numQs={numQs}
                                            numAnswered={
                                              Object.keys(answers).length
                                            }
                                          />
                                        )}
                                      </div>
                                    )}
                                    {pageQuestions &&
                                      pageQuestions?.length === 0 && (
                                        <FlexRow>
                                          <div
                                            className={`${styles.addBtnBig} `}
                                            onClick={() =>
                                              handleCreateSection(0, ind2 + 1)
                                            }
                                          >
                                            + Text
                                            <span>
                                              Good for cover pages or separating
                                              sections of your survey. Can also
                                              be used as a header.
                                            </span>
                                          </div>
                                          <div
                                            className={`${styles.addBtnBig} `}
                                            onClick={() =>
                                              handleCreateQuestion(0, ind2 + 1)
                                            }
                                          >
                                            + Question
                                            <span>
                                              Your survey question. Can be
                                              multiple choice, scale, free
                                              response, or ranking type
                                              questions.
                                            </span>
                                          </div>
                                        </FlexRow>
                                      )}
                                    {pageQuestions &&
                                      pageQuestions?.length > 0 &&
                                      pageQuestions?.map((question, ind) => (
                                        <React.Fragment key={ind}>
                                          <SurveyComponent
                                            key={question.id}
                                            component={question}
                                            active={activeId === question.id}
                                            setActive={() =>
                                              setUpActiveQ(question)
                                            }
                                            onClose={() => setSave(true)}
                                            status={status}
                                            moveTo={moveTo}
                                            pageInd={ind}
                                            canEdit={checkRole()}
                                            allQuestions={allQuestions}
                                            onEditQ={onEditQ}
                                            deleteQ={deleteQ}
                                            designSettings={designSettings}
                                            roles={roles}
                                            onCopy={onCopy}
                                            onAnswer={onAnswer}
                                            onRetract={onRetract}
                                            answers={answers}
                                            onSaveSomething={() =>
                                              setSave(true)
                                            }
                                            dependents={dependencies.find(
                                              (d) =>
                                                d.questionId === question.id
                                            )}
                                            setUpLogicResolver={
                                              setUpLogicResolver
                                            }
                                            survey={survey}
                                            calculateQuestionHealth={
                                              calculateQuestionHealth
                                            }
                                            questionNumber={
                                              question?.index
                                                ? question?.index
                                                : getNumber(question)
                                            }
                                            show={activeId === question?.id}
                                            close={close == ind}
                                            onAddQ={(spot) =>
                                              handleCreateQuestion(
                                                spot > 0 ? ind + 1 : ind,
                                                ind2 + 1
                                              )
                                            }
                                            onAddSection={() =>
                                              handleCreateSection(
                                                ind + 1,
                                                ind2 + 1
                                              )
                                            }
                                            onPaste={() => onPaste(ind + 1)}
                                            beginningOrEnd={overInd == ind}
                                          ></SurveyComponent>
                                        </React.Fragment>
                                      ))}
                                    <EditableNavigation
                                      settings={designSettings}
                                      setSettings={changeSettings}
                                      previousPage={previousPage}
                                      // nextPage={nextPage}
                                      // onFinish={() => {
                                      //   setShowFinishedPage(true);
                                      //   setActiveId("");
                                      // }}
                                      page={ind2 + 1}
                                      maxPage={maxPage}
                                      active={active === "nav"}
                                      setActive={setUpDesignEditing}
                                      close={exitDesignEditing}
                                    ></EditableNavigation>
                                  </div>
                                </React.Fragment>
                              ))}
                            <FlexRow>
                              <FlexRow
                                start
                                gap={"0px"}
                                style={{ width: "fit-content" }}
                              >
                                <Icon
                                  iconName={"check-circle"}
                                  onClick={() => onDeletePage(ind2 + 1)}
                                ></Icon>

                                <div
                                  className={styles.section_label6}
                                  style={{
                                    cursor: "pointer",
                                    display: "flex",
                                    alignItems: "center",
                                    gap: ".5rem",
                                    width: "fit-content",
                                  }}
                                  onMouseEnter={() => setFinishedActive(true)}
                                  onMouseLeave={() => setFinishedActive(false)}
                                  onClick={() => setActive("finished")}
                                >
                                  Finished Page{" "}
                                  {finishedActive && active != 3 && (
                                    <span>(Click to edit)</span>
                                  )}
                                </div>
                              </FlexRow>
                              <Button shadow onClick={addPage}>
                                + New Page
                              </Button>
                            </FlexRow>
                            <div
                              className={`${styles.apage}  ${
                                styles.finishedPage
                              } ${
                                (active === "finished" || finishedActive) &&
                                styles.aactive
                              }`}
                              style={{
                                gap: designSettings?.questionGap
                                  ? `${designSettings?.questionGap}px`
                                  : "20px",
                                minHeight: "700px",
                                backgroundColor:
                                  designSettings?.finishedBackgroundColor
                                    ? designSettings?.finishedBackgroundColor
                                    : designSettings.backgroundColor,
                                backgroundImage: designSettings?.backgroundImage
                                  ? designSettings?.backgroundImage
                                  : "",
                              }}
                              id="finished_page"
                              onClick={() => setUpActiveSetting("finished")}
                            >
                              <SurveyCompletedInConstruction
                                message={finishedMsg}
                                // returnToSurvey={() => setShowFinishedPage(false)}
                                settings={designSettings}
                                onEdit={editFinishMessage}
                                noConfetti
                                canEdit={checkRole()}
                              ></SurveyCompletedInConstruction>
                            </div>
                            <div
                              className={styles.section_label6}
                              style={{ marginBottom: "1rem" }}
                            >
                              Page Footer
                            </div>
                            <div
                              className={`${styles.apage}`}
                              style={{
                                gap: designSettings?.questionGap
                                  ? `${designSettings?.questionGap}px`
                                  : "20px",
                                backgroundColor: designSettings.backgroundColor
                                  ? designSettings.backgroundColor
                                  : "",
                                backgroundImage: designSettings?.backgroundImage
                                  ? designSettings?.backgroundImage
                                  : "",
                              }}
                            >
                              <div
                                className={styles.footer}
                                style={{
                                  fontSize:
                                    window.innerWidth < 700 ? ".7em" : "",
                                  minHeight: "70px",
                                }}
                              >
                                <div
                                  className={styles.poweredBy}
                                  style={{ gap: ".3em" }}
                                >
                                  Powered By{" "}
                                  <a
                                    href={"https://reactiondata.com"}
                                    target="_blank"
                                  >
                                    Reaction
                                  </a>{" "}
                                  <img
                                    src={require("assets/images/circlelogo.png")}
                                    height={10}
                                  />
                                </div>
                              </div>
                            </div>
                          </SortableContext>
                        )}

                        <DragOverlay
                          dropAnimation={{
                            duration: 500,
                            easing: "cubic-bezier(0.18, 0.67, 0.6, 1.22)",
                          }}
                        >
                          {movingId && <>{getActive()}</>}
                        </DragOverlay>
                      </DndContext>
                    </div>

                    {!seeAll && (
                      <EditableNavigation
                        settings={designSettings}
                        setSettings={changeSettings}
                        previousPage={previousPage}
                        nextPage={nextPage}
                        onFinish={() => {
                          setShowFinishedPage(true);
                          setActiveId("");
                        }}
                        page={currPage}
                        maxPage={maxPage}
                        active={active === "nav"}
                        setActive={setUpDesignEditing}
                        close={exitDesignEditing}
                      ></EditableNavigation>
                    )}
                    {!seeDesignSettings && window.innerWidth < 830 && (
                      <div className={styles.quickButtonContainer}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            gap: "1em",
                            justifyContent: "center",
                            flexWrap: "wrap",
                          }}
                        >
                          <Button
                            blue
                            height="35px"
                            onClick={showLoading ? undefined : onSaveClick}
                            shadow
                          >
                            <div
                              style={{
                                display: "flex",
                                gap: "5px",
                                alignItems: "center",
                              }}
                            >
                              Save {savingStatus}
                            </div>
                          </Button>
                          <Button
                            blue
                            height="35px"
                            onClick={setUpPreview}
                            shadow
                          >
                            Preview
                          </Button>
                        </div>
                        <div
                          className={styles.editSurveyPencil}
                          onClick={() => setSeeDesignSettings(true)}
                        >
                          <i className="bi bi-pencil"></i>
                        </div>
                      </div>
                    )}
                  </>
                )}
                {showFinshedPage && (
                  <SurveyCompletedInConstruction
                    message={finishedMsg}
                    returnToSurvey={() => setShowFinishedPage(false)}
                    settings={designSettings}
                    onEdit={editFinishMessage}
                    canEdit={checkRole()}
                  ></SurveyCompletedInConstruction>
                )}
              </div>
            )}

            {dependencyResolver && (
              <div id="dependencyResolver">{dependencyResolver}</div>
            )}
          </div>
        )}
      </div>

      {showPreview && (
        <div
          className={styles.expandedPreview}
          style={{
            backgroundColor: designSettings.backgroundColor,
            // position: !roles.editSurveyDesign && !roles.editSurveyQuestions ? "absolute" : ""
          }}
        >
          <div className={styles.close}>
            {previewMode === "Desktop" && (
              <Button
                onClick={() => {
                  setPreviewMode("Mobile");
                }}
                style={{ display: "flex", gap: ".5rem", alignItems: "center" }}
                shadow
              >
                <Icon iconName={"phone"}></Icon> Mobile
              </Button>
            )}
            {previewMode === "Mobile" && (
              <Button
                onClick={() => {
                  setPreviewMode("Desktop");
                }}
                style={{ display: "flex", gap: ".5rem", alignItems: "center" }}
                shadow
              >
                <Icon iconName={"display"}></Icon>
                Desktop
              </Button>
            )}
            <Button
              onClick={() => {
                // window.location.reload(false);
                setShowPreview(false);
              }}
              style={{ display: "flex", gap: ".5rem", alignItems: "center" }}
              shadow
            >
              <div style={{ transform: "rotateX(180deg)" }}>
                <i className="bi bi-arrow-return-left"></i>
              </div>{" "}
              Back to Build
            </Button>
          </div>

          {previewMode === "Desktop" && (
            <Survey projectId={projectId} preview></Survey>
          )}
          {previewMode === "Mobile" && (
            <>
              {window.innerWidth > 700 && (
                <>
                  <FlexRow
                    style={{
                      justifyContent: "center",
                      height: "fit-content",
                      alignItems: "center",
                      marginTop: "2rem",
                    }}
                  >
                    <div className={styles.mobileImage}>
                      <img
                        src={require("assets/images/IphoneSolo.png")}
                        height={900}
                        width={480}
                      ></img>
                      <div className={styles.mobile}>
                        {" "}
                        <div className={styles.mobileC} id="constructionZone">
                          <Survey projectId={projectId} preview></Survey>
                        </div>
                      </div>
                    </div>
                  </FlexRow>
                </>
              )}
              {window.innerWidth <= 700 && (
                <Survey projectId={projectId} preview></Survey>
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
}

export function Add({
  show,
  close,
  onAddQ,
  onAddSection,
  copy,
  onPaste,
  beginningOrEnd,
  emptyPage,
}) {
  const [showItems, setShowItems] = useState(false);

  function clickOutside(e) {
    if (ref.current && !ref.current?.contains(e.target)) {
      setShowItems(false);
    }
  }

  useEffect(() => {
    document.addEventListener("click", clickOutside, true);
    return () => {
      document.removeEventListener("click", clickOutside, true);
    };
  }, []);

  const ref = useRef();
  return (
    <>
      {show && (
        <div
          className={` ${styles.addContainer}`}
          style={
            emptyPage
              ? {
                  position: "static",
                  gap: "2em",
                }
              : {
                  // top: beginningOrEnd ? "" : "-.5rem",
                }
          }
          ref={ref}
          onClick={() => setShowItems(!showItems)}
          // onMouseLeave={() => setShowItems(false)}
          // onMouseEnter={() => setShowItems(true)}
        >
          {/* <div
            className={`${emptyPage ? styles.addBtnBig : styles.addBtn} ${
              !emptyPage && styles.addQ
            }`}
            onClick={onAddSection}
          >
            + Section
            {emptyPage && (
              <span>
                Can only add text into a section. Good for cover pages or
                separating sections of your survey
              </span>
            )}
          </div> */}
          <div
            // shadow
            onClick={() => setShowItems(!showItems)}
            // width={30}
            // height={30}
            // style={{backgroundColor: "transparent",}}
            className={styles.add}
          >
            <i className="bi-plus" style={{ fontSize: "16pt" }}></i>
            {emptyPage && (
              <span>
                Your survey question. Can be multiple choice, scale, free
                response, or ranking type questions.
              </span>
            )}
          </div>
          {showItems && (
            <div
              className={styles.settingsBox}
              style={{ left: "-.2rem", top: "-1.2rem", width: "fit-content" }}
            >
              <div className={`${styles.menuItem} `} onClick={onAddQ}>
                <i className="bi bi-question-circle"></i>
                Add Question
              </div>
              <div className={`${styles.menuItem} `} onClick={onAddSection}>
                <i className="bi bi-textarea-t"></i>
                Add Text
              </div>
            </div>
          )}
          {copy && (
            <div
              className={`${styles.addBtn} ${styles.paste}`}
              onClick={onPaste}
            >
              Paste
            </div>
          )}
        </div>
      )}
      {/* {!show && !closing && !beginningOrEnd && (
        <div style={{ height: "10px" }}></div>
      )} */}
    </>
  );
}

function BlueAddButton({ onAddQ, onAddSection }) {
  const [showAdd, setShowAdd] = useState(false);

  const ref = useRef();

  function clickListener(e) {
    if (!ref.current || !ref.current.contains(e.target)) {
      setShowAdd(false);
      document.removeEventListener("click", clickListener, true);
    }
  }

  function setUp() {
    setShowAdd(true);
    document.addEventListener("click", clickListener, true);
  }

  return (
    <>
      <div className={styles.blueAddButton} ref={ref}>
        <Button
          height="35px"
          onClick={setUp}
          style={{ gap: ".5rem" }}
          blue
          shadow
        >
          <Icon white iconName={"plus"}></Icon> Add
        </Button>
        {showAdd && (
          <div
            className={styles.settingsBox}
            style={{ right: "0", top: "120%", width: "fit-content" }}
          >
            <div className={`${styles.menuItem} `} onClick={onAddQ}>
              <i className="bi bi-question-circle"></i>
              Question
            </div>
            <div className={`${styles.menuItem} `} onClick={onAddSection}>
              <i className="bi bi-textarea-t"></i>
              Text
            </div>
          </div>
        )}
      </div>
    </>
  );
}

// function Add({ onAddQ, onAddSection, blankPage, pageInd, copy, onPaste }) {
//   const [show, setShow] = useState(blankPage ? true : false);
//   const [closing, setClosing] = useState();

//   const [timer, setTimer] = useState();

//   function startTimer() {
//     let temp = setTimeout(() => {
//       setShow(true);
//       setTimer(null);
//     }, 100);
//     setTimer(temp);
//   }

//   function exit() {
//     if (timer) {
//       clearTimeout(timer);
//       setTimer(null);
//     }
//     if (show) {
//       setClosing(true);
//       setTimeout(() => setClosing(false), 1100);
//     }
//     setShow(false);
//   }

//   function addSection() {
//     setShow(false);
//     onAddSection(pageInd);
//   }

//   function addQuestion() {
//     setShow(false);
//     onAddQ(pageInd);
//   }

//   function paste() {
//     setShow(false);
//     onPaste(pageInd);
//   }

//   return (
//     <div
//       className={`${show ? styles.showAdd : ""} ${styles.addContainer} ${
//         closing ? styles.closing : ""
//       }`}
//       onMouseEnter={blankPage ? null : startTimer}
//       onMouseLeave={blankPage ? null : exit}
//     >
//       <div
//         className={`${styles.addBtn} ${styles.addSection}`}
//         onClick={show ? addSection : undefined}
//       >
//         + Section
//       </div>
//       <div
//         className={`${styles.addBtn} ${styles.addQ}`}
//         onClick={show ? addQuestion : undefined}
//       >
//         + Question
//       </div>
//       {copy && (
//         <div
//           className={`${styles.addBtn} ${styles.paste}`}
//           onClick={show ? paste : undefined}
//         >
//           Paste
//         </div>
//       )}
//     </div>
//   );
// }
