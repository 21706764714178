import React from "react";
import styles from "./DataInput.module.scss";
import { TextFieldSimple } from "components/inputs";

function DataInput({ dataType, value, onChange, icon, label }) {

  
  if (dataType === "boolean") {
    return <div className={styles.input}>DataInput</div>;
  } else {
    return (
      <div className={styles.input}>
        <TextFieldSimple label={label} value={value} onChange={onChange} icon={icon}></TextFieldSimple>
      </div>
    );
  }
}

export default DataInput;
