import { useEffect } from "react";
import styles from "../SettingsAccordion.module.scss";
import {
  AnswerCount,
  AtoZ,
  AvgScore,
  DoughnutType,
  Flywheel,
  HorizBarType,
  LineType,
  NpsScore,
  ParticipationCount,
  PieType,
  VertBarType,
  ZtoA,
} from "../../QChart/QuestionChart";
import FlexRow from "components/layouts/FlexRow/FlexRow";
import { fonts } from "assets/functions/Variables";
import FlexCol from "components/layouts/FlexColumn/FlexCol";
import {
  ChartLabel,
  Color,
  Bold,
  Check,
  SelectTool,
  Switch,
  NumSliderTool,
} from "../Tools";

//custom accordian
export const DataLabels = ({
  viz,
  title,
  changeSettingsField,
  changeVizField,
  visible,
  setVisible,
}) => {
  const defaultLabel = {
    show: true,
    color: "#616565",
    position: "end",
    alignment: "end",
    offset: 0,
    sigFigs: 0,
    fontSize: 14,
  };

  function onLabelOrPercentClick(setting, val) {
    if (val) {
      if (!viz.designSettings[setting].color) {
        viz.designSettings[setting] = { ...defaultLabel }; // This creates the label. I thought that most chart may not use more than one label, so might as well save the extra data (strings) from the database until you use it
      }
    }
    onChange(setting, "show", val);
  }

  function onChange(setting, field, val) {
    // console.log(setting, field, val);
    let newSetting = viz.designSettings[setting];
    newSetting[field] = val;
    changeSettingsField(setting, newSetting);
  }

  useEffect(() => {
    // Just here to catch old charts. As of 5/4/23
    if (viz.designSettings.dataLabelValue === undefined) {
      let settings = { ...viz.designSettings };
      settings.dataLabelValue = {
        show: false,
        color: "#fff",
        position: "center",
        alignment: "center",
        offset: 0,
        sigFigs: 0,
        fontSize: 14,
      };
      settings.dataLabelLabel = {
        show: false,
      };
      settings.dataLabelPercent = {
        show: false,
      };

      delete settings.dataLabelAlignment;
      delete settings.dataLabelColor;
      delete settings.dataLabelFontSize;
      delete settings.dataLabelSigFig;
      delete settings.dataLabelPosition;
      delete settings.dataLabelStack;

      changeVizField("designSettings", settings);
    }
  }, []);

  function toggleLabels(val) {
    // if (!val) {
    //   viz.designSettings.zoomOut = 0;
    // }
    changeSettingsField("hasDataLabels", val);
  }

  // const byPercent =
  //   !viz.designSettings.byPercent &&
  //   (viz.designSettings.answerType === ParticipationCount ||
  //     viz.designSettings.answerType === AnswerCount ||
  //     viz.pivotString === "nps");

  const nps =
    viz.designSettings.answerType === NpsScore &&
    viz.designSettings.split !== "nps";

  const byPercent =
    viz.designSettings.byPercent &&
    viz.designSettings.answerType !== AvgScore &&
    !nps &&
    viz.designSettings.answerType !== Flywheel;

  return (
    <>
      <div
        key={"datalabels"}
        className={`${styles.header} ${visible ? styles.headervisible : ""}`}
        onClick={!visible ? setVisible : undefined}
      >
        {/* {title} */}{" "}
        <FlexRow
          onClick={visible ? setVisible : undefined}
          style={{ cursor: "pointer" }}
          className={styles.accordionTitle}
        >
          <div style={{ display: "flex", alignItems: "center", gap: "1em" }}>
            {title}{" "}
            <i style={{ fontSize: "1.2em" }} className="bi bi-superscript"></i>
          </div>

          <span className={styles.accordionicon}>
            <i className="bi bi-caret-left-fill"></i>
          </span>
        </FlexRow>
        {visible && (
          <div
            className={styles.body}
            style={{ gap: "10px", paddingBottom: "10px" }}
          >
            <Switch
              checked={viz.designSettings.hasDataLabels}
              onCheck={toggleLabels}
            >
              Data Labels
            </Switch>

            {viz.designSettings.hasDataLabels && (
              <>
                <FlexRow justify={"flex-start"} gap={"10px"}>
                  <SelectTool
                    options={fonts}
                    value={
                      viz?.designSettings?.DataLabelFont
                        ? fonts.find(
                            (v) =>
                              v.value === viz?.designSettings?.DataLabelFont
                          )
                        : fonts.find((v) => v.value === "Poppins, sans-serif")
                    }
                    onChange={(v) =>
                      changeSettingsField("DataLabelFont", v.value)
                    }
                  >
                    Font
                  </SelectTool>

                  <FlexCol
                    style={{ width: "fit-content", height: "100%" }}
                    gap={"5px"}
                  >
                    <ChartLabel style={{ visibility: "hidden" }}>B</ChartLabel>

                    <FlexCol justify={"center"} style={{ flexGrow: "1" }}>
                      <Bold
                        bold={
                          viz?.designSettings?.DataLabelFontWeight === "bold"
                        }
                        onChange={(val) =>
                          changeSettingsField("DataLabelFontWeight", val)
                        }
                      />
                    </FlexCol>
                  </FlexCol>
                </FlexRow>

                <div className={styles.title}>Different Labels</div>

                <Check
                  checked={viz.designSettings.dataLabelValue.show}
                  onCheck={(val) => onChange("dataLabelValue", "show", val)}
                >
                  {byPercent ? "Value (%)" : "Value"}
                </Check>

                {viz.designSettings.dataLabelValue.show && (
                  <SingleDataLabelSetting
                    viz={viz}
                    setting={viz.designSettings.dataLabelValue}
                    onChange={(field, val) =>
                      onChange("dataLabelValue", field, val)
                    }
                    sigFigs={viz.designSettings.byPercent}
                    npsValueLabel={
                      viz.designSettings.answerType === NpsScore &&
                      viz.type === VertBarType
                    }
                  />
                )}

                <Check
                  checked={viz.designSettings.dataLabelLabel.show}
                  onCheck={(val) =>
                    onLabelOrPercentClick("dataLabelLabel", val)
                  }
                >
                  Legend Label
                </Check>

                {viz.designSettings.dataLabelLabel.show && (
                  <SingleDataLabelSetting
                    viz={viz}
                    setting={viz.designSettings.dataLabelLabel}
                    onChange={(field, val) =>
                      onChange("dataLabelLabel", field, val)
                    }
                  />
                )}

                {!viz.designSettings.byPercent &&
                  (viz.designSettings.answerType === ParticipationCount ||
                    viz.designSettings.answerType === AnswerCount ||
                    viz.pivotString === "nps") &&
                  viz.designSettings.answerType !== Flywheel && (
                    <>
                      <Check
                        checked={viz.designSettings.dataLabelPercent.show}
                        onCheck={(val) =>
                          onLabelOrPercentClick("dataLabelPercent", val)
                        }
                      >
                        {viz.designSettings.byPercent
                          ? "Actual Value"
                          : "% of Total"}
                      </Check>

                      {viz.designSettings.dataLabelPercent.show && (
                        <SingleDataLabelSetting
                          viz={viz}
                          setting={viz.designSettings.dataLabelPercent}
                          onChange={(field, val) =>
                            onChange("dataLabelPercent", field, val)
                          }
                          sigFigs
                        />
                      )}
                    </>
                  )}
              </>
            )}
          </div>
        )}
      </div>
    </>
  );
};

function SingleDataLabelSetting({
  viz,
  setting,
  sigFigs,
  onChange,
  npsValueLabel,
}) {
  const positionOptions =
    viz.type === DoughnutType || viz.type === PieType
      ? [
          { value: "start", label: "Inner Edge" },
          { value: "center", label: "Middle" },
          { value: "end", label: "Outer Edge" },
        ]
      : [
          { value: "start", label: "Start" },
          { value: "center", label: "Center" },
          { value: "end", label: "End" },
        ];

  const alignmentOptions =
    viz.type === DoughnutType || viz.type === PieType
      ? [
          { value: "end", label: "Out" },
          { value: "start", label: "In" },
          { value: "center", label: "None" },
        ]
      : viz.type === HorizBarType
      ? [
          { value: "left", label: "Left" },
          { value: "right", label: "Right" },
          { value: "center", label: "None" },
        ]
      : [
          { value: "end", label: "Up" },
          { value: "start", label: "Down" },
          { value: "left", label: "Left" },
          { value: "right", label: "Right" },
          { value: "center", label: "None" },
        ];

  function changePosition(val) {
    setting.alignment = "center";
    onChange("position", val);
  }

  useEffect(() => {
    if (viz.type === HorizBarType) {
      if (setting.alignment === "end") {
        onChange("alignment", "right");
      }
      if (setting.alignment === "start") {
        onChange("alignment", "left");
      }
    }
  }, []);

  return (
    <FlexCol gap={"5px"} style={{ paddingLeft: "10px" }}>
      <Color color={setting.color} onChange={(val) => onChange("color", val)}>
        Color
      </Color>

      <NumSliderTool
        val={setting.fontSize}
        min={0}
        max={40}
        step={1}
        onChange={(val) => onChange("fontSize", val)}
        style={{ paddingTop: "5px" }}
      >
        Size
      </NumSliderTool>

      {sigFigs && (
        <NumSliderTool
          val={setting.sigFigs}
          min={0}
          max={3}
          step={1}
          onChange={(val) => onChange("sigFigs", val)}
        >
          Decimal Digits
        </NumSliderTool>
      )}

      {(setting.alignment !== "center" || setting.position === "outside") && (
        <NumSliderTool
          val={setting.offset}
          min={-100}
          max={100}
          step={1}
          onChange={(val) => onChange("offset", val)}
        >
          Push
        </NumSliderTool>
      )}

      <FlexRow>
        <SelectTool
          onChange={(option) => changePosition(option.value)}
          value={positionOptions.find((opt) => opt.value === setting.position)}
          options={positionOptions}
          disable={npsValueLabel}
        >
          Place
        </SelectTool>

        <SelectTool
          onChange={(option) => onChange("alignment", option.value)}
          value={alignmentOptions.find(
            (opt) => opt.value === setting.alignment
          )}
          options={alignmentOptions}
          disable={npsValueLabel}
        >
          Move
        </SelectTool>
      </FlexRow>
    </FlexCol>
  );
}
