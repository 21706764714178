import React, { useState } from "react";
import styles from "./Groups.module.scss";
import { NavTextField } from "components/inputs/input_fields/NavTextField/NavTextField";
import { TabBar } from "../TabBar/TabBar";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import Button from "components/Button/Button";
import DataInput from "./DataInput";
import {
  useFetchCurrOrgsFields,
  useFetchUnassignedOrgsFields,
  useSearchOrgGroups,
} from "api/resources/organization/organization";
import { Loading } from "components/Loading/Loading";
import Modal from "components/ReactModal/ReactModal";
import { Layout } from "./ObjectLayout";

function Groups({ fullLayout, setFullLayout, newGroup }) {
  const [active, updateActive] = useState(newGroup ? 1 : 0);
  const [addGroup, setAddGroup] = useState(false);

  let menuItems = [
    !newGroup
      ? {
          id: 0,
          name: "Group Inputs",
          // icon:
        }
      : undefined,
    {
      id: 1,
      name: "Single Columns",
      // icon:
    },
  ];

  function saveGroup(layout) {
    console.log(layout)
  }
  return (
    <div className={styles.page}>
      <Modal
        onClose={() => setAddGroup(false)}
        show={addGroup}
        modalStyle={{
          height: "90%",
          maxHeight: "100%",
          maxWidth: "100%",
          margin: "0",
        }}
      >
        {" "}
        <Layout
          object={[
            {
              id: "new:group",
              position: 0,
              icon: "house",
              enabled: true,
              name: "New Group",
              customFieldGroupRow: [
                { id: "new:row", position: 0, customFieldGroupRowField: [] },
              ],
            },
          ]}
          newGroup
          saveChanges={saveGroup}
        ></Layout>
      </Modal>
      <div className={styles.tabmenu}>
        <TabBar
          tabBarItems={menuItems}
          active={active}
          updateActive={updateActive}
        ></TabBar>
      </div>
      {active === 0 && !newGroup && (
        <>
          <div className={styles.header}>
            <div className={styles.header_4}>Add Group Inputs</div>
            <div className={styles.text_3}>
              Click and drag to add a group input anywhere on the layout, then
              delete or edit fields to customize
            </div>
            <div style={{ padding: "0 1rem" }}>
              <NavTextField
                shadow
                placeholder="Search"
                className={styles.search}
              ></NavTextField>
            </div>
          </div>
          <div className={styles.groups}>
            {fullLayout?.map(
              ({ id, name, customFieldGroupRow, enabled, icon }) =>
                enabled ? (
                  <Group
                    name={name}
                    layout={customFieldGroupRow}
                    icon={icon}
                    visible={enabled}
                    key={id}
                  />
                ) : (
                  <GroupMovable
                    name={name}
                    layout={customFieldGroupRow}
                    icon={icon}
                    visible={enabled}
                    key={id}
                  />
                )
            )}
            <div className={styles.addGroup}>
              <Button blue shadow onClick={() => setAddGroup(true)}>
                + New Group
              </Button>
            </div>
          </div>
        </>
      )}
      {active === 1 && <SingleInputs></SingleInputs>}
    </div>
  );
}

export default Groups;

function SingleInputs(inputs, setInputs) {
  const [searchString, setSearchString] = useState("");
  const fetchColumns = useFetchUnassignedOrgsFields(searchString);

  return (
    <>
      <div className={styles.header}>
        <div className={styles.header_4}>Add Single Inputs</div>
        <div className={styles.text_3}>
          Click and drag to add a new input anywhere on the layout, then delete
          or edit fields to customize
        </div>
        <div style={{ padding: "0 1rem" }}>
          <NavTextField
            shadow
            placeholder="Search"
            className={styles.search}
          ></NavTextField>
        </div>
      </div>
      <div
        className={styles.groups}
        style={{
          gap: "1rem",
          display: "flex",
          flexDirection: "column",
          padding: "1rem 1rem",
        }}
      >
        {fetchColumns.isLoading && <Loading height={50} width={50}></Loading>}
        {fetchColumns.isSuccess &&
          fetchColumns.data?.fields?.map((field, i) => (
            <SortableItem key={i} id={field?.id} field={field}>
              {" "}
              <DataInput label={field?.displayName}></DataInput>
            </SortableItem>
          ))}
        <div className={styles.addGroup}>
          <Button blue shadow>
            + New Column
          </Button>
        </div>
      </div>
    </>
  );
}

function Group({ name, layout, icon, visible }) {
  return (
    <>
      <div className={styles.group}>
        <div className={`${styles.groupHeader} ${visible && styles.override}`}>
          <i className={`bi-${icon}`}></i> {name}
        </div>
        <div className={`${styles.fields} ${visible && styles.override}`}>
          {layout?.map((row, i1) => (
            <React.Fragment key={row?.id}>
              {row?.customFieldGroupRowField?.map((field, i2) => (
                <React.Fragment key={i2}>
                  {field?.customField?.displayName}
                  {i2 === row?.customFieldGroupRowField?.length - 1 &&
                  i1 === layout?.length - 1
                    ? ""
                    : ", "}
                </React.Fragment>
              ))}
            </React.Fragment>
          ))}
        </div>
      </div>
    </>
  );
}

function GroupMovable({ name, layout, icon, visible }) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: name });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition: transition,
    opacity: isDragging ? 0.4 : undefined,
  };

  return (
    <>
      <div
        className={styles.group}
        style={style}
        {...attributes}
        {...listeners}
        ref={setNodeRef}
      >
        <div className={`${styles.groupHeader} ${visible && styles.override}`}>
          <i className={`bi-${icon}`}></i> {name}
        </div>
        <div className={`${styles.fields} ${visible && styles.override}`}>
          {layout?.map(({ id, position, customFieldGroupRowField }, i1) => (
            <React.Fragment key={id}>
              {customFieldGroupRowField?.map(
                ({ id, position, customField }, i2) => (
                  <React.Fragment key={i2}>
                    {customField?.displayName}
                    {i2 === customFieldGroupRowField?.length - 1 &&
                    i1 === layout?.length - 1
                      ? ""
                      : ", "}
                  </React.Fragment>
                )
              )}
            </React.Fragment>
          ))}
        </div>
      </div>
    </>
  );
}

function SortableItem({
  id,
  children,
  index,
  height,
  layout,
  layoutIndex,
  field,
}) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id,
    data: {
      index,
      layout,
      input: true,
      layoutIndex,
      dropInput: true,
      field: field,
    },
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition: transition,
    opacity: isDragging ? 0.4 : undefined,
    cursor: isDragging ? "grabbing" : "",
  };

  return (
    <div
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
      className={styles.sortableItem}
    >
      <span style={{ pointerEvents: "none" }}>{children}</span>
    </div>
  );
}
