import React, { useState } from "react";
import styles from "./ContactLayout.module.scss";
import DataInput from "components/layouts/ObjectLayout/DataInput";
import {
  useSearchEnabledOrgGroups,
  useSearchOrgGroups,
} from "api/resources/organization/organization";
import { Loading } from "components/Loading/Loading";
import {
  useFetchContact,
  useUpdateContactAttGql,
} from "api/resources/contacts/contacts";
import { TabPill } from "components/layouts";

export function GetContact({ contactId }) {
  const fetchContact = useFetchContact(contactId);

  return (
    <>
      {fetchContact.isLoading && <Loading></Loading>}
      {fetchContact.isSuccess && (
        <ContactLayout contact={fetchContact.data?.contact}></ContactLayout>
      )}
    </>
  );
}

export function ContactLayout({ contact }) {
  const [searchString, setSearchString] = useState("");
  const [contactState, setContact] = useState(contact);
  const [contactData, setContactData] = useState({});
  const searchGroups = useSearchEnabledOrgGroups(
    searchString,
    contact?.organizationId
  );

  // Update top-level fields
  const handleFieldChange = (field, value) => {
    setContact((prevContact) => ({
      ...prevContact,
      [field]: value,
    }));
    setContactData((prevContact) => ({
      ...prevContact,
      [field]: value,
    }));
  };

  // Update attributes by id
  const handleAttributeChange = (id, field, value) => {
    setContact((prevContact) => ({
      ...prevContact,
      attributes: prevContact.attributes.map((attribute) =>
        attribute.id === id ? { ...attribute, [field]: value } : attribute
      ),
    }));
  };

  const updateContact = useUpdateContactAttGql();
  function saveUserFields() {
    // setSaveWords(
    //   <>
    //     Saving <Loading width={20} height={20}></Loading>
    //   </>
    // );

    let attributeData = [];

    for (let att of contactState?.attribute) {
      if (att?.changed) {
        attributeData.push({
          customFieldId: col?.field?.id,
          name: att?.value,
          id: att?.id,
        });
      }
    }

    updateContact.mutate(
      {
        data: contactData,
        id: contactState.id,
        attributeData: {
          attributes: attributeData,
        },
      },
      {
        onSuccess: (data) => {
          //   onSaved();
          // setColumns(finalFields ? finalFields : {});
        },
      }
    );
  }

  console.log(contactState);

  const items = [
    {
      id: 0,
      name: "Layout",
    },
    {
      id: 1,
      name: "All Fields",
    },
  ];

  const [active, setActive] = useState(0);
  return (
    <>
      <div className={styles.content}>
        <TabPill
          tabBarItems={items}
          active={active}
          updateActive={setActive}
        ></TabPill>
        {active === 0 && (
          <ContactLayoutEnabled
            contactState={contactState}
            handleFieldChange={handleFieldChange}
            handleAttributeChange={handleAttributeChange}
          ></ContactLayoutEnabled>
        )}
         {active === 1 && (
          <ContactLayoutAllFields
            contactState={contactState}
            handleFieldChange={handleFieldChange}
            handleAttributeChange={handleAttributeChange}
          ></ContactLayoutAllFields>
        )}
      </div>
    </>
  );
}

export function ContactLayoutEnabled({
  contactState,
  handleFieldChange,
  handleAttributeChange,
}) {
  const [searchString, setSearchString] = useState("");
  const searchGroups = useSearchEnabledOrgGroups(
    searchString,
    contactState?.organizationId
  );

  return (
    <>
      {searchGroups.isLoading && <Loading></Loading>}
      <div className={styles.layout}>
        {searchGroups?.isSuccess &&
          searchGroups?.data?.groups &&
          searchGroups?.data?.groups?.map(
            ({ name, customFieldGroupRow, enabled }, i) => (
              <div className={styles.rowsContainer}>
                <div
                  style={{ width: "100%", color: "black" }}
                  className={styles.header_4}
                >
                  {name}
                </div>
                {customFieldGroupRow?.map(
                  ({ id, customFieldGroupRowField }, i) => (
                    <div className={`${styles.sortableItemContainer}`}>
                      {customFieldGroupRowField?.map((field, i) => (
                        <>
                          {field?.customField?.generic && (
                            <DataInput
                              label={field?.customField?.displayName}
                              value={contactState[field?.customField?.name]}
                              shadow
                              onChange={(value) =>
                                handleFieldChange(
                                  field?.customField?.name,
                                  value
                                )
                              }
                            ></DataInput>
                          )}
                          {!field?.customField?.generic && (
                            <DataInput
                              label={field?.customField?.displayName}
                              value={
                                contactState?.attribute?.find(
                                  (attr) =>
                                    attr.customFieldId ===
                                    field?.customField?.id
                                )?.name || ""
                              }
                              shadow
                              // onChange={(value) => handleAttributeChange(contactState?.attribute?.find(
                              //     (attr) => attr.customFieldId === field?.customField?.id
                              //   )?.id, field?.customField?.id, value)}
                            ></DataInput>
                          )}
                        </>
                      ))}
                    </div>
                  )
                )}
              </div>
            )
          )}
      </div>
    </>
  );
}

export function ContactLayoutAllFields({
  contactState,
  handleFieldChange,
  handleAttributeChange,
}) {
  const [searchString, setSearchString] = useState("");
  const searchGroups = useSearchOrgGroups(
    searchString,
    contactState?.organizationId
  );

  return (
    <>
      {searchGroups.isLoading && <Loading></Loading>}
      <div className={styles.layout}>
        {searchGroups?.isSuccess &&
          searchGroups?.data?.groups &&
          searchGroups?.data?.groups?.map(
            ({ name, customFieldGroupRow, enabled }, i) => (
              <div className={styles.rowsContainer}>
                <div
                  style={{ width: "100%", color: "black" }}
                  className={styles.header_4}
                >
                  {name}
                </div>
                {customFieldGroupRow?.map(
                  ({ id, customFieldGroupRowField }, i) => (
                    <div className={`${styles.sortableItemContainer}`}>
                      {customFieldGroupRowField?.map((field, i) => (
                        <>
                          {field?.customField?.generic && (
                            <DataInput
                              label={field?.customField?.displayName}
                              value={contactState[field?.customField?.name]}
                              shadow
                              onChange={(value) =>
                                handleFieldChange(
                                  field?.customField?.name,
                                  value
                                )
                              }
                            ></DataInput>
                          )}
                          {!field?.customField?.generic && (
                            <DataInput
                              label={field?.customField?.displayName}
                              value={
                                contactState?.attribute?.find(
                                  (attr) =>
                                    attr.customFieldId ===
                                    field?.customField?.id
                                )?.name || ""
                              }
                              shadow
                              // onChange={(value) => handleAttributeChange(contactState?.attribute?.find(
                              //     (attr) => attr.customFieldId === field?.customField?.id
                              //   )?.id, field?.customField?.id, value)}
                            ></DataInput>
                          )}
                        </>
                      ))}
                    </div>
                  )
                )}
              </div>
            )
          )}
      </div>
    </>
  );
}
