import styles from "./CreateSurvey.module.scss";
import Button from "components/Button/Button";
import { useFetchSurveyTagsGql } from "api/resources/organization/surveytags";
import { useEffect, useState } from "react";
import {
  useCopyProject,
  useCreateProjectGql,
  useCreateSurveyFromTemplate,
} from "api/resources/projects/projects";
import { useNavigate } from "react-router-dom";
import { Loading } from "components/Loading/Loading";
import { useFetchSurveyTemplates } from "api/resources/organization/organization";
import { ErrorPage } from "pages/error_page/ErrorPage";
import Survey from "components/Survey/Survey";
import { Label } from "components/layouts/Label/Label";
import {
  SelectField,
  SelectFieldCustom,
  TextFieldSimple,
} from "components/inputs";
import {
  frequencies,
  ReactionStandardSurveySettings,
  sendinternvals,
} from "assets/functions/Variables";
import FlexRow from "components/layouts/FlexRow/FlexRow";
import Icon from "components/Icon/Icon";
import InteractiveSurveysTable from "components/tables/BasicTable/InteractiveSurveysTable";
import Modal from "../../../components/ReactModal/ReactModal.jsx";
import { NavTextField } from "components/inputs/input_fields/NavTextField/NavTextField";

export const SurveyTypes = ["Custom", "NPS", "CAHPS", "Brand Equity"];

export const ConactTypes = [
  "Customer",
  "Patient",
  "Employee",
  "Market",
  "Other",
];

export function CreateSurvey({ close, org, show }) {
  const [format, setFormat] = useState("");
  const [template, setTemplate] = useState("");
  const [preview, setPreview] = useState("");
  const [pickedTags, setPickedTags] = useState([]);
  const [type, setType] = useState("Custom");
  const [who, setWho] = useState("Customer");
  const [surveyName, setSurveyName] = useState("");
  const [surveyDescription, setSurveyDescription] = useState("");
  const [validName, setValidName] = useState(true);
  const [creating, setCreating] = useState(false);
  const [load, setLoad] = useState(false);
  const [mode, setMode] = useState("main");
  const [page, setPage] = useState(0);
  const [freq, setFreq] = useState("project");
  const [surveyInterval, setInterval] = useState("quarterly");
  const fetchTemplates = useFetchSurveyTemplates();
  const [projectType, setProjectType] = useState("");

  const createFromTemplate = useCreateSurveyFromTemplate();
  const createFromCopy = useCopyProject();
  const createProject = useCreateProjectGql();

  function changeTags(options) {
    setPickedTags(options);
  }

  useEffect(() => {
    setTimeout(() => {
      setLoad(false);
    }, 100);
  }, [template]);

  function changeName(val) {
    if (val && !validName) {
      setValidName(true);
    }
    setSurveyName(val);
  }

  function startPostProcess() {
    if (!creating) {
      // double clicks check
      if (!surveyName) {
        setValidName(false);
      } else {
        let tagArray = pickedTags.length
          ? pickedTags.map((tag) => tag.value)
          : undefined;

        if (format === "scratch") {
          const project = {
            name: surveyName,
            scheduledToStartAt: null,
            scheduledToCloseAt: null,
            defaultLocale: "en",
            status: "Draft",
            timezone: null,
            type: type,
            audienceType: who,
            description: surveyDescription,
            responseCount: 0,
            organizationId: org?.id,
            designSettings: JSON.stringify(ReactionStandardSurveySettings),
            tags: tagArray,
            isProgram: freq === "program",
            activeLink: false,
            sendInterval: freq === "program" ? surveyInterval : null,
            projectType: projectType,
          };

          createProject.mutate(
            {
              data: project,
            },
            {
              onSuccess: (data) => {
                onCreated(data?.createProject?.id);
              },
              onError: (data) => {
                console.log("error creating survey");
                setCreating(false);
              },
            }
          );
          setCreating(true);
        } else if (format === "template") {
          createFromTemplate.mutate(
            {
              data: {
                projectId: template?.projectId,
                name: surveyName,
                description: surveyDescription,
                tags: tagArray,
                isProgram: freq === "program",
                activeLink: false,
                sendInterval: template?.sendInterval,
                audienceType: who,
              },
            },
            {
              onSuccess: (data) => {
                onCreated(data?.createSurveyFromTemplate?.id);
              },
            }
          );
          setCreating(true);
        } else if (format === "copy") {
          createFromCopy.mutate(
            {
              data: {
                projectId: template?.id,
                name: surveyName,
                description: surveyDescription,
                tags: tagArray,
                isProgram: freq === "program",
                activeLink: false,
                sendInterval: template?.sendInterval,
                audienceType: who,
              },
            },
            {
              onSuccess: (data) => {
                onCreated(data?.copyProject?.id);
              },
            }
          );
          setCreating(true);
        }
      }
    }
  }

  function closeOut() {
    setFormat("");
    setSurveyDescription("");
    setSurveyName("");
    setPickedTags([]);
    setType("");
    setValidName(true);
    setTemplate("");
    setMode("main");
    setPreview("");
    history.back();
  }

  const surveyTagsQuery = useFetchSurveyTagsGql();

  const navigate = useNavigate();

  function onCreated(projId) {
    navigate("/project/" + projId + "/survey-details");
    localStorage.setItem("activeprojecttab", 0);
  }

  return (
    <>
      {!creating && (
        <div className={styles.page}>
          <div className={styles.navContainer}>
            <Button style={{ gap: ".5rem" }} shadow onClick={closeOut}>
              <Icon blue iconName="arrow-left-circle"></Icon>Go Back
            </Button>
            <Button style={{ gap: ".5rem", fontWeight: "400" }} shadow>
              <Icon blue iconName="info-circle"></Icon>Survey details will
              determine what suggestions you will receive during the survey send
              process{" "}
            </Button>
          </div>
          <div className={styles.content}>
            {/* {page === 0 && (
              <div className={styles.settings}>
                
              </div>
            )} */}

            {(format === "copy" || format === "template") && preview && (
              <Modal
                rightStyle
                dark
                show={(format === "copy" || format === "template") && preview}
                modalStyle={{ width: "500px", height: "100%" }}
                onClose={() => setPreview("")}
              >
                <div
                  className={styles.settingSection}
                  style={{
                    padding: "0em",
                    width: "100%",
                    maxWidth: "100%",
                    height: "fit-content",
                    borderBottom: "none",
                    fontSize: ".8em",
                  }}
                >
                  <div className={styles.header_4}>
                    "{preview.name}" Preview
                  </div>
                  <Survey
                    projectId={preview.id}
                    preview
                    stats
                    fontSize="12px"
                  ></Survey>
                </div>
              </Modal>
            )}

            {page === 0 && (
              <div
                className={styles.settings}
                style={{ width: "100%", maxWidth: "1000px" }}
              >
                <div
                  className={styles.header_3}
                  style={{ margin: "0", fontWeight: "400" }}
                >
                  Create a new survey
                </div>
                <div
                  className={styles.text_2}
                  style={{ margin: "0", fontWeight: "400" }}
                >
                  How do you want to create your survey?
                </div>

                <FlexRow
                  style={{
                    flexWrap: "wrap",
                    marginTop: "2rem",
                    justifyContent: "center",
                  }}
                >
                  <div
                    className={`${styles.box} ${
                      format === "scratch" && styles.boxfill
                    }`}
                    onClick={() => {
                      setFormat(format === "scratch" ? "" : "scratch");
                      setTemplate(null);
                      setPage((page) => (page += 1));
                    }}
                  >
                    <div
                      className={`${styles.square}`}
                      style={{ border: "4px dotted #a3a4a8" }}
                    >
                      <i
                        className={"bi-plus"}
                        style={{ color: "#a3a4a8", fontSize: "4.8rem" }}
                      ></i>
                    </div>
                    <div
                      className={styles.text_2}
                      style={{ margin: "0", fontWeight: "400" }}
                    >
                      Blank survey
                    </div>
                    <div
                      className={styles.text_3}
                      style={{ margin: "0", fontWeight: "400" }}
                    >
                      Start from scratch
                    </div>
                  </div>
                  <div
                    className={`${styles.box} ${
                      format === "template" && styles.boxfill
                    }`}
                    onClick={() => {
                      setFormat(format === "template" ? "" : "template");
                      setTemplate(null);
                      setPage((page) => (page += 1));
                    }}
                  >
                    <div className={`${styles.square}`}>
                      <i className={"bi-rocket-takeoff-fill"}></i>
                    </div>
                    <div
                      className={styles.text_2}
                      style={{ margin: "0", fontWeight: "400" }}
                    >
                      Use a Template
                    </div>
                    <div
                      className={styles.text_3}
                      style={{ margin: "0", fontWeight: "400" }}
                    >
                      Choose from library
                    </div>
                  </div>
                  <div
                    className={`${styles.box} ${
                      format === "copy" && styles.boxfill
                    }`}
                    onClick={() => {
                      setFormat(format === "copy" ? "" : "copy");
                      setTemplate(null);
                      setPage((page) => (page += 1));
                    }}
                  >
                    <div className={`${styles.square}`}>
                      <i className={"bi-copy"}></i>
                    </div>
                    <div
                      className={styles.text_2}
                      style={{ margin: "0", fontWeight: "400" }}
                    >
                      Copy past survey
                    </div>
                    <div
                      className={styles.text_3}
                      style={{ margin: "0", fontWeight: "400" }}
                    >
                      Choose from past surveys
                    </div>
                  </div>
                  {/* <div
                    className={`${styles.box} ${
                      format === "scratch" && styles.boxfill
                    }`}
                    onClick={() => {
                      setFormat(format === "scratch" ? "" : "scratch");
                      setTemplate(null);
                    }}
                  >
                    <div
                      className={`${styles.square}`}
                    >
                      <i className={"bi-copy"}></i>
                    </div>
                    <div
                      className={styles.text_2}
                      style={{ margin: "0", fontWeight: "400" }}
                    >
                      Import Survey
                    </div>
                    <div
                      className={styles.text_3}
                      style={{ margin: "0", fontWeight: "400" }}
                    >
                      Import a file of a survey
                    </div>
                  </div> */}
                </FlexRow>
              </div>
            )}

            {page === 1 && format === "template" && (
              <div className={styles.fromTemplate}>
                {/* <span className={styles.fromheader}>
                  Choose a template to use
                </span> */}

                {fetchTemplates.isLoading && <Loading />}
                {fetchTemplates.isError && (
                  <ErrorPage
                    error={fetchTemplates.error}
                    backup={"fetchTemplates"}
                  />
                )}
                <div className={styles.templates}>
                  <div className={styles.top}>
                    <div
                      className={styles.header_3}
                      style={{ margin: "0", fontWeight: "400" }}
                    >
                      Survey Templates
                    </div>
                    <div
                      className={styles.text_2}
                      style={{ margin: "0", fontWeight: "400" }}
                    >
                      Create a new survey with a template
                    </div>
                    <FlexRow
                      start
                      style={{ width: "fit-content", marginTop: ".5rem" }}
                    >
                      <Button
                        shadow
                        onClick={() => {
                          setPage((page) => (page -= 1));
                          setFormat("");
                        }}
                        style={{ zIndex: "100" }}
                        width={100}
                      >
                        Back
                      </Button>
                      <NavTextField
                        placeholder="Search Templates"
                        shadow
                      ></NavTextField>
                    </FlexRow>
                  </div>
                  {fetchTemplates.isSuccess && (
                    <Templates
                      temps={fetchTemplates.data.getSurveyTemplatesByOrg}
                      setSurveyDescription={setSurveyDescription}
                      setSurveyName={setSurveyName}
                      setLoad={setLoad}
                      setTemplate={setTemplate}
                      template={template}
                      setType={setType}
                      preview={preview}
                      setPreview={setPreview}
                      setPage={setPage}
                    ></Templates>
                  )}
                </div>
              </div>
            )}
            {page === 1 && format === "copy" && (
              <div className={styles.fromTemplate}>
                <div className={styles.templates}>
                  <SurveysHandler
                    setSurveyDescription={setSurveyDescription}
                    setSurveyName={setSurveyName}
                    setLoad={setLoad}
                    setTemplate={(t) => {
                      setTemplate(t);
                      if (t != "") {
                        setPage((page) => (page += 1));
                      }
                    }}
                    template={template}
                    preview={preview}
                    setPreview={setPreview}
                    setPage={setPage}
                    setFormat={setFormat}
                  ></SurveysHandler>
                </div>
              </div>
            )}
            {!creating &&
              ((page === 1 && format === "scratch") || page === 2) && (
                <div className={styles.pageCreate}>
                  <div className={styles.settingSection}>
                    <div
                      className={styles.header_3}
                      style={{ margin: "0em", padding: "0em" }}
                    >
                      Survey Details
                    </div>
                    <div
                      className={styles.description2}
                      style={{ margin: "0em", padding: "0em" }}
                    >
                      {format === "scratch" &&
                        "You are building this survey from scratch"}
                      {format === "template" &&
                        "This survey is being built from a template"}
                      {format === "copy" &&
                        "This survey is being built from a copy of another survey"}
                    </div>
                    <div className={styles.setting}>
                      <Label
                        tooltipText={
                          "This the name of your survey. You will be able to identify your survey from this name"
                        }
                        labelIcon={<i className="bi-info-circle"></i>}
                      >
                        Survey Name
                      </Label>
                      <TextFieldSimple
                        valid={validName}
                        invalidMessage="Give your survey a name"
                        value={surveyName}
                        placeholder="Give your survey a name..."
                        onChange={changeName}
                        // style={{color: "black"}}
                      ></TextFieldSimple>
                    </div>
                    <div className={styles.setting}>
                      <Label
                        tooltipText={
                          "This a short description of what your survey will entail."
                        }
                        labelIcon={<i className="bi-info-circle"></i>}
                      >
                        Survey Description <span>(optional)</span>
                      </Label>
                      <TextFieldSimple
                        value={surveyDescription}
                        placeholder="Give a description of your survey..."
                        onChange={(value) => setSurveyDescription(value)}
                      ></TextFieldSimple>
                    </div>
                    <div className={styles.setting}>
                      <Label
                        tooltipText={
                          "This tag will enable you to easily analyze the results of the survey. For example, you can trend surveys that have the same survey tag."
                        }
                        labelIcon={<i className="bi-info-circle"></i>}
                      >
                        Survey Tag <span>(optional)</span>
                      </Label>
                      <div style={{ width: "100%", marginTop: ".5rem" }}>
                        {surveyTagsQuery.isLoading && (
                          <TextFieldSimple
                            placeholder="Type new tag or select..."
                            disable
                          ></TextFieldSimple>
                        )}
                        {surveyTagsQuery.isSuccess && (
                          <SelectFieldCustom
                            selectMultiple
                            onChange={changeTags}
                            options={surveyTagsQuery?.data?.surveyTagByOrgId.map(
                              (t) => {
                                return { value: t.id, label: t.label };
                              }
                            )}
                            values={pickedTags}
                            placeholder="Type new tag or select..."
                            searchable
                          ></SelectFieldCustom>
                        )}
                      </div>
                    </div>
                    <div className={styles.setting}>
                      <Label
                        tooltipText={
                          "Depending on what survey type you choose, your results will adjust to give you more relevant breakouts and data."
                        }
                        labelIcon={<i className="bi-info-circle"></i>}
                      >
                        What kind of survey is it?
                      </Label>

                      <div style={{ width: "100%", marginTop: ".5rem" }}>
                        <SelectFieldCustom
                          onChange={(chosen) =>
                            setType(chosen ? chosen.value : undefined)
                          }
                          options={SurveyTypes.map((val) => {
                            return { value: val, label: val };
                          })}
                          value={
                            type ? { value: type, label: type } : undefined
                          }
                          isClearable
                          placeholder="Choose a survey type..."
                        ></SelectFieldCustom>
                      </div>
                    </div>

                    {freq === "program" && (
                      <div className={styles.setting}>
                        <Label
                          tooltipText={
                            "This determines how often you will be sending your survey out. This can be changed later."
                          }
                          labelIcon={<i className="bi-info-circle"></i>}
                        >
                          How frequent will you be sending out this survey?
                        </Label>

                        <div style={{ width: "100%", marginTop: ".5rem" }}>
                          <SelectFieldCustom
                            onChange={(chosen) =>
                              setInterval(chosen ? chosen.value : undefined)
                            }
                            options={sendinternvals}
                            value={
                              surveyInterval
                                ? sendinternvals.find(
                                    (s) => s.value === surveyInterval
                                  )
                                : undefined
                            }
                            isClearable
                            placeholder="Choose a survey type..."
                          ></SelectFieldCustom>
                        </div>
                      </div>
                    )}
                    <div className={styles.setting}>
                      <Label
                        tooltipText={
                          "Our suggestions change depending on who you are sending your survey to."
                        }
                        labelIcon={<i className="bi-info-circle"></i>}
                      >
                        Who will you be sending this survey to?
                      </Label>

                      <div style={{ width: "100%", marginTop: ".5rem" }}>
                        <SelectFieldCustom
                          onChange={(chosen) =>
                            setWho(chosen ? chosen.value : undefined)
                          }
                          options={ConactTypes.map((val) => {
                            return { value: val, label: val };
                          })}
                          value={who ? { value: who, label: who } : undefined}
                          isClearable
                          placeholder="Choose Who you will send to..."
                        ></SelectFieldCustom>
                      </div>
                    </div>

                    {/* {freq === "program" && (
                <>
                  <div
                    className={styles.header}
                    style={{ margin: "0em", padding: "0em", marginTop: "2em" }}
                  >
                    Survey Distribution
                  </div>

                  <div
                    className={styles.setting}
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      padding: "0em 1em",
                    }}
                  >
                    <div
                      className={styles.description}
                      style={{ padding: "0em", margin: "0em" }}
                    >
                      Set a survey Schedule
                    </div>
                    <ToggleSwitch></ToggleSwitch>
                  </div>
                </>
              )} */}

                    <FlexRow justify={"center"}>
                      <Button
                        shadow
                        onClick={() => {
                          setPage((page) => (page -= 1));
                          if (format === "scratch") {
                            setFormat("");
                          }
                        }}
                        style={{ zIndex: "100" }}
                        width={100}
                      >
                        Back
                      </Button>
                      <Button
                        shadow
                        onClick={startPostProcess}
                        disable={
                          format != "" && format != "template"
                            ? false
                            : !template
                        }
                        blue
                        width={150}
                      >
                        Create Survey
                      </Button>
                    </FlexRow>
                  </div>
                </div>
              )}
          </div>

          {/* {format != "import" && !creating && mode === "main" && (
            <div className={styles.buttons}>
              {page === 0 && (
                <Button link shadow onClick={closeOut}>
                  Cancel
                </Button>
              )}
              {page != 0 && (
                <Button
                  shadow
                  disable={!projectType}
                  onClick={() => setPage((page) => (page -= 1))}
                  blue
                  style={{ zIndex: "100" }}
                  width={100}
                >
                  Back
                </Button>
              )}
              {page === 1 && template && (
                <div className={styles.text_1}>
                  Create a copy of "{template.name}"
                </div>
              )}
              {page === 0 && (
                <Button
                  shadow
                  disable={!projectType}
                  onClick={() => setPage((page) => (page += 1))}
                  blue
                  style={{ zIndex: "100" }}
                  width={100}
                >
                  Next
                </Button>
              )}
              {page === 1 && (
                <Button
                  shadow
                  disable={
                    (format === "copy" && !template) ||
                    (format === "template" && !template) ||
                    format === ""
                  }
                  onClick={() => setPage((page) => (page += 1))}
                  blue
                  style={{ zIndex: "100" }}
                  width={100}
                >
                  Next
                </Button>
              )}
              {page === 2 && (
                <Button
                  shadow
                  onClick={startPostProcess}
                  disable={
                    format != "" && format != "template" ? false : !template
                  }
                  blue
                  width={150}
                >
                  Create Survey
                </Button>
              )}
            </div>
          )} */}
        </div>
      )}

      {creating && (
        <div className={styles.creating}>
          <Loading></Loading>
          <span>Your survey is being created this might take a minute...</span>
        </div>
      )}

      {/* {!format && <ChooseFormat setFormat={setFormat} onExit={closeOut} />}
      {format && (
        <>
          {!from && <ChooseFrom setFrom={setFrom} onExit={closeOut} />}
          {from && (
            <>
              {from === "scratch" && (
                <CreateFromScratch
                  onExit={closeOut}
                  back={() => setFrom("")}
                  tags={
                    surveyTagsQuery.isSuccess
                      ? surveyTagsQuery.data.surveyTagByOrgId
                      : []
                  }
                  org={org}
                  onCreated={onCreated}
                />
              )}

              {from === "import" && (
                <SurveyImport
                  onExit={closeOut}
                  roles={roles}
                  back={() => setFrom("")}
                  tags={
                    surveyTagsQuery.isSuccess
                      ? surveyTagsQuery.data.surveyTagByOrgId
                      : []
                  }
                  onCreated={onCreated}
                />
              )}

              {from === "template" && (
                <ChooseTemplate
                  onExit={closeOut}
                  back={() => setFrom("")}
                  tags={
                    surveyTagsQuery.isSuccess
                      ? surveyTagsQuery.data.surveyTagByOrgId
                      : []
                  }
                  onCreated={onCreated}
                />
              )}
            </>
          )}
        </>
      )} */}
    </>
  );
}

function Templates({
  temps,
  template,
  setTemplate,
  setPreview,
  preview,
  setSurveyDescription,
  setLoad,
  setSurveyName,
  setType,
  setPage,
}) {
  const [templates, setTemplates] = useState(setTemps(temps));

  function setTemps(initTemps) {
    let finaltemps = [];
    let currCat = "";
    let temparray = [];
    for (let temp of initTemps) {
      if (temp.category != currCat) {
        if (currCat != "") {
          finaltemps.push({ category: currCat, array: temparray });
        }
        currCat = temp.category;
        temparray = [];
      }
      temparray.push(temp);
    }

    if (currCat != "") {
      finaltemps.push({ category: currCat, array: temparray });
    }

    return finaltemps;
  }

  return (
    <div className={styles.alltemps}>
      {templates.map((category, i) => (
        <div key={i}>
          <div
            className={styles.header_3}
            style={{ marginTop: i === 0 ? "0em" : "" }}
            key={i}
          >
            {" "}
            {category?.category}
          </div>
          <div className={styles.chooseItemTemplate}>
            {category?.array.map((temp, i) => (
              <div
                className={`${styles.boxTemplate}`}
                key={i}
                // onMouseEnter={() => setShowprev(temp?.id)}
                // onMouseLeave={() => setShowprev("")}
              >
                <div className={styles.imagecont}>
                  <img
                    src={
                      temp.imageURL
                        ? require(`assets/images/${temp.imageURL}`)
                        : require(`assets/images/Background.jpg`)
                    }
                    height={"100%"}
                  ></img>
                  {!temp.imageURL && (
                    <div className={styles.templatename}>{temp.name}</div>
                  )}
                </div>
                <div className={styles.boxheader}>
                  <div className={styles.header_4}>{temp.name}</div>
                  <span>{temp.description}</span>
                  <FlexRow start>
                    <Button
                      shadow
                      blue
                      onClick={() => {
                        if (temp?.projectId) {
                          setLoad(true);
                          setPreview({ ...temp, id: temp.projectId });
                        }
                      }}
                    >
                      Preview
                    </Button>
                    <Button
                      shadow
                      blue
                      onClick={() => {
                        setTemplate(template === temp ? "" : temp);
                        setSurveyName(temp.name);
                        setType(temp.type);
                        setSurveyDescription(temp.description);
                        setPage((page) => (page += 1));
                      }}
                    >
                      Choose
                    </Button>
                  </FlexRow>
                </div>
                {/* {showprev === temp?.id && (
                  <div
                    style={{
                      position: "absolute",
                      display: "flex",
                      justifyContent: "center",
                      gap: "1rem",
                      width: "100%",
                      left: "0",
                      top: "1rem",
                    }}
                  >
                    <Button
                      shadow
                      blue
                      onClick={() => {
                        setPreview(
                          template === temp
                            ? ""
                            : { ...temp, id: temp.projectId }
                        );
                        setLoad(true);
                      }}
                    >
                      Preview
                    </Button>
                    {template?.id != temp?.id && (
                      <Button
                        shadow
                        blue
                        onClick={() => {
                          setTemplate(template === temp ? "" : temp);

                          setSurveyName(temp.name);
                          setType(temp.type);
                          setSurveyDescription(temp.description);
                        }}
                      >
                        Choose
                      </Button>
                    )}
                    {template?.id === temp?.id && (
                      <Button
                        shadow
                        blue
                        onClick={() => {
                          setTemplate("");
                          setSurveyName("");
                          setType("");
                          setSurveyDescription("");
                        }}
                      >
                        Undo
                      </Button>
                    )}
                  </div>
                )} */}
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
}

function SurveysHandler({
  template,
  setTemplate,
  preview,
  setPreview,
  setSurveyDescription,
  setLoad,
  setSurveyName,
  setPage,
  setFormat,
}) {
  return (
    <>
      <InteractiveSurveysTable
        onRowClick={(temp) => {
          setPreview(preview === temp ? "" : temp);
          setLoad(true);
        }}
        widgetType="survey"
        searchPlaceholder={"Search Survey Projects..."}
        surveys
        onClickChoose={(temp) => {
          setTemplate(template === temp ? "" : temp);
          setSurveyName(temp.name + " (COPY)");
          setSurveyDescription(temp.description);
          // setLoad(true);
        }}
        chosen={template?.id}
        onClickActionButton={() => {
          setPage((page) => (page -= 1));
          setFormat("");
        }}
        actionButtonText={"back"}
      ></InteractiveSurveysTable>
    </>
  );
}
