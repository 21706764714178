import { useState, useEffect, useRef } from "react";
import accordianStyles from "../../SettingsAccordion.module.scss";
import styles from "./Data.module.scss";
import { useFetchSurveyTagsGql } from "api/resources/organization/surveytags";
import {
  useFetchAllPrograms,
  useFetchProjectsByIdsChart,
  useSearchProjectsForChart,
} from "api/resources/projects/projects";
import { useParams } from "react-router-dom";
import { DynamicOption } from "./DynamicOption/DynamicOption";
import { ChartLabel } from "../../Tools";
import FlexCol from "components/layouts/FlexColumn/FlexCol";

export const Scale = "NumberScale";
export const MultChoice = "MultipleChoice";
export const Text = "Text";
export const Matrix = "Matrix";

export const SurveyData = ({ viz, updateViz, method, newChart }) => {
  const { reportid } = useParams();

  return (
    <>
      <div className={styles.body}>
        {/* {reportid && newChart && (
          <MiddleMan
            viz={viz}
            updateViz={updateViz}
            reportId={reportid}
            method={method}
          />
        )}
        {(!reportid || !newChart) && (
          <PickData viz={viz} updateViz={updateViz} method={method} />
        )} */}

        <PickData viz={viz} updateViz={updateViz} method={method} />
      </div>
    </>
  );
};

// function MiddleMan({ viz, updateViz, reportId, method }) {
//   const getReport = useFetchReport(reportId);

//   return (
//     <>
//       {getReport.isLoading && (
//         <div style={{ display: "flex", justifyContent: "center" }}>
//           <Loading height={120} width={120} />
//         </div>
//       )}
//       {getReport.isSuccess && (
//         <PickData
//           viz={viz}
//           updateViz={updateViz}
//           tiedIds={getReport.data.report.project.map((p) => p.id)}
//           method={method}
//         />
//       )}
//     </>
//   );
// }

function PickData({ viz, updateViz, tiedIds, method }) {
  const getProjects = useFetchProjectsByIdsChart({
    projectIds: viz.projectIdsArray,
  });

  function getIds() {
    let copy = [...viz.projectIdsArray];
    if (tiedIds) {
      for (let id of tiedIds) {
        if (!copy.includes(id)) {
          copy.push(id);
        }
      }
    }
    return copy;
  }

  const [addSurvey, setAddSurvey] = useState(false);

  function genTitle(copy, projects) {
    let title = "";
    for (let project of projects) {
      if (title) {
        title += " | " + project.name;
      } else {
        title = project.name;
      }
    }

    let ids = [...copy.programIds, ...copy.tagIdsArray];
    for (let id of ids) {
      let dynamic = copy.designSettings.dynamics[id];
      if (title) {
        title += " | " + dynamic.name;
      } else {
        title = dynamic.name;
      }
    }

    copy.title = title;
  }

  function addProject(project) {
    let copy = { ...viz };
    copy.projectIdsArray.push(project.id);

    if (viz.designSettings.artificialTitle) {
      let projects = [...getProjects.data.survey];
      projects.push(project);
      genTitle(copy, projects);
    }

    updateViz(copy);
    setAddSurvey(false);
  }

  function removeProject(project) {
    let copy = { ...viz };
    let ind = copy.projectIdsArray.indexOf(project.id);
    copy.projectIdsArray.splice(ind, 1);

    if (viz.designSettings.artificialTitle) {
      let projects = [...getProjects.data.survey];
      ind = projects.findIndex((p) => p.id === project.id);
      projects.splice(ind, 1);
      genTitle(copy, projects);
    }

    updateViz(copy);
  }

  function handleUpdate(copy) {
    if (viz.designSettings.artificialTitle) {
      genTitle(copy, getProjects.data.survey);
    }

    updateViz(copy);
  }

  return (
    <>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          paddingTop: "10px",
        }}
      >
        <TagsAndPrograms viz={viz} updateViz={handleUpdate} />

        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            paddingTop: "10px",
          }}
        >
          <div
            className={`${accordianStyles.title}`}
            style={{ paddingBottom: "5px" }}
          >
            Surveys
            <i style={{ fontSize: "1.2em" }} className="bi bi-clipboard"></i>
          </div>
          {viz.projectIdsArray.length > 0 && getProjects.isSuccess && (
            <ChosenSurveys
              removeProject={removeProject}
              chosenProjects={getProjects.data.survey}
            />
          )}
        </div>

        <div className={styles.addSurvey}>
          <div
            className={styles.add}
            style={addSurvey ? { visibility: "hidden" } : undefined}
            onClick={() => setAddSurvey(true)}
          >
            + Add Survey
          </div>
          {addSurvey && (
            <AddSurvey
              addProject={addProject}
              projectIdsArray={viz.projectIdsArray}
              close={() => setAddSurvey(false)}
            />
          )}
        </div>
      </div>
    </>
  );
}

function TagsAndPrograms({ viz, updateViz }) {
  const [searchString, setSearchString] = useState("");
  const [active, setActive] = useState(false);

  function addSurveyTag(tag) {
    let copy = { ...viz };
    copy.tagIdsArray.push(tag.id);

    copy.designSettings.dynamics[tag.id] = {
      name: tag.label,
      timeFrame: "All",
      type: "surveyTag",
      id: tag.id,
    };

    updateViz(copy);
  }

  function removeSurveyTag(tagId) {
    let copy = { ...viz };
    let index = copy.tagIdsArray.indexOf(tagId);
    copy.tagIdsArray.splice(index, 1);
    delete copy.designSettings.dynamics[tagId];

    updateViz(copy);
  }

  function addProgram(program) {
    let copy = { ...viz };
    if (!copy.programIds) {
      copy.programIds = [program.id];
    } else {
      copy.programIds.push(program.id);
    }

    copy.designSettings.dynamics[program.id] = {
      name: program.name,
      timeFrame: "All",
      type: "program",
      id: program.id,
    };

    updateViz(copy);
  }

  function removeProgram(programId) {
    let copy = { ...viz };
    let index = copy.programIds.indexOf(programId);
    copy.programIds.splice(index, 1);
    delete copy.designSettings.dynamics[programId];

    updateViz(newViz);
  }

  function changeTimeFrame(id, tf) {
    let copy = { ...viz };
    let dynamic = copy.designSettings.dynamics[id];
    if (tf === "selection") {
      dynamic.selection = [];
    } else if (dynamic.timeFrame === "selection") {
      delete dynamic.selection;
    }
    dynamic.timeFrame = tf;

    updateViz(copy);
  }

  function changeSelection(id, projId) {
    let copy = { ...viz };

    let dynamic = copy.designSettings.dynamics[id];

    let ind = dynamic.selection.indexOf(projId);
    if (ind > -1) {
      dynamic.selection.splice(ind, 1);
    } else {
      dynamic.selection.push(projId);
    }

    updateViz(copy);
  }

  return (
    <>
      <div className={accordianStyles.title}>
        Program <i className="bi bi-calendar-check"></i> | Survey Tags{" "}
        <i className="bi bi-tags"></i>
      </div>
      <div className={styles.searchFor}>
        <input
          type="text"
          onChange={(e) => setSearchString(e.target.value)}
          className={styles.search}
          value={searchString}
          onFocus={() => setActive(true)}
          onBlur={() => setTimeout(() => setActive(false), 250)}
        ></input>
        <div className={styles.tagsAnchor}>
          {active && (
            <SearchTagsAndPrograms
              searchString={searchString}
              addProgram={addProgram}
              addSurveyTag={addSurveyTag}
              viz={viz}
            />
          )}
        </div>
      </div>

      {viz.programIds.map((id) => (
        <DynamicOption
          dynamic={viz.designSettings.dynamics[id]}
          onRemove={() => removeProgram(id)}
          changeTimeFrame={(tf) => changeTimeFrame(id, tf)}
          changeSelection={(proj) => changeSelection(id, proj.id)}
        />
      ))}

      {viz.tagIdsArray.map((id) => (
        <DynamicOption
          dynamic={viz.designSettings.dynamics[id]}
          onRemove={() => removeSurveyTag(id)}
          changeTimeFrame={(tf) => changeTimeFrame(id, tf)}
          changeSelection={(proj) => changeSelection(id, proj.id)}
        />
      ))}
    </>
  );
}

function SearchTagsAndPrograms({
  searchString,
  addProgram,
  addSurveyTag,
  viz,
}) {
  const getSurveyTags = useFetchSurveyTagsGql();
  const getPrograms = useFetchAllPrograms();

  function programs() {
    let programs = getPrograms.data.programs.filter(
      (p) => !viz.programIds.includes(p.id)
    );

    if (searchString) {
      programs = programs.filter((p) =>
        p.mainProject.name.toLowerCase().includes(searchString.toLowerCase())
      );
    }
    return programs;
  }

  function tags() {
    let tags = getSurveyTags.data.surveyTagByOrgId.filter(
      (p) => !viz.tagIdsArray.includes(p.id)
    );

    if (searchString) {
      tags = tags.filter((t) =>
        t.label.toLowerCase().includes(searchString.toLowerCase())
      );
    }
    return tags;
  }

  return (
    <div className={styles.tagOptions}>
      {getSurveyTags.isSuccess && getPrograms.isSuccess && (
        <>
          {programs().map((p) => (
            <div className={styles.tagOption} onClick={() => addProgram(p)}>
              {p?.mainProject?.name}
              <div
                className={`${styles.qTagName}${styles.indicator} ${styles.programIndicator}`}
              >
                Program
              </div>
            </div>
          ))}
          {tags().map((t) => (
            <div className={styles.tagOption} onClick={() => addSurveyTag(t)}>
              {t?.label}
              <div
                className={`${styles.qTagName} ${styles.indicator} ${styles.surveyTagIndicator}`}
              >
                Tag
              </div>
            </div>
          ))}
        </>
      )}

      {/* {getQuestionTags().map((t) => (
        <div className={styles.tagOption} onClick={() => addQuestionTag(t)}>
          <div className={styles.qTagName}>
            {t.name}{" "}
            {t.color && (
              <i className={`bi bi-tag-fill`} style={{ color: t.color }}></i>
            )}
          </div>
        </div>
      ))} */}
    </div>
  );
}

function AddSurvey({ addProject, projectIdsArray, close }) {
  function clickOutListener(e) {
    if (ref.current && !ref.current.contains(e.target)) {
      close();
      document.removeEventListener("click", clickOutListener, true);
    }
  }

  const ref = useRef();

  useEffect(() => {
    document.addEventListener("click", clickOutListener, true);
    return () => {
      document.removeEventListener("click", clickOutListener, true);
    };
  }, []);

  return (
    <>
      <div className={styles.addSettings} ref={ref}>
        <i className="bi-x-lg" onClick={close}></i>
        <FlexCol gap={"5px"}>
          <ChartLabel
            style={{
              paddingLeft: "5px",
            }}
          >
            Add Survey
          </ChartLabel>
          <SearchSurvey
            addProject={addProject}
            exceptForIds={projectIdsArray}
          ></SearchSurvey>
        </FlexCol>
      </div>
    </>
  );
}

export function SearchSurvey({ addProject, exceptForIds }) {
  const [searchString, setSearchString] = useState("");
  const [searchVisible, setSearchVisible] = useState(false);
  function changeSearch(e) {
    setSearchString(e.target.value);
  }
  const [perPage, setPerPage] = useState(0);
  const [pageSkip, setPageSkip] = useState(0);
  const [searchSort, setSearchSort] = useState({
    item: "startedAt",
    descend: true,
  });

  useEffect(() => {
    searchOptions.refetch();
  }, [searchString]);

  const searchOptions = useSearchProjectsForChart(
    searchString,
    pageSkip,
    perPage,
    searchSort
  );

  const ref = useRef(null);
  //handling the clicking outside of elements
  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setSearchVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  return (
    <div className={styles.searchQuestionBox} ref={ref}>
      <input
        className={styles.searchQuestionInput}
        type="text"
        placeholder="search"
        onChange={changeSearch}
        onFocus={() => {
          setSearchVisible(true);
        }}
        // onBlur={() => {
        //   setSearchVisible(false);
        // }}
      ></input>
      {searchVisible &&
        searchOptions.isSuccess &&
        !searchOptions.isRefetching && (
          <div className={styles.optionsBox}>
            <div className={styles.options}>
              {searchOptions.data?.surveys.map((project, index) => (
                <>
                  {!exceptForIds.includes(project.id) && (
                    <div
                      key={index}
                      className={styles.option}
                      onClick={() => {
                        addProject(project);
                        setSearchVisible(false);
                      }}
                    >
                      {project.name}
                    </div>
                  )}
                </>
              ))}
            </div>
          </div>
        )}
    </div>
  );
}

function ChosenSurveys({ removeProject, chosenProjects }) {
  function getProjectsInOrder() {
    let surveys = [...chosenProjects];

    surveys.sort((a, b) => {
      //Sort the projects by survey date
      if (a.startedAt === null || b.startedAt === null) {
        if (a.startedAt) return -1;
        if (b.startedAt) return 1;
        return 0;
      }
      let aDate = new Date(a.startedAt);
      let bDate = new Date(b.startedAt);
      return bDate - aDate;
    });
    return surveys;
  }

  return (
    <div className={styles.currentData}>
      {getProjectsInOrder().map((project, index) => (
        <div key={index} className={styles.projRow}>
          <div className={styles.chosenSurvey}>
            <div className={styles.projectName}>{project.name}</div>
            <i
              className="bi bi-x-lg"
              onClick={() => removeProject(project)}
            ></i>
          </div>
        </div>
      ))}
    </div>
  );
}
