import FlexCol from "components/layouts/FlexColumn/FlexCol";
import styles from "./TextTool.module.scss";
import { ChartLabel } from "../ChartLabel/ChartLabel";
import { useState, useEffect } from "react";

export function TextTool({ value, onChange, onSave, placeholder, style, children }) {
  const [val, setVal] = useState(value ? value : "");

  function handleChange(e) {
    setVal(e.target.value);
    if (onChange) {
      onChange(e.target.value);
    }
  }

  useEffect(() => {
    if (value !== val) {
      setVal(value);
    }
  }, [value]);

  function onBlur(e) {
    onSave(e.target.value);
  }

  function onKeyDown(e) {
    if (e.key === "Enter" || e.keyCode === 13) {
      onSave(e.target.value);
    }
  }

  return (
    <FlexCol gap={"5px"} style={style}>
      <ChartLabel>{children}</ChartLabel>

      <input
        type={"text"}
        onChange={handleChange}
        onKeyDown={onSave ? onKeyDown : null}
        onBlur={onSave ? onBlur : null}
        value={val}
        className={styles.textEntry}
        placeholder={placeholder}
      ></input>
    </FlexCol>
  );
}
