import { NumberInput } from "components/inputs/input_fields/NumberInput/NumberInput";
import styles from "../SettingsAccordion.module.scss";
import { MetricType, ValueType } from "../../QChart/QuestionChart";
import Button from "components/Button/Button";
import TextEditorTry from "components/inputs/input_fields/TextEditorTry/TextEditorTry";
import { combinedQs } from "../../Visualization";
import FlexRow from "components/layouts/FlexRow/FlexRow";
import { ChartLabel, Color, SelectTool, Switch } from "../Tools";
import FlexCol from "components/layouts/FlexColumn/FlexCol";

export const TextBoxSettings = ({
  viz,
  title,
  changeSettingsField,
  visible,
  setVisible,
}) => {
  function updateBox(index, field, val) {
    let boxes = [...viz.designSettings.textboxes];
    boxes[index][field] = val;
    console.log(boxes[index]);
    changeSettingsField("textboxes", boxes);
  }

  function addBox() {
    if (!viz.designSettings.textboxes) {
      let boxes = [];
      boxes.push({
        top: "10%",
        left: "95%",
        text: "Text Box",
        color: "gray",
        backgroundColor: "white",
        valuetype: "static",
        stickRight: false,
        stickLeft: false,
      });
      changeSettingsField("textboxes", boxes);
    } else {
      let boxes = [...viz.designSettings.textboxes];
      boxes.push({
        top: "10%",
        left: "95%",
        text: "Text Box",
        color: "gray",
        backgroundColor: "white",
        valuetype: "static",
        stickRight: false,
        stickLeft: false,
      });
      changeSettingsField("textboxes", boxes);
    }
  }

  function deleteBox(index) {
    let boxes = [...viz.designSettings.textboxes];
    boxes.splice(index, 1);
    changeSettingsField("textboxes", boxes);
  }

  const vizQs = combinedQs(viz);

  function allScale() {
    if (vizQs && vizQs.length) {
      for (let q of vizQs) {
        if (!q.scaleQuestion) {
          return false;
        }
      }
      return true;
    }
    return false;
  }

  function couldBeNPS() {
    for (let q of vizQs) {
      if (!(q.scaleQuestion.min === 0 && q.scaleQuestion.max === 10)) {
        return false;
      }
    }
    return true;
  }

  // {
  //   code: "npsScore",
  //   icon: "bookmark-check",
  //   title: "NPS Score",
  // },
  // {
  //   code: "numberOfComments",
  //   icon: "chat-text",
  //   title: "Number of Comments",
  // },

  const metricOptions = [
    {
      label: "Response Rate",
      value: "responseRate",
    },
    {
      label: "Responses",
      value: "responseCount",
    },
    {
      label: "Audience Size",
      value: "audienceSize",
    },
  ];

  function getOptions() {
    let options = [];

    if (viz.type !== MetricType && viz.type !== ValueType) {
      if (allScale()) {
        if (couldBeNPS()) {
          options.push({ value: "nps", label: "NPS" });
        }
        options.push({
          value: "average",
          label: "Average Value",
        });
      }
      options.push({ value: "answercount", label: "Answer Count" });
    }

    options.push({ value: "static", label: "Static Value" });
    return options;
  }

  function getValue(box) {
    let val = getOptions().find((type) => box.valuetype === type.value);
    if (val) {
      return val;
    } else {
      return { value: "static", label: "Static Value" };
    }
  }

  return (
    <>
      <div
        key={"textboxes"}
        className={`${styles.header} ${visible ? styles.headervisible : ""}`}
        onClick={!visible ? setVisible : undefined}
      >
        <FlexRow
          onClick={visible ? setVisible : undefined}
          style={{ cursor: "pointer" }}
          className={styles.accordionTitle}
        >
          <div style={{ display: "flex", alignItems: "center", gap: "1em" }}>
            {title}{" "}
            <i
              style={{ fontSize: "1.2em" }}
              // className="bi bi-input-cursor-text"
              className="bi bi-textarea-t"
            ></i>
          </div>

          <span className={styles.accordionicon}>
            <i className="bi bi-caret-left-fill"></i>
          </span>
        </FlexRow>
        {visible && (
          <div className={styles.body} style={{ gap: "20px" }}>
            {viz.designSettings.textboxes &&
              viz.designSettings.textboxes.map((box, index) => (
                <FlexCol gap={"10px"}>
                  <ChartLabel>
                    Box {index + 1}{" "}
                    <i
                      className="bi bi-dash-circle"
                      style={{ cursor: "pointer", color: "#FF8878" }}
                      onClick={() => deleteBox(index)}
                    ></i>
                  </ChartLabel>

                  <SelectTool
                    options={getOptions()}
                    value={getValue(box)}
                    onChange={(typeOption) =>
                      updateBox(index, "valuetype", typeOption.value)
                    }
                  >
                    Content
                  </SelectTool>

                  <FlexCol gap="5px">
                    <ChartLabel>Text</ChartLabel>

                    {box.valuetype === "static" && (
                      <TextEditorTry
                        onSave={(text, state) => {
                          updateBox(index, "state", state);
                          updateBox(index, "text", text);
                        }}
                        encoding={box.state}
                        editable
                        border
                        placeholder="Enter text..."
                        showToolbar
                      />
                    )}
                  </FlexCol>

                  <div
                    className={styles.setting2}
                    style={{ paddingTop: "10px" }}
                  >
                    <NumberInput
                      startNumber={box.left}
                      handleNumberChange={(val) =>
                        updateBox(index, "left", val)
                      }
                      min={0}
                      max={100}
                    ></NumberInput>

                    <ChartLabel>Horizontal (X) Position</ChartLabel>
                  </div>
                  <div className={styles.setting2}>
                    <NumberInput
                      startNumber={box.width}
                      handleNumberChange={(val) =>
                        updateBox(index, "width", val)
                      }
                      min={0}
                      max={500}
                      step={10}
                    ></NumberInput>

                    <ChartLabel>Width</ChartLabel>
                  </div>
                  <div className={styles.setting2}>
                    <NumberInput
                      startNumber={box.top}
                      handleNumberChange={(val) => updateBox(index, "top", val)}
                      min={0}
                      max={100}
                    ></NumberInput>

                    <ChartLabel>Vertical (Y) Position</ChartLabel>
                  </div>
                  <div className={styles.setting2}>
                    <NumberInput
                      startNumber={box.padding}
                      handleNumberChange={(val) =>
                        updateBox(index, "padding", val)
                      }
                      min={0}
                      max={100}
                    ></NumberInput>
                    <ChartLabel>Box Padding</ChartLabel>
                  </div>
                  <FlexCol style={{ paddingLeft: "10px" }} gap={"10px"}>
                    {!box.stickLeft && (
                      <Switch
                        checked={box.stickRight}
                        onCheck={(val) => updateBox(index, "stickRight", val)}
                      >
                        Stick Right
                      </Switch>
                    )}

                    {box.stickRight && (
                      <div className={styles.setting2}>
                        <NumberInput
                          startNumber={
                            "marginRight" in box ? box.marginRight : 45
                          }
                          handleNumberChange={(val) =>
                            updateBox(index, "marginRight", val)
                          }
                          min={0}
                          max={100}
                        ></NumberInput>
                        <ChartLabel>Margin Right</ChartLabel>
                      </div>
                    )}

                    {!box.stickRight && (
                      <Switch
                        startChecked={box.stickLeft}
                        onCheck={(val) => updateBox(index, "stickLeft", val)}
                      >
                        Stick Left
                      </Switch>
                    )}

                    {box.stickLeft && (
                      <div className={styles.setting2}>
                        <NumberInput
                          startNumber={
                            "marginLeft" in box ? box.marginLeft : 45
                          }
                          handleNumberChange={(val) =>
                            updateBox(index, "marginLeft", val)
                          }
                          min={0}
                          max={100}
                        ></NumberInput>

                        <ChartLabel>Margin Left</ChartLabel>
                      </div>
                    )}

                    <Switch
                      checked={box.hasBackground}
                      onCheck={(val) => updateBox(index, "hasBackground", val)}
                    >
                      Has Background
                    </Switch>

                    {box.hasBackground && (
                      <Color
                        color={box.backgroundColor}
                        onChange={(val) =>
                          updateBox(index, "backgroundColor", val)
                        }
                        style={{ paddingLeft: "5px" }}
                      >
                        Background Color
                      </Color>
                    )}

                    <Switch
                      checked={box.boxShadow}
                      onCheck={(val) => updateBox(index, "boxShadow", val)}
                    >
                      Box Shadow
                    </Switch>
                  </FlexCol>
                </FlexCol>
              ))}

            <div style={{ display: "flex", justifyContent: "center" }}>
              <Button gray onClick={addBox}>
                + Text Box
              </Button>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
