import { trimDate } from "assets/functions/DateFunctions";
import styles from "./Link.module.scss";
import { useFetchReportLinkInfo } from "api/resources/organization/reports";
import { Loading } from "components/Loading/Loading";
import { useFetchProject } from "api/resources/projects/projects";
import { useNavigate } from "react-router-dom";
import { useQueryClient } from "react-query";

export const LinkType = "link";

export const defaultLinkSettings = {};

export function Link({ viz, editing, role, height }) {
  return (
    <>
      {viz.designSettings.projectId && (
        <Survey
          id={viz.designSettings.projectId}
          editing={editing}
          role={role}
          height={height}
        />
      )}
      {viz.designSettings.reportId && (
        <Report
          id={viz.designSettings.reportId}
          editing={editing}
          role={role}
          height={height}
        />
      )}
    </>
  );
}

function Survey({ id, editing, role, height }) {
  const fetchProj = useFetchProject(id);

  const navigate = useNavigate();
  function onClick() {
    if (
      fetchProj.isSuccess &&
      fetchProj.data.project &&
      role.canViewSurveysTab
    ) {
      localStorage.setItem("activepage", 1);
      localStorage.setItem("activeprojecttab", 0);
      localStorage.removeItem("reportspageitem");
      navigate(`/project/${id}/survey-details`, {
        replace: true,
      });
    }
  }

  return (
    <div
      className={styles.container}
      style={editing ? undefined : { cursor: "pointer" }}
      onClick={editing ? undefined : onClick}
    >
      {fetchProj.isLoading && <Loading height={height} />}
      {fetchProj.isSuccess && (
        <>
          {!fetchProj.data.project && (
            <div className={styles.invalidHolder}>
              <div className={styles.invalid}>Link Invalid</div>
              <div className={styles.invalidText}>Survey no longer exists</div>
            </div>
          )}
          {fetchProj.data.project && (
            <>
              <div className={styles.iconHolder}>
                <div className={styles.icon}>
                  <i className="bi-card-list"></i>
                </div>
              </div>
              <div
                className={styles.ends}
                style={{ alignItems: "flex-start" }}
              ></div>
              <div className={styles.name}>{fetchProj.data.project.name}</div>
              <div className={styles.ends}>
                <span>
                  {fetchProj.data.project.startedAt ? (
                    `Opened: ${trimDate(fetchProj.data.project.startedAt)}`
                  ) : fetchProj.data.project.status === "Draft" ? (
                    <span className={styles.draft}>Draft</span>
                  ) : (
                    " "
                  )}
                </span>
                <span>
                  <span className={styles.responseCount}>
                    {`${fetchProj.data.project.responseCount} `}
                  </span>
                  Responses
                </span>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
}

function Report({ id, editing, role, height }) {
  const fetchReport = useFetchReportLinkInfo(id);

  function getPages(r) {
    const pages = fetchReport.data.report?.analysis?.length;
    let string = pages == 1 ? "Page" : "Pages";
    return (
      <>
        <span className={styles.pages}>{pages}</span> {string}
      </>
    );
  }

  function getVisuals() {
    const vizs = fetchReport.data.report?.analysis.reduce(
      (count, anal) => count + anal.visualization.length,
      0
    );
    let string = vizs == 1 ? "Visual" : "Visuals";

    return (
      <>
        {" "}
        <span className={styles.visuals}>{vizs}</span> {string}
      </>
    );
  }

  const navigate = useNavigate();
  const queryClient = useQueryClient();
  function onClick() {
    if (
      fetchReport.isSuccess &&
      fetchReport.data.report &&
      role.canViewAnalyzeTab
    ) {
      queryClient.removeQueries("report");
      localStorage.setItem("activepage", 2);
      navigate(`/report/${id}`, { replace: true });
    }
  }

  return (
    <div
      className={styles.container}
      style={editing ? undefined : { cursor: "pointer" }}
      onClick={editing ? undefined : onClick}
    >
      {fetchReport.isLoading && <Loading height={height} />}
      {fetchReport.isSuccess && (
        <>
          {!fetchReport.data.report && (
            <div className={styles.invalidHolder}>
              <div className={styles.invalid}>Link Invalid</div>
              <div className={styles.invalidText}>Report no longer exists</div>
            </div>
          )}
          {fetchReport.data.report && (
            <>
              <div className={styles.iconHolder}>
                <div className={styles.icon}>
                  <i
                    className={
                      fetchReport.data.report?.icon
                        ? `bi-${fetchReport.data.report?.icon}`
                        : "bi-clipboard-data"
                    }
                  ></i>
                </div>
              </div>
              <div className={styles.ends} style={{ alignItems: "flex-start" }}>
                {fetchReport.data.report?.updatedAt
                  ? `Updated: ${trimDate(fetchReport.data.report?.updatedAt)}`
                  : ""}
              </div>
              <div className={styles.name}>{fetchReport.data.report?.name}</div>
              <div className={styles.ends}>
                <span>
                  {getPages()}
                  {getVisuals()}
                </span>

                {fetchReport.data.report?.sharedReport.some(
                  (s) => s.organizationId
                ) && (
                  <span className={styles.orgWide}>
                    <i className="bi bi-buildings"></i>
                  </span>
                )}
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
}
