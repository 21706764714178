import typicalStyles from "../../SettingsAccordion.module.scss";
import styles from "./MetricSettings.module.scss";
import { Label } from "components/layouts/Label/Label";
import { useState } from "react";

import { DndContext, closestCenter } from "@dnd-kit/core";
import {
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import {
  restrictToVerticalAxis,
  restrictToParentElement,
} from "@dnd-kit/modifiers";
import SingleMetric from "./SingleMetric";
import Customizer from "./Customizer";
import { singleMetricDefault } from "pages/results/Charts/ScoreChart";
import { useFetchReport } from "api/resources/organization/reports";
import { useParams } from "react-router-dom";
import { Loading } from "components/Loading Rectangle/Loading";
import GlobalMetricSettings from "./GlobalSettings";
import { shortId } from "components/tables/EditableTable/utils";
import { AddMetric } from "./AddMetric/AddMetric";
import FlexRow from "components/layouts/FlexRow/FlexRow";

export const surveyOnes = [
  {
    code: "responseRate",
    icon: "percent",
    title: "Response Rate",
  },
  // {
  //   code: "emailBounces",
  //   icon: "send-exclamation",
  //   title: "Email Bounces",
  //   metric: "10*",
  // },
  {
    code: "responseCount",
    icon: "clipboard-check",
    title: "Responses",
    // metric: "856",
  },
  // {
  //   code: "numInvitationsSent",
  //   icon: "mailbox",
  //   title: "Invitations Sent",
  //   metric: "1325*",
  // },
  {
    code: "audienceSize",
    icon: "card-list",
    title: "Audience Size",
    // metric: "362",
  },
  // {
  //   code: "surveyWarnings",
  //   icon: "exclamation-diamond",
  //   title: "Survey Warnings",
  //   metric: "10*",
  // },
  // {
  //   code: "collaborators",
  //   icon: "people",
  //   title: "Collaborators",
  //   metric: "14*",
  // },
  {
    code: "avgSurveyTime",
    icon: "stopwatch",
    title: "Avg Survey Time",
  },
];

export default function MetricSettings({
  title,
  changeSettings,
  updateViz,
  settings,
  visible,
  setVisible,
  viz,
}) {
  return (
    <>
      <div
        className={`${typicalStyles.header} ${
          visible ? typicalStyles.headervisible : ""
        }`}
        onClick={!visible ? setVisible : undefined}
      >
        <FlexRow
          onClick={visible ? setVisible : undefined}
          style={{ cursor: "pointer" }}
          className={typicalStyles.accordionTitle}
        >
          <div style={{ display: "flex", alignItems: "center", gap: "1em" }}>
            {title}{" "}
            <i style={{ fontSize: "1.2em" }} className="bi bi-ui-checks"></i>
          </div>
          {/* {title} */}
          <span className={typicalStyles.accordionicon}>
            <i className="bi bi-caret-left-fill"></i>
          </span>
        </FlexRow>

        {visible && (
          <>
            {/* {settings.metrics?.small && ( */}
            <Metrics
              settings={settings}
              changeSettings={changeSettings}
              viz={viz}
              updateViz={updateViz}
            />
            {/* )}
          {settings.metrics?.big && (
            <BigMetric settings={settings} changeSettings={changeSettings} />
          )} */}
          </>
        )}
      </div>
    </>
  );
}

function Metrics({ settings, changeSettings, viz, updateViz }) {
  const [addNew, setAddNew] = useState(false);
  const [newCustomizable, setNewCustomizable] = useState();

  const [open, setOpen] = useState(-1);

  function toggle(val) {
    if (open == val) {
      setOpen(-1);
    } else {
      setOpen(val);
    }
  }

  function changeList(list) {
    let newMetrics = { ...settings.metrics };
    newMetrics = list;
    changeSettings("metrics", newMetrics);
  }

  function addMetric(metric) {
    let copy = { ...viz };

    metric.id = shortId();
    if (copy.designSettings.metrics) {
      let length = copy.designSettings.metrics.length;
      let it = {};
      if (length) {
        let previous = { ...copy.designSettings.metrics[length - 1] };
        delete previous.questions;
        delete previous.projects;
        delete previous.dynamic;
        it = { ...previous, ...metric };
      } else {
        it = { ...singleMetricDefault, ...metric };
      }
      copy.designSettings.metrics.push(it);
    } else {
      let it = { ...singleMetricDefault, ...metric };
      copy.designSettings.metrics = [it];
    }

    for (let proj of metric.projects) {
      if (!copy.projectIdsArray.includes(proj.id)) {
        copy.projectIdsArray.push(proj.id);
      }
    }

    updateViz(copy);
    setAddNew(false);
  }

  function deleteOne(index) {
    let copy = { ...viz };
    copy.designSettings.metrics.splice(index, 1);

    let ids = [];
    for (let id of copy.projectIdsArray) {
      if (
        copy.designSettings.metrics.some((m) =>
          m.projects.some((p) => p.id === id)
        )
      ) {
        ids.push(id);
      }
    }
    copy.projectIdsArray = ids;

    updateViz(copy);
    setOpen(-1);
  }

  function handleDragOver({ active, over }) {
    if (active.id !== over?.id) {
      let oldList = [...settings.metrics];
      let indexOfActive = active.data.current.sortable.index; //oldList.findIndex((m) => m.id === active.id);
      let indexOfOver = over.data.current.sortable.index; //oldList.findIndex((m) => m.id === over.id);

      let newList = [...oldList];
      newList.splice(indexOfActive, 1, oldList[indexOfOver]);
      newList.splice(indexOfOver, 1, oldList[indexOfActive]);
      changeList(newList);
    }
  }

  function updateSingle(ind, metric) {
    let newList = [...settings.metrics];
    newList[ind] = metric;
    changeList(newList);
  }

  function onDoneCustomizing(w) {
    addMetric(w);
    setNewCustomizable(null);
    setAddNew(false);
  }

  const { reportid } = useParams();

  return (
    <>
      <Label style={{ fontWeight: "600", color: "#8dabb2" }}>Metrics</Label>
      {settings.metrics && (
        <div className={typicalStyles.col} style={{ gap: "5px" }}>
          <DndContext
            // sensors={sensors}
            collisionDetection={closestCenter}
            onDragOver={handleDragOver}
            modifiers={[restrictToVerticalAxis, restrictToParentElement]}
          >
            <SortableContext
              id={"Small Metrics"}
              items={settings.metrics.map((m) => (m.id ? m.id : m.title))}
              strategy={verticalListSortingStrategy}
            >
              {settings.metrics.map((m, i) => (
                <SingleMetric
                  metric={m}
                  ind={i}
                  key={i}
                  updateSingle={(copy) => updateSingle(i, copy)}
                  onDelete={() => deleteOne(i)}
                  settings={settings}
                  open={open}
                  toggle={() => toggle(i)}
                  viz={viz}
                />
              ))}
            </SortableContext>
          </DndContext>
        </div>
      )}
      <div className={typicalStyles.row} style={{ justifyContent: "center" }}>
        {!addNew && open == -1 && (
          <div className={styles.plusMetric} onClick={() => setAddNew(true)}>
            + Metric
          </div>
        )}
        {addNew && (
          <>
            {reportid && (
              <MiddleMan
                onClickOut={() => setAddNew(false)}
                addMetric={addMetric}
                viz={viz}
                reportId={reportid}
              />
            )}
            {!reportid && (
              <AddMetric
                onClickOut={() => setAddNew(false)}
                addMetric={addMetric}
                viz={viz}
              />
            )}
          </>
        )}
      </div>

      {settings.metrics && settings.metrics.length > 0 && open == -1 && (
        <GlobalMetricSettings
          viz={viz}
          changeSettings={changeSettings}
          settings={settings}
          updateViz={updateViz}
        />
      )}

      {newCustomizable && (
        <Customizer
          metric={newCustomizable}
          setMetric={setNewCustomizable}
          onDone={onDoneCustomizing}
          onCancel={() => {
            setNewCustomizable(null);
            setAddNew(true);
          }}
          filters={viz.filters}
        />
      )}
    </>
  );
}

// function BigMetric({ settings, changeSettings }) {
//   const [choose, setChoose] = useState();

//   function changeMetric(metric) {
//     let newMetrics = { ...settings.metrics };
//     newMetrics.big = metric;

//     changeSettings("metrics", newMetrics);
//     setChoose(false);
//   }

//   function onClickOut(e) {
//     let select = document.getElementById("big metric select");
//     if (e.target !== select) {
//       // Because there is already an onClick on the select that will close it. Let it fall to it.
//       setChoose(false);
//     }
//   }

//   function getOptions() {
//     let array = [];
//     let current = settings.metrics.big.title;
//     for (let m of surveyOnes) {
//       if (m.title !== current && !m.solelyLink) {
//         array.push(m);
//       }
//     }
//     return array;
//   }

//   return (
//     <div className={styles.col} style={{ gap: "5px" }}>
//       <Label style={{ fontWeight: "600", color: "#8dabb2" }}>Big Metric</Label>
//       <div
//         className={styles.select}
//         onClick={() => setChoose(!choose)}
//         id="big metric select"
//       >
//         <div
//           className={styles.value}
//           style={
//             !settings.metrics.big?.title ? { color: "#a3a4a8" } : undefined
//           }
//         >
//           {settings.metrics.big?.title
//             ? settings.metrics.big.title
//             : "No Metric Chosen"}
//         </div>
//         <div className={styles.arrow}>
//           <i className="bi bi-chevron-down"></i>
//         </div>
//       </div>

//       {choose && (
//         <div className={styles.col} style={{ alignItems: "center" }}>
//           <AddMetric
//             onClickOut={onClickOut}
//             addMetric={changeMetric}
//             options={getOptions()}
//           />
//         </div>
//       )}
//     </div>
//   );
// }

function MiddleMan({ onClickOut, addMetric, viz, reportId }) {
  const getReport = useFetchReport(reportId);

  return (
    <>
      {getReport.isLoading && (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Loading height={120} width={120} />
        </div>
      )}
      {getReport.isSuccess && (
        <AddMetric
          onClickOut={onClickOut}
          addMetric={addMetric}
          viz={viz}
          tiedIds={getReport.data.report.project.map((p) => p.id)}
        />
      )}
    </>
  );
}
