import { useState, useEffect, useRef } from "react";
import styles from "./AnswerTable.module.scss";
import ContactDetails from "components/Popout/ContactProfile";
import { combinedProjIds, combinedQs } from "../Visualization";
import { getTitleContainerStyle, getTitleStyle } from "./Table";
import BucketBreakdown from "./BucketBreakdown/BucketBreakdown";
import { addColumnStrings } from "assets/functions/ObjectFunctions";
import { useGetPagedAnswers } from "api/resources/projects/answers";
import { useFetchProjectsByIdsGql } from "api/resources/projects/projects";
import { Loading } from "components/Loading/Loading";
import { ScrollPullTable } from "components/Charts/Table/ScrollPullTable";
import TableChart from "components/Charts/Table/TableChart";
import { BackendDataTable } from "./DataTable/BackendDataTable";

export function AnswerTable({
  viz,
  inEdit,
  newChart,
  custom_fields,
  height,
  role,
  seeData,
  closeSeeData,
  setOutsideData,
  setUpOutsideDataCounter,
  filters,
  filterSubtitle,
  saveTableSort,
  show,
  setShowDrill,
}) {
  const [page, setPage] = useState();
  const [answers, setAnswers] = useState();
  const [moreToPull, setMoreToPull] = useState(true);
  const [counter, setCounter] = useState(-1);

  const [needAll, setNeedAll] = useState(
    viz.designSettings.tableSort && viz.designSettings.tableSort.length
      ? true
      : false
  );

  const colsRef = useRef();
  const dataRef = useRef();
  const filtersRef = useRef();

  const refreshRef = useRef(false);

  const vizQs = combinedQs(viz);

  const columns = { names: [] };
  let fields = viz.designSettings.tableFields;
  for (let key in fields) {
    let col = fields[key];
    if (
      col.show &&
      !col.project &&
      key !== "firstName" &&
      key !== "lastName" &&
      key !== "email"
    ) {
      columns.names.push(key);
    }
  }

  const fetchPage = useGetPagedAnswers();

  const getProjects = useFetchProjectsByIdsGql(
    {
      projectIds: combinedProjIds(viz),
    },
    viz.id
  );

  function onScrolled(onPulled) {
    pullData(page + 1, onPulled);
  }

  function pullData(pageNum, onPulled) {
    setPage(pageNum);

    fetchPage.mutate(
      {
        data: { questionIds: vizQs.map((q) => q.id) },
        filters: filters,
        userConstraints: {
          constraints: viz.designSettings?.dynamic
            ? viz.designSettings.userConstraints
            : undefined,
        },
        columns: columns,
        page: pageNum,
      },
      {
        onSuccess: (data) => {
          if (data.paged) {
            if (refreshRef.current) {
              setAnswers([...data.paged.answers]);
              refreshRef.current = false;
            } else {
              setAnswers([...(answers ? answers : []), ...data.paged.answers]);
            }

            if (onPulled) {
              onPulled(data.paged.more);
            }

            setMoreToPull(data.paged.more);
          }
        },
        onError: (err) => {
          console.log(err);
        },
      }
    );
  }

  function refresh() {
    pullData(needAll ? -1 : 0);
    if (answers) setAnswers(null);
    refreshRef.current = true;
    setMoreToPull(true);
    setCounter((old) => old + 1);
  }

  useEffect(() => {
    refresh();
  }, [needAll]);

  useEffect(() => {
    let qIds = vizQs.map((q) => q.id);
    if (dataRef.current && dataRef.current.toString() !== qIds.toString()) {
      refresh();
    } else if (
      colsRef.current &&
      colsRef.current.names?.toString() !== columns.names?.toString()
    ) {
      refresh();
    }

    colsRef.current = columns;
    dataRef.current = qIds;
  }, [viz]);

  useEffect(() => {
    if (filtersRef.current && filtersRef.current !== filters) {
      refresh();
    }
    filtersRef.current = filters;
  }, [filters]);

  return (
    <>
      {!seeData && show && (
        <>
          {getProjects.isLoading && <Loading height={height}></Loading>}
          {getProjects.isSuccess && (
            <>
              <Table
                answers={answers}
                projects={getProjects.data?.survey}
                viz={viz}
                canSeeContactInfo={role.canSeeContactInfo}
                newChart={newChart}
                inEdit={inEdit}
                custom_fields={custom_fields}
                filterSubtitle={filterSubtitle}
                onSaveSort={saveTableSort}
                height={height}
                setOutsideData={setOutsideData}
                setUpOutsideDataCounter={setUpOutsideDataCounter}
                refetch={() => fetchPage.refetch()}
                setShowDrill={setShowDrill}
                onScrolled={onScrolled}
                setNeedAll={setNeedAll}
                moreToPull={moreToPull}
                counter={counter}
              ></Table>
            </>
          )}
        </>
      )}

      {seeData && (
        <BackendDataTable
          viz={viz}
          filters={filters}
          custom_fields={custom_fields}
          closeSeeData={closeSeeData}
        />
      )}
    </>
  );
}

function Table({
  answers,
  viz,
  canSeeContactInfo,
  inEdit,
  projects,
  newChart,
  filterSubtitle,
  onSaveSort,
  height,
  setOutsideData,
  setUpOutsideDataCounter,
  custom_fields,
  refetch,
  setShowDrill,
  onScrolled,
  setNeedAll,
  moreToPull,
  counter,
}) {
  const [dataArray, setDataArray] = useState();
  const [headers, setHeaders] = useState();
  const [contact, setContact] = useState(null);
  const [breakout, setBreakout] = useState(null);

  const vizQs = combinedQs(viz);

  function isRanking(qId) {
    let q = vizQs.find((q) => q.id === qId);
    return q?.choiceQuestion?.isRanking;
  }

  function getMatrixAnswer(answer) {
    let matrixAnswer = JSON.parse(answer.matrixAnswer);
    let q = vizQs.find((q) => q.id === answer.questionId);
    let string = "";
    for (let option of q.matrixQuestion.options) {
      if (
        viz.designSettings.showOnly &&
        viz.designSettings.showOnly.some(
          (one) => q.matrixQuestion.options.some((opt) => one === opt) // applies to this matrix Q
        ) &&
        !viz.designSettings.showOnly.includes(option)
      ) {
        continue;
      }
      if (option in matrixAnswer) {
        if (string) {
          string += "\n";
        }
        string += option + ": ";
        if (q.choiceQuestion) {
          let choiceAnswer = matrixAnswer[option];
          const ranking = isRanking(answer.questionId);
          for (let i = 0; i < choiceAnswer.length; i++) {
            if (ranking) {
              string += i + 1 + ": ";
            }
            string += choiceAnswer[i];
            if (i < choiceAnswer.length - 1) {
              if (ranking) {
                string += "  ";
              } else {
                string += ", ";
              }
            }
          }
        } else {
          string += matrixAnswer[option];
        }
      }
    }
    if (string) {
      return <div style={{ whiteSpace: "pre" }}>{string}</div>;
    }
    return string;
  }

  function getRankingAnswer(answer) {
    let string = "";
    for (let i = 0; i < answer.choiceAnswer.length; i++) {
      if (string) string += " ";
      string += i + 1 + ": ";
      string += answer.choiceAnswer[i];
    }
    return string;
  }

  function getAnswer(answer) {
    if (answer.choiceAnswer) {
      if (isRanking(answer.questionId)) {
        return getRankingAnswer(answer);
      }
      let string = "";
      for (let i = 0; i < answer.choiceAnswer.length; i++) {
        if (string) {
          string += ", ";
        }
        string += answer.choiceAnswer[i];
      }
      return string;
    }
    if (answer.scaleAnswer || answer.scaleAnswer === 0) {
      return answer.scaleAnswer;
    }
    if (answer.textAnswer) {
      return answer.textAnswer;
    }
    if (answer.matrixAnswer) {
      return getMatrixAnswer(answer);
    }
    return "Did not answer";
  }

  const style = (value) => <span className={styles.text}>{value}</span>;

  const initHeaders = [
    {
      name: "First Name",
      accessor: "firstName",
      cell_style: style,
    },
    {
      name: "Last Name",
      accessor: "lastName",
      cell_style: style,
    },
    {
      name: "Email",
      accessor: "email",
      cell_style: style,
    },
  ];

  function getColumns() {
    let columns = [];
    if (!viz.designSettings.tableOrder) {
      return [];
    }

    let tableFields = viz.designSettings.tableFields;
    for (let field of viz.designSettings.tableOrder) {
      if (tableFields[field]?.show) {
        if (tableFields[field].isBucket) {
          if (!tableFields[field].hideOverall) {
            columns.push({
              name: tableFields[field]?.name,
              accessor: field,
              cell_style: style,
              onClick: (obj) => handleBucketClick(obj, field),
            });
          }

          if (tableFields[field].acc) {
            let accName = tableFields[field].accName
              ? tableFields[field].accName
              : "Accuracy";
            columns.push({
              name: tableFields[field].hideOverall
                ? tableFields[field].name + " " + accName
                : "- " + accName,
              accessor: "acc_" + field,
              cell_style: style,
              onClick: (obj) => handleBucketClick(obj, field),
            });
          }

          if (tableFields[field].breakdown) {
            let bName = tableFields[field].bName
              ? tableFields[field].bName
              : "Breakdown";
            columns.push({
              name: tableFields[field].hideOverall
                ? tableFields[field].name + " " + bName
                : "- " + bName,
              accessor: "breakdown_" + field,
              cell_style: style,
              onClick: (obj) => handleBucketClick(obj, field),
            });
          }
        } else {
          columns.push({
            name: tableFields[field]?.name ? tableFields[field].name : field,
            accessor: field,
            cell_style: style,
          });
        }
      }
    }

    return columns;
  }

  function fillInDots(dataField, contact) {
    dataField.id = contact.id;
    for (let field of viz.designSettings.tableOrder) {
      if (field in contact) {
        dataField[field] = "...";
      }
    }
  }

  function getDataField(answer, contact, containsMap) {
    let dataField = {};

    if (contact) {
      if (canSeeContactInfo) {
        dataField = { ...contact };
      } else {
        fillInDots(dataField, contact);
      }
      dataField.anon = false;
    } else {
      dataField = {
        firstName: "Anonymous",
        anon: true,
      };
    }

    let answerField = getAnswer(answer);
    if (answerField == null) {
      return null;
    }

    if (viz.designSettings.separateBySurvey) {
      dataField[containsMap[answer.questionId]] = answerField;

      let proj = projects.find((s) => s.id === answer.participation.projectId);
      if (proj) {
        dataField.survey = proj?.name;

        if (contact) {
          dataField.contact_survey = contact.id + "_" + proj.id;
        }
      }
    } else {
      dataField[answer.questionId] = answerField;
    }

    return dataField;
  }

  function getAnswerBucketLabels(result, key) {
    let overall = "";
    let acc = "";
    let breakdown = "";

    let props = [];
    if (Array.isArray(result)) {
      props = result;
    } else if (key in viz.designSettings.tableFields) {
      let bucketField = viz.designSettings.tableFields[key];

      props = [result.overall];
      if (bucketField?.includeAll) {
        for (let b in result.breakdown) {
          if (b !== result.overall && result.breakdown[b].length) {
            props.push(b);
          }
        }
      }

      let num =
        typeof result.acc === "string"
          ? Number.parseFloat(result.acc)
          : result.acc;
      acc = num * 100 + "%";

      for (let prop in result.breakdown) {
        if (bucketField?.filter && !bucketField?.filter.includes(prop)) {
          continue;
        }
        let pieces = result.breakdown[prop];
        if (pieces?.length) {
          if (breakdown) {
            breakdown += "\n";
          }
          breakdown += "- " + prop + ": ";

          let string = "";
          for (let piece of pieces) {
            if (string) {
              string += " & ";
            }
            string += piece;
          }
          breakdown += string;
        }
      }
    }

    for (let prop of props) {
      if (overall) {
        overall += ", ";
      }
      overall += prop;
    }

    return [overall, acc, breakdown];
  }

  function addBuckets(dataField, bucketMap, answer) {
    let buckets = bucketMap[answer.questionId];
    let answerBuckets = JSON.parse(answer.buckets);
    for (let bucket of buckets) {
      if (answerBuckets[bucket.name]) {
        let key = viz.designSettings.separateBySurvey
          ? bucket.id
          : bucket.id + "_" + answer.questionId;

        let [overall, acc, breakdown] = getAnswerBucketLabels(
          answerBuckets[bucket.name],
          key
        );

        dataField[key] = overall;
        // dataField["raw_" + key] = JSON.stringify(answerBuckets[bucket.name]); // stringify for easier to store the data?
        dataField["acc_" + key] = acc;
        dataField["breakdown_" + key] = breakdown;

        dataField[key + "info"] = {
          answerId: answer.id,
          bucketName: bucket.name,
          bucketId: bucket.id,
        };
      }
    }
  }

  function getData(columns) {
    let dataMap = {};
    let data = [];
    let anonymous = [];

    let containsMap = {};
    if (viz.designSettings.separateBySurvey) {
      let settings = viz.designSettings;
      for (let key of settings.tableOrder) {
        if ("contains" in settings.tableFields[key]) {
          for (let qId of settings.tableFields[key].contains) {
            containsMap[qId] = key;
          }
        }
      }
    }

    let bucketMap = {};
    for (let q of vizQs) {
      if (q.textQuestion && q.textQuestion.bucket) {
        bucketMap[q.id] = q.textQuestion.bucket;
      }
    }

    for (let answer of answers) {
      let contact = answer.participation?.contact
        ? { ...answer.participation?.contact }
        : null;
      if (contact) {
        addColumnStrings(contact, custom_fields);
      }

      let dataField = getDataField(answer, contact, containsMap);
      if (!dataField) {
        continue;
      }

      if (bucketMap[answer.questionId] && answer.buckets) {
        addBuckets(dataField, bucketMap, answer, containsMap);
      }

      if (dataField.anon) {
        anonymous.push(dataField);
      } else {
        let key = viz.designSettings.separateBySurvey
          ? dataField.contact_survey
          : dataField.id;

        if (!dataMap[key]) {
          dataMap[key] = dataField;
        } else {
          let orig = dataMap[key];
          dataMap[key] = { ...orig, ...dataField };
        }
      }
    }

    for (let key in dataMap) {
      data.push(dataMap[key]);
    }

    if (viz.designSettings.separateBySurvey) {
      sortData(data, columns);
    }

    data = [...data, ...anonymous];

    return data;
  }

  function sortData(data, columns) {
    // sort data so contacts are next to each other by last name, then first name, then by surveys
    if (columns.some((c) => c.accessor === "survey")) {
      let copy = [...projects];
      copy.sort((p1, p2) => {
        let a = p1.startedAt ? new Date(p1.startedAt).getTime() : 0;
        let b = p2.startedAt ? new Date(p2.startedAt).getTime() : 0;
        return a - b;
      });

      let projectMap = {};
      for (let i = 0; i < copy.length; i++) {
        projectMap[copy[i].name] = i;
      }

      data.sort((row1, row2) => {
        let a = projectMap[row1.survey];
        let b = projectMap[row2.survey];

        return b - a;
      });
    }
    if (columns.some((c) => c.accessor === "firstName")) {
      sortField(data, "firstName");
    }
    if (columns.some((c) => c.accessor === "lastName")) {
      sortField(data, "lastName");
    }
  }

  function sortField(data, field) {
    data.sort((row1, row2) => {
      let a = row1[field];
      let b = row2[field];
      if (!a) {
        a = "";
      }
      if (!b) {
        b = "";
      }

      if (a.toLowerCase() < b?.toLowerCase()) {
        return -1;
      }
      if (a.toLowerCase() > b?.toLowerCase()) {
        return 1;
      }
      return 0;
    });
  }

  function setFakeData() {
    let extra = [
      {
        name: "Answer",
        accessor: "answer",
        cell_style: style,
      },
    ];
    setHeaders([...initHeaders, ...extra]);

    let data = [
      {
        firstName: "John",
        lastName: "Doe",
        email: "john@doe.com",
        answer: "Okay",
      },
      {
        firstName: "Jane",
        lastName: "Doe",
        email: "jane@doe.com",
        answer: "Great",
      },
      {
        firstName: "John Jr",
        lastName: "Doe",
        email: "johnjr@doe.com",
        answer: "Good",
      },
      {
        firstName: "Jane Jr",
        lastName: "Doe",
        email: "janejr@doe.com",
        answer: "Great",
      },
    ];
    setDataArray(data);
  }

  useEffect(() => {
    if (newChart && vizQs.length === 0) {
      setFakeData();
    } else {
      let columns = getColumns();
      setHeaders(columns);

      if (answers) {
        let data = getData(columns);
        setDataArray(data);
      } else if (dataArray) {
        setDataArray();
      }
    }
  }, [viz, answers]);

  function handleRowClick(obj) {
    var selection = window.getSelection();
    if (!selection.toString()) {
      let person = { ...obj };
      if (!person.anon) {
        delete person.anon;
        setContact(person);
        setShowDrill(true);
      }
    }
  }

  function handleBucketClick(obj, id) {
    if (inEdit) return;
    var selection = window.getSelection();
    if (!selection.toString() && selection.isCollapsed) {
      if (obj[id] && obj[id + "info"]) {
        let info = obj[id + "info"];

        let answer = answers.find((a) => a.id === info.answerId);
        if (answer && answer.buckets) {
          let answerBuckets = JSON.parse(answer.buckets);
          let answerBucket = answerBuckets[info.bucketName];
          if (answerBucket && !Array.isArray(answerBucket)) {
            setBreakout({
              answerBucket: answerBucket,
              bucketId: info.bucketId,
              contact: obj.anon ? null : { ...obj },
              answer: answer,
            });
            setShowDrill(true);
            return;
          }
        }
      }
      handleRowClick(obj);
    }
  }

  return (
    <>
      {headers && (
        <ScrollPullTable
          initHeaders={headers}
          data={dataArray}
          asChart
          onRowClick={inEdit || !canSeeContactInfo ? undefined : handleRowClick}
          titleStyle={getTitleStyle(viz)}
          tableTitle={viz.designSettings.hasTitle ? viz.title : ""}
          subtitle={filterSubtitle ? filterSubtitle : false}
          tableSort={viz.designSettings?.tableSort}
          onSaveSort={onSaveSort}
          inEdit={inEdit}
          titleContainerStyle={getTitleContainerStyle(viz)}
          color={viz.designSettings.tableColor}
          height={height}
          setOutsideData={setOutsideData}
          setUpOutsideDataCounter={setUpOutsideDataCounter}
          onScrolled={onScrolled}
          setNeedAll={setNeedAll}
          moreToPull={moreToPull}
          counter={counter}
        />
      )}
      {contact && (
        <ContactDetails
          onClose={() => {
            setContact(null);
            setShowDrill(false);
          }}
          contact={contact}
        ></ContactDetails>
      )}
      {breakout && (
        <BucketBreakdown
          bucketId={breakout.bucketId}
          answerBucket={breakout.answerBucket}
          contact={breakout.contact}
          answer={breakout.answer}
          onClose={() => {
            setBreakout(null);
            setShowDrill(false);
          }}
          refetch={refetch}
        />
      )}
    </>
  );
}
