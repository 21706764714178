/*
======================= START OF LICENSE NOTICE =======================
  Copyright (C) 2024 Reaction. All Rights Reserved

  NO WARRANTY. THE PRODUCT IS PROVIDED BY DEVELOPER "AS IS" AND ANY
  EXPRESS OR IMPLIED WARRANTIES, INCLUDING, BUT NOT LIMITED TO, THE
  IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR
  PURPOSE ARE DISCLAIMED. IN NO EVENT SHALL DEVELOPER BE LIABLE FOR
  ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, OR CONSEQUENTIAL
  DAMAGES (INCLUDING, BUT NOT LIMITED TO, PROCUREMENT OF SUBSTITUTE
  GOODS OR SERVICES; LOSS OF USE, DATA, OR PROFITS; OR BUSINESS
  INTERRUPTION) HOWEVER CAUSED AND ON ANY THEORY OF LIABILITY, WHETHER
  IN CONTRACT, STRICT LIABILITY, OR TORT (INCLUDING NEGLIGENCE OR
  OTHERWISE) ARISING IN ANY WAY OUT OF THE USE OF THE PRODUCT, EVEN
  IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGE.
======================== END OF LICENSE NOTICE ========================
  Primary Author: natehanson, brodyspencer

*/

// External
import React, { useEffect, useState } from "react";
import "bootstrap-icons/font/bootstrap-icons.css";
import { BrowserRouter, useNavigate } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
// import { ReactQueryDevtools } from "react-query/devtools";

// Internal
import { SideMenu } from "components/sidebars";
import styles from "./App.module.scss";
import { MainContentRoutes } from "routes";
import { useToken } from "./pages/Login/Login";
import { NonSignInRoutesRoutes } from "./routes/NonSignInRoutes";
import WebFont from "webfontloader";
import { useGetCurrentUser } from "api/resources/organization/users";
import user from "assets/images/blank-profile-picture.png";
import uparrow from "assets/images/UpArrow.png";
import reactionlogo from "assets/images/ReactionCircleLogo.png";
import { useSubscription } from "@apollo/client";
import { NOTIFICATION } from "api/resources/subscription/subscribe";
import { useFetchRole } from "api/resources/organization/roles";
import { ErrorPage } from "pages";
import { Loading } from "components/Loading/Loading";
import { useLogoutRequest } from "api/resources/authentication/logout";
import { HorizontalBar } from "components/layouts/HorizontalBar/HorizontalBar";
import { getTime } from "pages/account/notifications/Notifications";
import {
  useSearchTasks,
  useUpdateTask,
} from "api/resources/account/notification";
import { Pie } from "components/inputs/input_fields/CircleProgress/Circle";
import Button from "components/Button/Button";

const queryClient = new QueryClient();

/**
 * Main App function for running the reaction data app. If logged in go to main app, if not show login page
 */
function App() {
  const { token, setToken } = useToken();

  useEffect(() => {
    //this loads the following fonts from google: Droid Sans, Chilanka, Poppins
    WebFont.load({
      google: {
        families: ["Droid Sans", "Chilanka", "Poppins", "Montserrat"],
      },
    });
  }, []);

  const getWidth = () =>
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth;
  let [width, setWidth] = useState(getWidth());

  useEffect(() => {
    const resizeListener = () => {
      // change width from the state object
      setWidth(getWidth());
    };
    // set resize listener
    window.addEventListener("resize", resizeListener);

    // clean up function
    return () => {
      // remove resize listener
      window.removeEventListener("resize", resizeListener);
    };
  }, []);

  return (
    <>
      <QueryClientProvider client={queryClient}>
        {!token ? ( //if token is false no user is signed in
          <BrowserRouter>
            <div className={styles.App}>
              <NonSignInRoutesRoutes
                setToken={setToken}
              ></NonSignInRoutesRoutes>
            </div>
          </BrowserRouter>
        ) : (
          <BrowserRouter>
            <AppContainer
              width={width}
              token={token}
              setToken={setToken}
            ></AppContainer>
          </BrowserRouter>
        )}
        {/* <ReactQueryDevtools /> */}
      </QueryClientProvider>
    </>
  );
}
export default App;

function AppContainer({ width, token, setToken }) {
  const { data, loading } = useSubscription(NOTIFICATION, {
    variables: { userId: token.login.user.id },
  });
  const [search, setSearch] = useState("");
  const [perPage, setPerpage] = useState(50);
  const [pageSkip, setPageSkip] = useState(0);
  const [sort, setSort] = useState({
    item: "createdAt",
    descend: true,
  });

  const fetchTasks = useSearchTasks(
    sort,
    pageSkip,
    perPage,
    search,
    false,
    false
  );

  const getCurrUser = useGetCurrentUser();
  const getCurrRole = useFetchRole();
  const [notifications, setNotifications] = useState([]);
  const [beforeNavAway, setBeforeNavAway] = useState();
  const [error, setError] = useState(false);
  const [loggingOut, setLoggingOut] = useState(false);

  useEffect(() => {
    if (data && data.notificationSubscription) {
      setNotifications((prevNotifications) => {
        const newNotification = data.notificationSubscription;
        return [newNotification, ...prevNotifications];
      });
      setTimeout(() => {
        setNotifications([]);
      }, 4500);
    }
  }, [data, loading]);

  useEffect(() => {
    const interval = setInterval(() => {
      checkForInactivity();
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    updateExpireTime();

    window.addEventListener("click", updateExpireTime);
    window.addEventListener("keypress", updateExpireTime);
    window.addEventListener("scroll", updateExpireTime);
    window.addEventListener("mousemove", updateExpireTime);

    return () => {
      window.removeEventListener("click", updateExpireTime);
      window.removeEventListener("keypress", updateExpireTime);
      window.removeEventListener("scroll", updateExpireTime);
      window.removeEventListener("mousemove", updateExpireTime);
    };
  }, []);

  function updateExpireTime() {
    const expireTime = Date.now() + 86400000; //One Day

    localStorage.setItem("expireTime", expireTime);
  }

  function checkForInactivity() {
    const expireTime = localStorage.getItem("expireTime");

    if (expireTime < Date.now()) {
      alert("You will be logged out due to inactivity");
      setError(true);
    }
  }

  function getType(notif) {
    if (notif.type === "Upload") {
      return uparrow;
    } else if (notif.type === "Survey Created") {
      return reactionlogo;
    } else {
      return user;
    }
  }

  function checkorg(user) {
    if (user && "organization" in user) {
      return user.organization.find(
        (org) => org.id === getCurrUser.data.me.organizationId
      );
    } else {
      setError(true);
    }
  }

  const updateTask = useUpdateTask();
  function hideTask(id) {
    updateTask.mutate({
      data: {
        hide: true,
      },
      id: id,
    });
  }

  const [collapsed, collapse] = useState(
    localStorage.getItem("sidemenustate")
      ? localStorage.getItem("sidemenustate")
      : width < 1024
      ? width < 560
        ? "mobile"
        : "collapsed"
      : "expanded"
  );

  const logoutRequest = useLogoutRequest();
  const navigate = useNavigate();

  function logout() {
    console.log("Logging Out");
    setLoggingOut(true);
    setTimeout(() => {
      logoutRequest.mutate(
        {},
        {
          onSuccess: () => {
            localStorage.removeItem("reaction_token");
            localStorage.removeItem("sidemenustate");
            localStorage.removeItem("activepage");
            localStorage.removeItem("activeorg");
            localStorage.removeItem("activedelivertab");
            localStorage.removeItem("ReportPage");
            localStorage.removeItem("contactspage");
            setToken(null);
            navigate("/login");
            setLoggingOut(false);
            window.location.reload();
          },
          onError: () => {
            setLoggingOut(false);
          },
        }
      );
    }, 500);
  }

  return (
    <div className={styles.App}>
      {loggingOut ? (
        <>
          <Loading />
        </>
      ) : (
        <>
          <div className={styles.notifContainer}>
            {notifications.length > 0 && (
              <>
                {notifications.map((notif, i) => (
                  <div className={styles.notificationContainer} key={i}>
                    <img
                      src={getType("")}
                      className={styles.avatar}
                      alt="user profile image"
                    />
                    <div className={styles.notification}>
                      <div className={styles.notifTitle}>
                        <div className={styles.header5}>{notif.body}</div>
                      </div>
                      <div className={styles.time}>
                        {getTime(notif.createdAt)}
                      </div>
                    </div>
                  </div>
                ))}
              </>
            )}

            {fetchTasks.isSuccess && fetchTasks.data?.tasks.length > 0 && (
              <>
                {fetchTasks.data?.tasks?.map((task, i) => (
                  <div className={styles.tasksContainer} key={i}>
                    <div className={styles.progress}>
                      <Pie
                        percentage={task?.progress ? task?.progress : 0}
                        dim={40}
                        progress
                        colour={"#15bcc7"}
                        backColor={"#d8d9d9"}
                      />
                    </div>
                    <div className={styles.notification}>
                      <div className={styles.header_6} style={{ margin: "0" }}>
                        {task.body}
                      </div>
                      <div className={styles.notifTitle}>
                        {" "}
                        <div className={styles.time}>
                          {getTime(task.createdAt)}
                        </div>
                        <Button link onClick={() => hideTask(task?.id)}>
                          {" "}
                          hide
                        </Button>
                      </div>
                    </div>
                  </div>
                ))}
                <TASKTIME tasks={fetchTasks}></TASKTIME>
              </>
            )}
          </div>
          {(getCurrRole.isError ||
            getCurrUser.isError ||
            error ||
            (getCurrUser.isSuccess && !getCurrUser.data?.me)) && (
            <div className={styles.mainContent}>
              <OnError error={getCurrUser.error} logout={logout} />
            </div>
          )}
          {(getCurrRole.isLoading || getCurrUser.isLoading) && (
            <div className={styles.mainContent}>
              <Loading></Loading>
            </div>
          )}
          {getCurrUser.isSuccess &&
            getCurrRole.isSuccess &&
            getCurrUser.data.me && (
              <SideMenu
                user={getCurrUser.data.me}
                roles={getCurrRole.data.role}
                organization={checkorg(getCurrUser.data.me)}
                beforeNavAway={beforeNavAway}
                setBeforeNavAway={setBeforeNavAway}
                collapse={collapse}
                collapsed={collapsed}
                logout={logout}
              />
            )}
          {getCurrUser.isSuccess &&
            getCurrRole.isSuccess &&
            getCurrUser.data.me && (
              <MainContentRoutes
                setBeforeNavAway={setBeforeNavAway}
                refetchTasks={fetchTasks.refetch}
                collapse={collapse}
                collapsed={collapsed}
              />
            )}
        </>
      )}
    </div>
  );
}

export function Footer() {
  return (
    <div className={styles.footer}>
      <div className={styles.flex}>
        {" "}
        <a href={"https://reactiondata.com/about-us-2/"} target="_blank">
          About Us
        </a>
        <HorizontalBar width={2} height={25} rotate />
        <a href={"https://reactiondata.com/our-team/"} target="_blank">
          Our Team
        </a>
        <HorizontalBar width={2} height={25} rotate />
        <a href={"https://reactiondata.com/solutions/"} target="_blank">
          Reaction Solutions
        </a>
        <HorizontalBar width={2} height={25} rotate />
        Copyright © 2024 Reaction Data, Inc. All rights reserved.
      </div>
    </div>
  );
}

/**
 * If there is an error with getting a role or the user the error page will be displayed.
 * After one second the user will then be logged out.
 * @param error the error that is recieved
 * @returns The error page to be displayed
 */
function OnError({ error, logout }) {
  useEffect(() => {
    setTimeout(() => {
      logout();
    }, 1000);
  }, []);

  return <ErrorPage error={error} timeout></ErrorPage>;
}

function TASKTIME({ tasks }) {
  setTimeout(function () {
    if (tasks.data?.tasks?.length > 0) {
      tasks.refetch();
    }
  }, 3000);
  return <></>;
}

function myLoop(length) {
  //  create a loop function
  setTimeout(function () {
    if (tasks.data?.tasks?.length > 0) {
      tasks.refetch();
      myLoop();
    }
  }, 1000);
}
