import React, { useState, useRef, useEffect } from "react";
import styles from "./ToFrom.module.scss";
import Modal from "components/ReactModal/ReactModal";
import Audience from "pages/contacts/audiences/Audience";
import { useGetCurrentUser } from "api/resources/organization/users";
import { useNavigate } from "react-router-dom";
import { useSearchContactsFiltersCount } from "api/resources/contacts/contacts";

export function To({ chosen, options, onChange, disable, shadow, roles }) {
  const [showOptions, setShowOptions] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [searchOptions, setSearchOptions] = useState();
  const [showAud, setShowAud] = useState();

  const selectRef = useRef(null);
  const searchRef = useRef(null);
  const getCurrUser = useGetCurrentUser();
  const [hasInteracted, setHasInteracted] = useState(false);

  useEffect(() => {
    if (hasInteracted.current && showOptions && searchRef.current) {
      searchRef.current.focus();
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showOptions, hasInteracted]);

  function toggleDropDown(e) {
    setHasInteracted(true);
    if (showOptions) {
      if (!searchRef.current || !searchRef.current.contains(e.target)) {
        setShowOptions(false);
      }
    } else {
      setShowOptions(true);
      document.addEventListener("click", handleClickOutside, true);
    }
  }

  const handleClickOutside = (event) => {
    if (!selectRef.current || !selectRef.current.contains(event.target)) {
      setShowOptions(false);
      setHasInteracted(false);
      // searchRef.current = false;
      document.removeEventListener("click", handleClickOutside, true);
      search("");
    }
  };

  const search = (userInput) => {
    if (userInput) {
      const results = options.filter((option) =>
        option.name.toLowerCase().includes(userInput.toLowerCase())
      );
      setSearchOptions(results);
    } else {
      setSearchOptions(options); // Reset to full options when the input is cleared
    }
    setSearchValue(userInput);
  };

  function handleRemoveOption(e, i) {
    e.stopPropagation();
    let copy = [...chosen];
    copy.splice(i, 1);
    onChange(copy);
  }

  function getBackColor() {
    if (showOptions) {
      return "white";
    }
    return "";
  }

  function getHeight() {
    if (options.length <= 6) {
      return (options.length + 1) * 40 + "px";
    } else {
      return "";
    }
  }

  const clearInput = () => {
    setSearchValue("");
    setSearchOptions(options);
    if (searchRef.current) {
      searchRef.current.focus();
    }
  };

  function onOptionClick(option) {
    let ind = chosen.findIndex((a) => a.id === option.id);
    if (ind > -1) {
      let copy = [...chosen.slice(0, ind), ...chosen.slice(ind + 1)];
      onChange(copy);
    } else {
      onChange([...chosen, ...[option]]);
    }
    clearInput();
  }

  function showAudience(aud) {
    setShowAud(aud);
  }

  const navigate = useNavigate();

  function onManage() {
    navigate("/audiences");
    localStorage.setItem("activepage", 4);
  }

  // TODO: Sometime do a create new audience modal, where they can select contacts they already have, or upload a file or manual entry, etc...
  return (
    <>
      <div className={`${styles.container} `} ref={selectRef}>
        <div className={styles.inputContainer}>
          <div className={styles.text}>
            <div className={styles.label55}>To:</div>
            <div className={styles.selectMultiple}>
              {chosen?.map((aud, i) => (
                <span
                  className={styles.chosenAudience}
                  key={i}
                  onClick={() => showAudience(aud)}
                >
                  <div className={styles.chosenAudienceName}>
                    {aud.name}
                    <AudienceMembers
                      id={aud?.id}
                      filters={aud?.filters}
                    ></AudienceMembers>
                    <i
                      className={`bi-x ${styles.remove}`}
                      onClick={(e) => handleRemoveOption(e, i)}
                    ></i>
                  </div>
                </span>
              ))}
              <input
                className={`${styles.input}`}
                value={searchValue}
                onChange={(e) => search(e.target.value)}
                ref={searchRef}
                onClick={!showOptions ? () => setShowOptions(true) : undefined}
              />
            </div>
          </div>
        </div>

        {showOptions && (
          <div
            className={styles.dropdown}
            style={{
              maxHeight: getHeight(),
            }}
          >
            {!searchValue && (
              <div
                className={`${styles.option} ${styles.manageAudiences}`}
                onClick={onManage}
              >
                <div className={styles.manage}>
                  Manage Audiences <i className="bi bi-gear"></i>
                </div>
              </div>
            )}

            {(searchOptions ? searchOptions : options).map((option, i) => (
              <div
                key={i}
                className={styles.option}
                onClick={() => onOptionClick(option)}
              >
                <div className={styles.audienceOptionName}>
                  {option.name}{" "}
                  <AudienceMembers
                    id={option?.id}
                    filters={option?.filters}
                  ></AudienceMembers>
                </div>

                {chosen.some((a) => a.id === option.id) && (
                  <div className={styles.checked}>
                    <i className="bi-check"></i>
                  </div>
                )}
              </div>
            ))}
          </div>
        )}
      </div>

      {getCurrUser.isSuccess && showAud && (
        <Modal
          show={showAud}
          onClose={() => setShowAud(null)}
          modalStyle={{
            borderRadius: "1em",
            height: "97%",
            width: "97%",
            maxWidth: "1400px",
            overflow: "hidden",
          }}
          dark
        >
          <Audience
            user={getCurrUser.data.me}
            audienceId={showAud?.id}
            preview
            roles={roles}
            // customFields={orgFields?.data?.getCurrOrgFields}
          ></Audience>
        </Modal>
      )}
    </>
  );
}

function AudienceMembers({ id, filters }) {
  const getContactCount = useSearchContactsFiltersCount(
    "",
    "",
    id,
    filters ? filters : ""
  );

  return (
    <span className={styles.members}>
      {getContactCount.isSuccess && getContactCount?.data?.count}
    </span>
  );
}
