import { HorizontalBar } from "components/layouts/HorizontalBar/HorizontalBar";
import { TableType } from "../QChart/QuestionChart";
import styles from "./QuickPivots.module.scss";
import { useEffect, useState, useRef } from "react";
import { combinedProjIds } from "../Visualization";
import {
  useFetchProjectsByIdsGql,
  useGetStatChartProjects,
} from "api/resources/projects/projects";
import Filter from "./Filter/Filter";
import { SplitsNPivots } from "./AccordianItems/SplitsNPivots";
import { TablePivots } from "./AccordianItems/TablePivots";
import Button from "components/Button/Button";
import { Loading } from "components/Loading/Loading";
import { StatSplitsNPivots } from "./AccordianItems/StatSplitsNPivots";

export function QuickPivots({
  viz,
  close,
  filters,
  custom_fields,
  externalFilter,
  onEdit,
  onSave,
  edited,
  inEdit,
}) {
  const ref = useRef();

  const [saving, setSaving] = useState(false);

  function clickOutside(event) {
    if (ref.current && !ref.current?.contains(event.target)) {
      close();
    }
  }

  useEffect(() => {
    document.addEventListener("click", clickOutside, true);
    return () => {
      document.removeEventListener("click", clickOutside, true);
    };
  }, []);

  const getProjects =
    viz.dataMethod === "survey"
      ? useGetStatChartProjects(viz)
      : useFetchProjectsByIdsGql({
          projectIds: combinedProjIds(viz),
        });

  const [open, setOpen] = useState("SplitsNPivots");

  function getPivotCount() {
    let count = 0;
    if (viz.pivotString) {
      count += 1;
    }
    if (viz.designSettings.split) {
      count += 1;
    }
    return count;
  }

  function getFilterCount() {
    let filtersObject = JSON.parse(filters);
    let count = 0;
    for (let key in filtersObject) {
      let filter = filtersObject[key];
      if (key === "surveys") {
        let allProjIds = combinedProjIds(viz);
        if (allProjIds.length > 1) {
          for (let survey of filter) {
            if (allProjIds.includes(survey.id)) {
              count++;
            }
          }
        }
      } else if (key === "answers") {
        count += filter.length;
      } else {
        if (filter?.properties?.length) {
          count += filter?.properties?.length;
        }
      }
    }
    return count;
  }

  function updateChosenFilters(newFilters) {
    let copy = { ...viz };
    copy.filters = JSON.stringify(newFilters);
    let count = 0;
    for (let prop in newFilters) {
      if (prop === "surveys") {
        count += newFilters[prop].length;
      } else if (prop === "answers") {
        count += newFilters[prop].length;
      } else if (prop !== "orAnswers") {
        count += newFilters[prop].properties.length;
      }
    }

    copy.designSettings.showFilterSubtitle = count < 4;
    onEdit(copy);
  }

  function getChosenFilter() {
    if (viz.filters) {
      return typeof viz.filters === "string"
        ? JSON.parse(viz.filters)
        : viz.filters;
    }
    return {};
  }

  function changeSettingsField(field, val) {
    let copy = { ...viz };
    copy.designSettings[field] = val;
    onEdit(copy);
  }

  // const [originalColors, setOriginalColors] = useState(
  //   viz.designSettings.colors
  //     ? {
  //         colors: viz.designSettings.colors,
  //         split: viz.designSettings.split,
  //         pivot: viz.pivotString,
  //         filters: viz.filters,
  //       }
  //     : undefined
  // );

  function save() {
    if (!saving) {
      onSave();
      setSaving(true);
    } else {
      console.log("double");
    }
  }

  return (
    <div className={styles.background}>
      <div className={styles.menuHolder}>
        <div
          className={styles.quickPivotsMenu}
          ref={ref}
          id={"Quick Pivots " + viz.id}
        >
          {/* <div className={styles.container} ref={ref}> */}
          {viz.title && viz.designSettings.hasTitle && (
            <div className={styles.chartName}>{viz.title}</div>
          )}
          <div className={styles.toggle}>
            <div
              className={`${styles.toggleButton} ${
                open === "Filters" && styles.active
              }`}
              onClick={() => setOpen("Filters")}
            >
              {" "}
              <i className="bi-funnel"></i>Filters
              {getFilterCount() > 0 && (
                <div className={styles.number}>{getFilterCount()}</div>
              )}
            </div>
            <HorizontalBar rotate height={15} width={2}></HorizontalBar>
            <div
              className={`${styles.toggleButton} ${
                open === "SplitsNPivots" && styles.active
              }`}
              onClick={() => setOpen("SplitsNPivots")}
            >
              <i className="bi-person-lines-fill"></i>Splits & Pivots{" "}
              {getPivotCount() > 0 && (
                <div className={styles.number}>{getPivotCount()}</div>
              )}
            </div>
            {/* <HorizontalBar rotate height={15} width={2}></HorizontalBar>
            <div
              className={`${styles.toggleButton} ${
                open === "Settings" && styles.active
              }`}
              onClick={() => setOpen("Settings")}
            >
              <i className="bi-gear-fill"></i>Settings{" "}
            </div> */}
          </div>
          <div className={styles.closeOut} onClick={close}>
            <i className="bi-x-lg"></i>
          </div>

          <div className={styles.stuff}>
            {open === "Filters" && getProjects.isSuccess && (
              <Filter
                custom_fields={custom_fields}
                chosenFilter={getChosenFilter()}
                updateChosenFilters={updateChosenFilters}
                disabled={
                  viz.designSettings?.dynamic
                    ? viz.designSettings?.userConstraints
                    : null
                }
                projects={getProjects.data.survey}
                externalFilter={
                  externalFilter ? JSON.parse(externalFilter) : undefined
                }
                style={{ padding: "0px" }}
              ></Filter>
            )}
            {open === "SplitsNPivots" && (
              <>
                {viz.type !== TableType && (
                  <>
                    {viz.dataMethod === "survey" && getProjects.isSuccess && (
                      <StatSplitsNPivots // list-check    eyeglasses    graph-up     list-columns   list-nested   list-ul    person-check-fill   person-gear  person-lines-fill    person-plus-fill   sliders-2
                        viz={viz}
                        updateViz={onEdit}
                        projects={getProjects.data.survey}
                        changeSettingsField={changeSettingsField}
                        custom_fields={custom_fields}
                      ></StatSplitsNPivots>
                    )}

                    {viz.dataMethod !== "survey" && (
                      <SplitsNPivots
                        viz={viz}
                        updateViz={onEdit}
                        changeSettingsField={changeSettingsField}
                        custom_fields={custom_fields}
                      ></SplitsNPivots>
                    )}
                  </>
                )}
                {viz.type === TableType && (
                  <TablePivots
                    viz={viz}
                    updateViz={onEdit}
                    close={() => setVisible("SplitsNPivots")}
                    changeSettingsField={changeSettingsField}
                    custom_fields={custom_fields}
                    projects={getProjects.data.survey}
                  ></TablePivots>
                )}
              </>
            )}
          </div>

          {edited && (
            <div className={styles.saveContainer}>
              <Button onClick={save} blue>
                <div className={styles.inSave}>
                  {saving ? (
                    <>
                      Saving <Loading height={15} width={15} />
                    </>
                  ) : (
                    "Save"
                  )}
                </div>
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
