//External
import React from "react";

//Internal
import styles from "./Label.module.scss";

/**
 * A customized Label
 * @param props any children contained in the <Label></Label>
 * @returns {React.ReactElement} a Label
 */
export function Label({
    italics,
    style,
    children,
    iconPosition,
    labelIcon,
    tooltipText,
    tooltipStyle,
    className,
    onClick,
}) {
    return (
        <label
            className={`${styles.thelabel} ${italics ? styles.italics : ""} ${
                iconPosition && (iconPosition === "right" ? "" : styles.reverse)
            } ${className}`}
            style={style}
            onClick={() => (onClick ? onClick() : undefined)}
        >
            {children && <div>{children}</div>}
            {labelIcon && (
                <div className={styles.icon}>
                    {labelIcon}
                    {tooltipText && (
                        <div
                            className={styles.tooltip}
                            style={tooltipStyle ? tooltipStyle : undefined}
                        >
                            {tooltipText}
                        </div>
                    )}
                </div>
            )}
        </label>
    );
}
