import React, { useState } from "react";
import styles from "./AddWidget.module.scss";
import FakeNotifWidget from "./Widgets/Notifications/FakeNotifWidget";
import FakeReportWidget from "./Widgets/Reports/FakeReportWidget";
import FakeSurveyWidget from "./Widgets/Survey/FakeSurveyWidget";
import {
  DoughnutType,
  HorizBarType,
  PieType,
  TableType,
  VertBarType,
  defaultChartSettings,
} from "pages/results/Charts/QChart/QuestionChart";
import FakeTile from "./Widgets/Tile/FakeTile";

function AddWidget({ close, setToDrop, currIndex, multiUserDash }) {
  const initSettings = (type) => {
    let designSettings = { ...defaultChartSettings };

    if (type === DoughnutType || type === PieType) {
      designSettings.hasLegend = true;
      designSettings.legendPosition = "right";
    }

    return JSON.stringify(designSettings);
  };

  const viz1 = {
    type: VertBarType,
    title: `Fake Data`,
    designSettings: initSettings(VertBarType),
    pivotString: "",
    projectIdsArray: [],
    tagIdsArray: [],
    filters: "{}",
    question: [],
    id: "123",
  };

  const viz2 = {
    type: DoughnutType,
    title: `Fake Data`,
    designSettings: initSettings(DoughnutType),
    pivotString: "",
    projectIdsArray: [],
    tagIdsArray: [],
    filters: "{}",
    question: [],
    id: "123",
  };

  const viz3 = {
    type: HorizBarType,
    title: `Fake Data`,
    designSettings: initSettings(HorizBarType),
    pivotString: "",
    projectIdsArray: [],
    tagIdsArray: [],
    filters: "{}",
    question: [],
    id: "123",
  };

  const viz4 = {
    type: TableType,
    title: `Fake Data`,
    designSettings: initSettings(TableType),
    pivotString: "",
    projectIdsArray: [],
    tagIdsArray: [],
    filters: "{}",
    question: [],
    id: "123",
  };

  const chartWidg = {
    charts: [viz1, viz2, viz4, viz3],
    needViz: true,
    autoTitle: true,
  };

  const fakeSmallMetrics = [
    {
      icon: "percent",
      title: "Response Rate",
      metric: "56%",
    },
    {
      icon: "send-exclamation",
      title: "Email Bounces",
      metric: "10",
    },
    {
      icon: "bookmark-check",
      title: "NPS Score",
      metric: "28",
    },
    {
      icon: "chat-text",
      title: "Number of Comments",
      metric: "629",
    },
    {
      icon: "clipboard-check",
      title: "Responses",
      metric: "856",
    },
    {
      icon: "mailbox",
      title: "Invitations Sent",
      metric: "1325",
    },
    // {
    //   icon: 'card-list',
    //   title: "Audience Size",
    //   metric: "362",
    // },
    // {
    //   icon: 'exclamation-diamond',
    //   title: "Survey Warnings",
    //   metric: "10",
    // },
    // {
    //   icon: 'people',
    //   title: "Collaborators",
    //   metric: "14",
    // },
  ];

  const smallMetricWidg = {
    metrics: {
      small: fakeSmallMetrics,
    },
    needSmall: true,
    autoTitle: true,
    metricAlignment: "start",
    metricOrder: "row",
  };

  const bigMetricWidg = {
    metrics: {
      big: {
        title: "Responses",
        metric: "856",
      },
    },
    needBig: true,
    autoTitle: true,
  };

  const notifSettings = {
    markAll: true,
    viewAll: true,
    unreadOnly: false,
    oneBigList: false,
    snooze: false,
    timeFrames: {
      today: true,
      withinWeek: true,
      withinMonth: true,
      monthPlus: true,
    },
  };

  const unreadNotifSettings = {
    markAll: true,
    viewAll: true,
    unreadOnly: true,
    oneBigList: false,
    snooze: false,
    timeFrames: {
      today: true,
      withinWeek: true,
      withinMonth: true,
      monthPlus: true,
    },
  };

  const reportSettings = {
    reports: [],
    showGoToReports: true,
    showRecent: false,
    recentLimit: 3,
  };

  const recentReports = {
    reports: [],
    showGoToReports: true,
    showRecent: true,
    recentLimit: 3,
  };

  const oneSurveyTile = {
    survey: {
      startedAt: "2023-09-15 16:29:30.151",
      status: "Open",
      type: "New",
      name: "Customer Experience",
      description: "First iteration",
      numQs: 3,
      responses: 35,
      id: "",
    },
  };

  const reportTile = {
    report: {
      name: "Survey Results",
      content: "From Q2",
      numPages: 3,
      numVizualizations: 10,
      type: "Custom",
      id: "",
      project: {
        name: "Q2 Survey",
      },
    },
  };

  let options = [
    {
      title: "Chart Widgets",
      type: "survey",
      available: [
        {
          w: 2,
          h: 2,
          content: <FakeSurveyWidget settings={chartWidg} />,
          settings: chartWidg,
        },
        {
          w: 4,
          h: 2,
          content: <FakeSurveyWidget settings={chartWidg} />,
          settings: chartWidg,
        },
        {
          w: 6,
          h: 2,
          content: <FakeSurveyWidget settings={chartWidg} />,
          settings: chartWidg,
        },
      ],
    },
    // {
    //   title: "Survey Metric Widgets",
    //   type: "survey",
    //   available: [
    //     {
    //       w: 1,
    //       h: 1,
    //       content: <FakeSurveyWidget settings={smallMetricWidg} />,
    //       settings: smallMetricWidg,
    //     },
    //     {
    //       w: 1,
    //       h: 1,
    //       content: <FakeSurveyWidget settings={bigMetricWidg} />,
    //       settings: bigMetricWidg,
    //     },
    //     {
    //       w: 2,
    //       h: 2,
    //       content: <FakeSurveyWidget settings={smallMetricWidg} />,
    //       settings: smallMetricWidg,
    //     },
    //   ],
    // },
    // {
    //   title: "Survey Metrics + Charts",
    //   type: "survey",
    //   available: [
    //     {
    //       w: 4,
    //       h: 2,
    //       content: <FakeSurveyWidget settings={chartWidg} />,
    //       settings: chartWidg,
    //     },
    //     {
    //       w: 4,
    //       h: 3,
    //       content: <FakeSurveyWidget settings={chartWidg} />,
    //       settings: chartWidg,
    //     },
    //     {
    //       w: 6,
    //       h: 2,
    //       content: <FakeSurveyWidget settings={chartWidg} />,
    //       settings: chartWidg,
    //     },
    //     {
    //       w: 6,
    //       h: 3,
    //       content: <FakeSurveyWidget settings={chartWidg} />,
    //       settings: chartWidg,
    //     },
    //   ],
    // },
    {
      title: "Surveys",
      type: "tile",
      available: [
        {
          w: 2,
          h: 1,
          content: <FakeTile settings={oneSurveyTile} />,
          settings: oneSurveyTile,
        },
      ],
    },
    {
      title: "Reports",
      type: "tile",
      available: [
        {
          w: 2,
          h: 1,
          content: <FakeTile settings={reportTile} />,
          settings: reportTile,
        },
        // {
        //   w: 1,
        //   h: 2,
        //   content: <FakeSurveyWidget settings={surveyTileList} />,
        //   settings: surveyTileList,
        // },
        // {
        //   w: 2,
        //   h: 3,
        //   content: <FakeSurveyWidget settings={surveyTileList} />,
        //   settings: surveyTileList,
        // },
      ],
    },

    // {
    //   title: "Recent Reports",   // make only for not inOrgs
    //   type: "reports",
    //   available: [
    //     {
    //       w: 2,
    //       h: 2,
    //       content: <FakeReportWidget h={2} settings={reportSettings} />,
    //       settings: reportSettings,
    //     },
    //     {
    //       w: 2,
    //       h: 4,
    //       content: <FakeReportWidget h={4} settings={reportSettings} />,
    //       settings: reportSettings,
    //     },
    //     {
    //       w: 2,
    //       h: 2,
    //       content: <FakeReportWidget h={2} settings={recentReports} />,
    //       settings: recentReports,
    //     },
    //     {
    //       w: 2,
    //       h: 4,
    //       content: <FakeReportWidget h={4} settings={recentReports} />,
    //       settings: recentReports,
    //     },
    //   ],
    // },
  ];

  if (!multiUserDash) {
    options.push({
      title: "Notification Widgets",
      type: "notifications",
      available: [
        {
          w: 2,
          h: 2,
          content: (
            <FakeNotifWidget
              settings={unreadNotifSettings}
              // onDragStart={() =>
              //   onDragStart(unreadNotifSettings, "notifications")
              // }
              // onDrag={close}
            />
          ),
          settings: unreadNotifSettings,
        },
        {
          w: 2,
          h: 3,
          content: <FakeNotifWidget settings={unreadNotifSettings} />,
          settings: unreadNotifSettings,
        },
        {
          w: 2,
          h: 2,
          content: <FakeNotifWidget settings={notifSettings} />,
          settings: notifSettings,
        },
        {
          w: 2,
          h: 3,
          content: <FakeNotifWidget settings={notifSettings} />,
          settings: notifSettings,
        },
      ],
    });
  }

  function onDragStart(widget, type) {
    widget.x = 0;
    widget.y = 0;
    widget.name = type === "reports" ? "Pinned Reports" : "";
    widget.type = type;
    if (type === "survey") {
      widget.settings.showName = true;
      if (widget.settings?.metrics) {
        if (widget.settings?.metrics?.small) {
          widget.settings.metrics.small = [];
        } else if (widget.settings?.metrics?.big) {
          widget.settings.metrics.big = {};
        }
      }
    } else if (type === "tile") {
      delete widget.name;
      if (widget.settings?.survey) {
        widget.settings.survey = {
          id: "",
        };
      } else if (widget.settings?.report) {
        widget.settings.report = {
          id: "",
        };
      }
    }
    widget.i = currIndex.toString();
    widget.static = false;
    setToDrop(widget);
  }

  return (
    <div
      className={styles.fullPage}
    >
      <div className={styles.addPage}>
        <div className={styles.catalog}>
          {options.map((opt) => (
            <div className={styles.section}>
              <div className={styles.sectionHeader} style={{ paddingTop: "0" }}>
                {opt.title}
              </div>
              <div className={styles.row}>
                {opt.available.map((widget) => (
                  <div
                    draggable="true"
                    unselectable="on"
                    onDrag={close}
                    onDragStart={() => onDragStart(widget, opt.type)}
                    className={styles.widget}
                    style={{
                      height: widget.h + "0em",
                      minWidth: widget.w + "0em",
                    }}
                  >
                    {widget.content}
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className={styles.blurredSection} onClick={close}></div>
    </div>
  );
}

export default AddWidget;
