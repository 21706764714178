// External
import { useEffect, useRef, useState } from "react";
import Calendar from "react-calendar";
import "./Calendar.css";
import TimePicker from "react-time-picker/dist/entry.nostyle";
import "./TimePicker.css";
// Internal
import styles from "./SelectFieldCalendar.module.scss";
import { Label } from "components/layouts/Label/Label";

import FlexRow from "components/layouts/FlexRow/FlexRow";
import { TextFieldSimple } from "../TextFieldSimple/TextFieldSimple";
/**
 * A custom select input component with no external libraries
 * @class
 * @property {object} options: The options that will be in the select menu. Should be a list of objects with 'label' and 'value' attributes
 * @property {object} defaultValue: One of the options in the options list (object with 'label' and 'value' attributes)
 * @property {boolean} selectMultiple: Whether users should be able to select multiple items
 * @property {function} onChange: A function that will be called when the user changes the selection. The value of the option will be returned.
 * @returns {React.ReactElement} Select Field component
 *
 * @example
 * <SelectField options={options} selectMultiple={false} />
 */
class SelectedDate {
  constructor(year, month, day, time, fullText, timestamp) {
    this.year = year;
    this.month = month;
    this.day = day;
    this.time = time;
    this.fullText = fullText;
    this.timestamp = timestamp;
  }
}

export const SelectFieldCalendar = ({
  value,
  icon,
  values,
  selectMultiple,
  onChange,
  startDate,
  placeholder,
  label,
}) => {
  const [date, changeDate] = useState(new Date(startDate));
  const [show, setShow] = useState(false);
  const [chosenValues, setChosenValues] = useState(values);
  const [time, setTime] = useState(
    new Date(startDate).getHours() + ":" + new Date(startDate).getMinutes()
  );
  const [dateObject, setDateObject] = useState(new SelectedDate());

  // useEffect(() => {
  //   setTime(`${date.getHours()}:${date.getMinutes()}`);
  // }, [date]);

  const ref = useRef(null);

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setShow(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  function handleRemoveOption(item) {
    //remove chosen option
    // onRemoveOption() a function passed into component

    let newValues = [...chosenValues];
    let index = newValues.indexOf(item);
    newValues.splice(index, 1);
    setChosenValues(newValues);
  }

  function commaList(array) {
    return array.map((item) => (
      <div key={item.id} className={styles.chosenOption}>
        {item} <button onClick={() => handleRemoveOption(item)}>x</button>
      </div>
    ));
  }

  function handleChangeTime(time) {
    setTime(time);
    dateObject.timestamp = Date.parse(date) / 1000;
    dateObject.day = date.getDate();
    dateObject.month = date.getMonth() + 1;
    dateObject.year = date.getFullYear();

    const newDateTime = new Date(
      `${findDay(date.getDay())}, ${date.getDate()} ${findFullMonth(
        date.getMonth()
      )} ${date.getFullYear()} ${time}:00`
    );
    changeDate(newDateTime);
    dateObject.fullText = newDateTime;
    dateObject.time = time;
    onChange(dateObject);
  }

  function handleChangeDate(newDate) {
    dateObject.timestamp = Date.parse(newDate) / 1000;
    dateObject.day = newDate.getDate();
    dateObject.month = newDate.getMonth() + 1;
    dateObject.year = newDate.getFullYear();
    dateObject.time = time + ":00";
    changeDate(newDate);
    const newDateTime = new Date(
      `${findDay(newDate.getDay())}, ${newDate.getDate()} ${findFullMonth(
        newDate.getMonth()
      )} ${newDate.getFullYear()} ${time}`
    );
    dateObject.fullText = newDateTime;
    onChange(dateObject);
  }

  let nullDate = new Date(null);

  const isDateGiven = () => {
    return date.toDateString() !== nullDate.toDateString();
  };
  const calenderDate = () => {
    if (date.toDateString() === nullDate.toDateString()) {
      return new Date();
    }
    return date;
  };

  return (
    <>
      <div className={styles.selectContainer} ref={ref}>
        <Label style={{ paddingLeft: ".75em", width: "fit-content" }}>
          {label}
        </Label>

        <div className={styles.select} onClick={() => setShow(!show)}>
          {!selectMultiple && (
            <div className={styles.activeOption}>
              {icon}
              {isDateGiven()
                ? `${findDay(date.getDay())}, ${findMonth(
                    date.getMonth()
                  )} ${date.getDate()} ${date.getFullYear()} at ${militaryTimeConverter(
                    `${time}`
                  )}`
                : placeholder}
            </div>
          )}
          {selectMultiple && (
            <div className={styles.activeOption}>
              {icon}
              {chosenValues ? commaList(chosenValues) : value}
            </div>
          )}

          <i className="bi bi-chevron-down"></i>
        </div>

        {show && (
          <div className={styles.dropdown}>
            <Calendar onChange={handleChangeDate} value={calenderDate()} />
            <div>
              at: <TimePicker onChange={handleChangeTime} value={time} />
            </div>
          </div>
        )}

        {/* {show && (
          <div className={styles.dropdown}>
            <Calendar
              onChange={handleChangeDate}
              value={calenderDate()}
              // tileDisabled={disabled}
            />
            <FlexRow style={{ width: "fit-content" }}>
              at:{" "}
              <TextFieldSimple
                onChange={handleChangeTime}
                value={time}
                type="time"
              ></TextFieldSimple>
            </FlexRow>
          </div>
        )} */}
      </div>
    </>
  );
};

export function findDay(dayNumber) {
  if (dayNumber == 0) {
    return "Sun";
  } else if (dayNumber == 1) {
    return "Mon";
  } else if (dayNumber == 2) {
    return "Tue";
  } else if (dayNumber == 3) {
    return "Wed";
  } else if (dayNumber == 4) {
    return "Thur";
  } else if (dayNumber == 5) {
    return "Fri";
  } else {
    return "Sat";
  }
}

export function findMonth(dayNumber) {
  if (dayNumber == 0) {
    return "Jan";
  } else if (dayNumber == 1) {
    return "Feb";
  } else if (dayNumber == 2) {
    return "Mar";
  } else if (dayNumber == 3) {
    return "Apr";
  } else if (dayNumber == 4) {
    return "May";
  } else if (dayNumber == 5) {
    return "Jun";
  } else if (dayNumber == 6) {
    return "Jul";
  } else if (dayNumber == 7) {
    return "Aug";
  } else if (dayNumber == 8) {
    return "Sep";
  } else if (dayNumber == 9) {
    return "Oct";
  } else if (dayNumber == 10) {
    return "Nov";
  } else {
    return "Dec";
  }
}

export function findFullMonth(dayNumber) {
  if (dayNumber == 0) {
    return "January";
  } else if (dayNumber == 1) {
    return "February";
  } else if (dayNumber == 2) {
    return "March";
  } else if (dayNumber == 3) {
    return "April";
  } else if (dayNumber == 4) {
    return "May";
  } else if (dayNumber == 5) {
    return "June";
  } else if (dayNumber == 6) {
    return "July";
  } else if (dayNumber == 7) {
    return "August";
  } else if (dayNumber == 8) {
    return "September";
  } else if (dayNumber == 9) {
    return "October";
  } else if (dayNumber == 10) {
    return "November";
  } else {
    return "December";
  }
}

export function militaryTimeConverter(time) {
  // var time = "16:30:00"; // your input

  time = time.split(":"); // convert to array

  // fetch
  var hours = Number(time[0]);
  var minutes = Number(time[1]);
  // var seconds = Number(time[2]);

  // calculate
  var timeValue;
  if (hours > 0 && hours <= 12) {
    timeValue = "" + hours;
  } else if (hours > 12) {
    timeValue = "" + (hours - 12);
  } else if (hours == 0) {
    timeValue = "12";
  }

  timeValue += minutes < 10 ? ":0" + minutes : ":" + minutes; // get minutes
  // timeValue += (seconds < 10) ? ":0" + seconds : ":" + seconds;  // get seconds
  timeValue += hours >= 12 ? " PM" : " AM"; // get AM/PM
  return timeValue;
}
