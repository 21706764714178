import { NumberInput } from "components/inputs/input_fields/NumberInput/NumberInput";
import { useState } from "react";
import styles from "../SettingsAccordion.module.scss";
import { ValueType } from "../../QChart/QuestionChart";
import { combinedQs } from "../../Visualization";
import FlexRow from "components/layouts/FlexRow/FlexRow";
import { fonts } from "assets/functions/Variables";
import FlexCol from "components/layouts/FlexColumn/FlexCol";
import {
  TextTool,
  SelectTool,
  Color,
  NumSliderTool,
  Switch,
  ChartLabel,
} from "../Tools";

export const TitleSettings = ({
  viz,
  title,
  updateViz,
  changeVizField,
  changeSettingsField,
  visible,
  setVisible,
}) => {
  const [showHint, setShowHint] = useState(false);
  const [oldTitle, setOldTitle] = useState();
  const [hasCustomTitle, setHasCustomTitle] = useState(
    !viz.designSettings.artificialTitle
  );

  function genTitle() {
    let titles = [];
    let title = "";
    for (let q of combinedQs(viz)) {
      if (!titles.includes(q.questionText)) {
        if (title.length > 0) {
          title += " | ";
        }
        title += q.questionText;
        titles.push(q.questionText);
      }
    }
    return title;
  }

  function onTitleEntry(val, encoding) {
    // debugger;
    if (viz.designSettings.artificialTitle) {
      changeSettingsField("artificialTitle", false);
    }

    let newViz = { ...viz };
    newViz["titleValue"] = val;
    updateViz(newViz);
  }

  const revertBtn = oldTitle
    ? () => {
        setOldTitle(null);
        changeSettingsField("artificialTitle", false);
        changeVizField("title", oldTitle);
      }
    : () => {
        setOldTitle(viz.title);
        changeSettingsField("artificialTitle", true);
        changeVizField("title", genTitle());
      };

  function changeTitleSize(val) {
    // Make it be .2, .3 .5
    changeSettingsField("titleFontSize", val);
  }

  function titleChange(val) {
    let newViz = { ...viz };
    newViz.title = val;
    newViz.titleValue = val;
    newViz.designSettings.artificialTitle = false;
    updateViz(newViz);
  }

  function showFilterSubtitle() {
    if (viz.filters) {
      let filters = JSON.parse(viz.filters);
      return Object.keys(filters).length > 0;
    }
    return false;
  }

  return (
    <>
      <div
        key={"titles"}
        className={`${styles.header} ${visible ? styles.headervisible : ""}`}
        onClick={!visible ? setVisible : undefined}
      >
        <FlexRow
          onClick={visible ? setVisible : undefined}
          style={{ cursor: "pointer" }}
          className={styles.accordionTitle}
        >
          <div style={{ display: "flex", alignItems: "center", gap: "1em" }}>
            {title}{" "}
            <i style={{ fontSize: "1.2em" }} className="bi bi-fonts"></i>
          </div>

          <span className={styles.accordionicon}>
            <i className="bi bi-caret-left-fill"></i>
          </span>
        </FlexRow>

        {visible && (
          <div className={styles.body} style={{ gap: "7px" }}>
            <Switch
              checked={viz.designSettings.hasTitle}
              onCheck={(val) => changeSettingsField("hasTitle", val)}
            >
              Chart Title
            </Switch>

            {viz.designSettings.hasTitle && (
              <>
                {/* {hasCustomTitle && (
                <div className={styles.setting}>
                  <Label
                    style={{
                      fontWeight: "700",
                      width: "fit-content",
                      paddingLeft: "20px",
                      fontSize: ".9em",
                    }}
                    labelIcon={
                      viz.designSettings.artificialTitle ? null : (
                        <i className="bi bi-info-square"></i>
                      )
                    }
                    tooltipText={genTitle()}
                  >
                    <div className={styles.linkButton} onClick={revertBtn}>
                      {oldTitle ? (
                        <>
                          <i className="bi bi-arrow-counterclockwise"></i>
                          {` Undo`}
                        </>
                      ) : (
                        `Use Question as Title`
                      )}
                    </div>
                  </Label>
                </div>
              )} */}

                <TextTool value={viz.title} onChange={titleChange}>
                  Text
                </TextTool>

                <NumSliderTool
                  val={
                    viz.designSettings.valueTitleSize
                      ? viz.designSettings.valueTitleSize
                      : 17
                  }
                  min={5}
                  max={60}
                  step={1}
                  onChange={(val) => changeSettingsField("valueTitleSize", val)}
                >
                  Size
                </NumSliderTool>

                <SelectTool
                  options={fonts}
                  value={
                    viz?.designSettings?.titleFont
                      ? fonts.find(
                          (v) => v.value === viz?.designSettings?.titleFont
                        )
                      : fonts.find((v) => v.value === "Poppins, sans-serif")
                  }
                  onChange={(v) => changeSettingsField("titleFont", v.value)}
                >
                  Font
                </SelectTool>

                <Color
                  color={
                    viz.designSettings.titleColor
                      ? viz.designSettings.titleColor
                      : "#000000"
                  }
                  onChange={(val) => changeSettingsField("titleColor", val)}
                  style={{ paddingTop: "5px" }}
                >
                  Color
                </Color>

                <Color
                  color={
                    viz.designSettings.titleBackgroundColor
                      ? viz.designSettings.titleBackgroundColor
                      : "#ffffff"
                  }
                  onChange={(val) =>
                    changeSettingsField("titleBackgroundColor", val)
                  }
                >
                  Title Background Color
                </Color>

                <NumSliderTool
                  val={
                    viz.designSettings.titleBorderRadius
                      ? viz.designSettings.titleBorderRadius
                      : 0
                  }
                  min={0}
                  max={60}
                  step={1}
                  onChange={(val) =>
                    changeSettingsField("titleBorderRadius", val)
                  }
                >
                  Title Border Radius
                </NumSliderTool>

                {viz.type != ValueType && (
                  <div
                    className={styles.setting2}
                    style={{
                      gap: ".5em",
                      fontSize: "1rem",
                    }}
                  >
                    <ChartLabel>Alignment</ChartLabel>

                    <div
                      className={`${styles.itemA} ${
                        viz.designSettings.titleAlignment === "start" &&
                        styles.activeItem
                      } `}
                      onClick={() =>
                        changeSettingsField("titleAlignment", "start")
                      }
                    >
                      <i className="bi-text-left"></i>
                    </div>
                    <div
                      className={`${styles.itemA} ${
                        viz.designSettings.titleAlignment === "center" &&
                        styles.activeItem
                      } `}
                      onClick={() =>
                        changeSettingsField("titleAlignment", "center")
                      }
                    >
                      <i className="bi-text-center"></i>
                    </div>
                    <div
                      className={`${styles.itemA} ${
                        viz.designSettings.titleAlignment === "end" &&
                        styles.activeItem
                      } `}
                      onClick={() =>
                        changeSettingsField("titleAlignment", "end")
                      }
                    >
                      <i className="bi-text-right"></i>
                    </div>
                  </div>
                )}
                {viz.type != ValueType && (
                  <FlexCol align={"center"} style={{ paddingTop: "5px" }}>
                    <ChartLabel>Title Padding</ChartLabel>
                    <FlexCol gap={".5rem"} align={"center"}>
                      <div
                        className={`${styles.itemB} `}
                        style={{ flexDirection: "column" }}
                      >
                        <i className="bi-arrow-bar-up"></i>
                        <NumberInput
                          startNumber={
                            viz.designSettings?.paddingTopTitle
                              ? viz.designSettings.paddingTopTitle
                              : 0
                          }
                          handleNumberChange={(val) =>
                            changeSettingsField("paddingTopTitle", val)
                          }
                          min={0}
                          max={500}
                        ></NumberInput>
                      </div>
                      <FlexRow gap="5px" justify={"center"}>
                        {" "}
                        <div className={`${styles.itemB} `}>
                          <i className="bi-arrow-bar-left"></i>
                          <NumberInput
                            startNumber={
                              viz.designSettings?.paddingLeftTitle
                                ? viz.designSettings.paddingLeftTitle
                                : 0
                            }
                            handleNumberChange={(val) =>
                              changeSettingsField("paddingLeftTitle", val)
                            }
                            min={0}
                            max={500}
                          ></NumberInput>
                        </div>
                        <div className={`${styles.itemB} `}>
                          <NumberInput
                            startNumber={
                              viz.designSettings?.paddingRightTitle
                                ? viz.designSettings.paddingRightTitle
                                : 0
                            }
                            handleNumberChange={(val) =>
                              changeSettingsField("paddingRightTitle", val)
                            }
                            min={0}
                            max={500}
                          ></NumberInput>
                          <i className="bi-arrow-bar-right"></i>
                        </div>
                      </FlexRow>
                      <div
                        className={`${styles.itemB} `}
                        style={{ flexDirection: "column" }}
                      >
                        <NumberInput
                          startNumber={
                            viz.designSettings?.paddingBottomTitle
                              ? viz.designSettings.paddingBottomTitle
                              : 0
                          }
                          handleNumberChange={(val) =>
                            changeSettingsField("paddingBottomTitle", val)
                          }
                          min={0}
                          max={500}
                        ></NumberInput>
                        <i className="bi-arrow-bar-down"></i>
                      </div>
                    </FlexCol>{" "}
                  </FlexCol>
                )}
              </>
            )}

            {showFilterSubtitle() && (
              <Switch
                checked={viz.designSettings.showFilterSubtitle}
                onCheck={(val) =>
                  changeSettingsField("showFilterSubtitle", val)
                }
              >
                Show Filter Subtitle
              </Switch>
            )}
          </div>
        )}
      </div>
    </>
  );
};
