import React, { useState, useEffect } from "react";
import styles from "./ImportHow.module.scss";
import Importer from "./Importer";
import FlexRow from "components/layouts/FlexRow/FlexRow";
import Icon from "components/Icon/Icon";
import Button from "components/Button/Button";
import ColumnMapping from "components/ContactCleaner2.0/ColumnMapping";
import { csv2json } from "csv42";
import { SelectFieldCustom, TextFieldSimple } from "components/inputs";
import Stat from "components/tables/BasicTable/SurveyWidgets/Stat";
import Checkbox from "components/inputs/input_fields/CheckboxBlue/Checkbox";

import { useGetContactUpdates } from "api/resources/contacts/contacts";
import { Loading as LoadingUpload } from "components/LoadingUpload/Loading";
import { Loading } from "components/Loading Rectangle/Loading";
import { useNavigate } from "react-router-dom";
import axios from "axios";

function ImportHow({ cancel, user, organization, objectColumns, audienceId }) {
  const [type, setType] = useState();
  const [page, setPage] = useState(0);

  return (
    <div className={styles.page}>
      {page === 0 && (
        <>
          {" "}
          <div className={styles.headerContainer}>
            {" "}
            <span className={styles.header_4}>
              How would you like you import your contacts?
            </span>
          </div>
          <div className={styles.content}>
            <div
              className={`${styles.box} ${type === "file" && styles.boxfill}`}
              onClick={() => {
                setType("file");
              }}
            >
              <FlexRow start gap={".5rem"}>
                <Icon
                  iconName={"file-earmark-arrow-up"}
                  style={{ fontSize: "1.2rem" }}
                  className={styles.icon}
                ></Icon>{" "}
                <h1 style={{ margin: "0" }}>CSV File</h1>
              </FlexRow>
              <div className={styles.label5}>Import from a csv file.</div>
            </div>
            {/* <div
              className={`${styles.box} ${type === "manual" && styles.boxfill}`}
              onClick={() => {
                setType("manual");
              }}
            >
              <FlexRow start gap={".5rem"}>
                <Icon
                  iconName={"pencil-square"}
                  style={{ fontSize: "1.2rem" }}
                  className={styles.icon}
                ></Icon>
                <h1 style={{ margin: "0" }}>Table Input</h1>
              </FlexRow>{" "}
              <div className={styles.label5}>
                Import from a manual input table.
              </div>
            </div>{" "} */}
            <div
              className={`${styles.box} ${type === "sync" && styles.boxfill}`}
              //   onClick={() => {
              //     setType("sync");
              //   }}
            >
              <FlexRow start gap={".5rem"}>
                <Icon
                  iconName={"arrow-repeat"}
                  style={{ fontSize: "1.2rem" }}
                  className={styles.icon}
                ></Icon>{" "}
                <h1 style={{ margin: "0" }}>
                  Sync Contacts{" "}
                  <span className={styles.section_label6}>(coming soon)</span>
                </h1>
              </FlexRow>
              <div className={styles.label5}>
                Sync your contacts with an outside source account like
                Salesforce or Hubspot.
              </div>
            </div>
          </div>
          <div className={styles.headerFooter}>
            {page === 0 && (
              <Button link onClick={cancel}>
                Cancel
              </Button>
            )}
            <Button
              blue
              shadow
              style={{
                gap: ".75em",
                fontWeight: "400",
              }}
              height={2.4}
              padding={"0 1rem"}
              disable={!type}
              onClick={() => setPage((page) => (page += 1))}
            >
              Next
            </Button>
          </div>
        </>
      )}

      {type === "file" && page === 1 && (
        <FileUpload
          setType={() => {
            setType(null);
            setPage(0);
          }}
          objectColumns={objectColumns}
          organizationId={organization?.id}
          userId={user?.id}
          cancel={cancel}
          audienceId={audienceId}
        ></FileUpload>
      )}
    </div>
  );
}

export default ImportHow;

function FileUpload({
  setType,
  objectColumns,
  organizationId,
  userId,
  cancel,
  audienceId,
}) {
  const [page, setPage] = useState(0);
  const [selectedFile, setSelectedFile] = useState(null);
  const [headers, setHeaders] = useState([]);
  const [headerMap, setHeaderMap] = useState({});
  const [fileName, setFileName] = useState("");
  const [checkPrefix, setCheckPrefix] = useState(true);
  const [checkSuffix, setCheckSuffix] = useState(true);
  const [checkSingle, setCheckSingle] = useState(true);
  const [checkEmailSyntax, setCheckEmailSyntax] = useState(true);
  const [emailme, setEmailme] = useState(true);
  const [data, setData] = useState([]);
  const [identityFields, setIdentityFields] = useState([]);
  const [checked, setChecked] = useState("add-update");
  const [importing, setImporting] = useState(false);
  const navigate = useNavigate();

  function startImport() {
    const url = process.env.REACT_APP_UPLOADER_API_KEY;
    const formData = new FormData();
    console.log(headerMap);
    debugger;

    formData.append("file", selectedFile);
    formData.append("columnMap", JSON.stringify(headerMap));

    // let finalMap = {};
    // debugger;
    // let keys = Object.keys(headerMap);
    // for (let k of keys) {
    //   if (headerMap[k]?.columns?.length > 0 || headerMap[k]?.new) {
    //     if (firstname.test(k.toLowerCase())) {
    //       finalMap["firstName"] = headerMap[k];
    //     } else if (lastname.test(k.toLowerCase())) {
    //       finalMap["lastName"] = headerMap[k];
    //     } else if (k.toLowerCase() === "email") {
    //       finalMap["email"] = headerMap[k];
    //     } else {
    //       finalMap[k] = headerMap[k];
    //     }
    //   }
    // }
    const config = {
      withCredentials: true,
      headers: {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
      },
      params: {
        audienceId: audienceId ? audienceId : "",
        organizationId: organizationId,
        appUserId: userId,
        singleCharacterOrLetter: checkSingle,
        verifyDomain: false,
        removeDuplicatesByEmail: false,
        checkForPrefixes: checkPrefix,
        checkForSuffixes: checkSuffix,
        verifyEmailSyntax: checkEmailSyntax,
        removeWhitespace: true,
        pNonEmptyColumns: true,
        importType: checked,
        columnMap: "",
        sendEmail: emailme,
        sendNotification: true,
        type: "",
        upload_id: "",
      },
    };

    axios
      .post(url, formData, config)
      .then((response) => {
        // setWaitingForFileUpload(false);
        // setUploadId(response.data?.upload_id);
        cancel();
        // setImporting(false);
      })
      .catch((error) => {
        console.error(error);
        // setWaitingForFileUpload(false);
        // setFaliure(error);
      });
  }

  return importing ? (
    <>
      <div className={styles.uploading}>
        {" "}
        Import process initiated. Feel free to leave this page. <br /> You will
        be notified when the process is complete
        <LoadingUpload height={300} width={300} />
        <Button
          link
          // onClick={() => navigate("/previous-imports/" + uploadId)}
        >
          Click here to view upload progress
        </Button>
      </div>
    </>
  ) : (
    <>
      {page === 0 && (
        <ChooseFile
          selectedFile={selectedFile}
          setSelectedFile={setSelectedFile}
          setHeaderMap={setHeaderMap}
          setHeaders={setHeaders}
          objectColumns={objectColumns}
          setFileName={setFileName}
          setData={setData}
          setIdentityFields={setIdentityFields}
          identityFields={identityFields}
        ></ChooseFile>
      )}
      {page === 1 && (
        <ChooseMap
          headerMap={headerMap}
          headers={headers}
          setHeaderMap={setHeaderMap}
          setHeaders={setHeaders}
          objectColumns={objectColumns}
        ></ChooseMap>
      )}

      {page === 2 && (
        <ChooseIdentification
          headerMap={headerMap}
          headers={headers}
          setHeaderMap={setHeaderMap}
          setHeaders={setHeaders}
          objectColumns={objectColumns}
          checkPrefix={checkPrefix}
          checkEmailSyntax={checkEmailSyntax}
          checkSuffix={checkSuffix}
          checkSingle={checkSingle}
          setCheckEmailSyntax={setCheckEmailSyntax}
          setCheckPrefix={setCheckPrefix}
          setCheckSuffix={setCheckSuffix}
          setCheckSingle={setCheckSingle}
          checked={checked}
          setChecked={setChecked}
        ></ChooseIdentification>
      )}
      {page === 3 && (
        <UploadReview
          headerMap={headerMap}
          headers={headers}
          setHeaderMap={setHeaderMap}
          setHeaders={setHeaders}
          objectColumns={objectColumns}
          fileName={fileName}
          setFileName={setFileName}
          emailme={emailme}
          setEmailme={setEmailme}
          data={data}
          checked={checked}
          organizationId={organizationId}
        ></UploadReview>
      )}

      <div className={styles.headerFooter}>
        {page === 0 && (
          <FlexRow start gap={".5rem"}>
            <i className="bi-filetype-csv"></i>
            <div className={`${styles.link} ${styles.black}`}>
              Download a sample CSV file
            </div>
          </FlexRow>
        )}
        {page === 0 && (
          <Button link onClick={() => setType(null)}>
            Previous
          </Button>
        )}

        {page > 0 && (
          <Button link onClick={() => setPage((page) => (page -= 1))}>
            Previous
          </Button>
        )}

        {page <= 2 && (
          <Button
            blue
            shadow
            style={{
              gap: ".75em",
              fontWeight: "400",
            }}
            height={2.4}
            padding={"0 1rem"}
            disable={
              (page === 0 && !selectedFile) ||
              (page === 2 && !findKeyWithEmailColumn(headerMap, "email"))
            }
            onClick={() => setPage((page) => (page += 1))}
          >
            Next
          </Button>
        )}

        {page >= 3 && (
          <Button
            blue
            shadow
            style={{
              gap: ".75em",
              fontWeight: "400",
            }}
            height={2.4}
            padding={"0 1rem"}
            disable={page === 0 && !selectedFile}
            onClick={() => {
              setImporting(true);
              startImport();
            }}
          >
            Import Contacts
          </Button>
        )}
      </div>
    </>
  );
}

function UploadReview({
  headers,
  headerMap,
  setHeaderMap,
  fileName,
  setFileName,
  emailme,
  setEmailme,
  data,
  checked,
  organizationId,
}) {
  const [loading, setLoading] = useState(false);
  const [numbers, setNumbers] = useState({});
  const getNumbers = useGetContactUpdates();

  function checkImport(field) {
    let contactEmails = [];

    setLoading(true);
    for (let c of data) {
      if (field?.name in c && c[field?.name]) {
        contactEmails.push(c[field?.name]);
      }
    }
    getNumbers.mutate(
      {
        contacts: contactEmails,
        checked: checked,
        orgId: organizationId,
      },
      {
        onSuccess: (data) => {
          setNumbers(data.values);
          setLoading(false);
        },
      }
    );
  }

  useEffect(() => {
    let idField = findKeyWithEmailColumn(headerMap, "email");
    checkImport(idField);
  }, []);

  return (
    <div className={styles.uploadFile}>
      <div className={styles.headerContainer}>
        {" "}
        <span className={styles.header_4}>Import Contacts</span>
        <span className={styles.label5}>
          View your final details and settings before import
        </span>
      </div>
      <div className={styles.fileContent}>
        <div className={styles.information}>
          {!loading && (
            <Stat
              title={"Contacts to be created"}
              value={numbers?.create}
            ></Stat>
          )}
          {loading && (
            <div className={styles.loadingContainer}>
              <Loading height={100}></Loading>
            </div>
          )}
          {!loading && (
            <Stat
              title={"Contacts to be updated"}
              value={numbers?.update}
            ></Stat>
          )}
          {loading && (
            <div className={styles.loadingContainer}>
              <Loading height={100}></Loading>
            </div>
          )}
        </div>
        <div
          className={styles.headerContainer}
          style={{ border: "none", gap: "0" }}
        >
          {" "}
          <span
            className={styles.header_5}
            style={{ margin: "0", color: "black" }}
          >
            Name your Import
          </span>
          <span className={styles.label5} style={{ paddingBottom: ".5rem" }}>
            This is the name that would show up when filtering contacts by
            "Import"
          </span>
          <TextFieldSimple
            value={fileName}
            onChange={setFileName}
          ></TextFieldSimple>
          <FlexRow start style={{ paddingTop: "1rem" }}>
            <Checkbox
              checked={emailme}
              onChange={(e) => setEmailme(e.target.checked)}
            ></Checkbox>{" "}
            <span className={styles.label5}>
              Send me an email when finished
            </span>
          </FlexRow>
        </div>
      </div>
    </div>
  );
}

function ChooseIdentification({
  headers,
  headerMap,
  setHeaderMap,
  objectColumns,
  setCheckEmailSyntax,
  setCheckPrefix,
  setCheckSuffix,
  setCheckSingle,
  checkPrefix,
  checkEmailSyntax,
  checkSuffix,
  checkSingle,
  checked,
  setChecked,
}) {
  //   const identities = headers.filter((h) => headerMap[h?.name].identity);
  function updateMap(vals, header) {
    let temp = { ...headerMap };
    let key = findKeyWithEmailColumn(temp, "email");
    if (key) {
      temp[key?.id].column = {};
    }

    temp[header?.name].column = vals;
    setHeaderMap(temp);
  }
  return (
    <div className={styles.uploadFile}>
      <div className={styles.headerContainer}>
        {" "}
        <span className={styles.header_4}>Import Settings</span>
        <span className={styles.label5}>
          Finalize settings to be used on import
        </span>
      </div>

      <div className={styles.fileContent}>
        <div
          className={styles.headerContainer}
          style={{ border: "none", padding: "0 .5rem" }}
        >
          {" "}
          {/* <span className={styles.header_5} style={{ margin: "0" }}>
            Contact Identity
          </span> */}
          <span className={styles.label5} style={{ paddingBottom: ".5rem" }}>
            This column will be used to identify your contacts
          </span>
        </div>
        <table className={styles.maptable} style={{ marginBottom: "1rem" }}>
          <thead>
            <tr>
              <th>Column from file</th>
              <th></th>
              <th>Identity Column in Reaction</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <SelectFieldCustom
                  value={findKeyWithEmailColumn(headerMap, "email")}
                  onChange={(vals) => {
                    updateMap(
                      {
                        id: "email",
                        name: "Email",
                        label: "Email",
                        name: "email",
                        value: "email",
                        icon: "at",
                      },
                      vals
                    );
                  }}
                  icon={<Icon blue iconName={"layout-three-columns"}></Icon>}
                  options={headers}
                ></SelectFieldCustom>
              </td>
              <td
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <i
                  className="bi-arrow-right"
                  style={{ fontSize: "1.1rem" }}
                ></i>
              </td>

              <td>
                <div className={styles.columninfile}>
                  <Icon iconName={"envelope"}></Icon>
                  Email
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <FlexRow
          style={{
            gap: "1rem",
            width: "100%",
            justifyContent: "space-around",
            alignItems: "flex-start",
          }}
        >
          <div
            className={styles.headerContainer}
            style={{ border: "none", padding: "0", width: "300px" }}
          >
            {" "}
            <span className={styles.header_5} style={{ margin: "0" }}>
              Update Behavior
            </span>
            {/* <span className={styles.label5} style={{ paddingBottom: ".5rem" }}>
            This is the name that would show up when filtering contacts by
            "Import"
          </span> */}
            <div className={styles.radioLabel}>
              <div
                style={{
                  width: "41px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {" "}
                <input
                  id="sortByOg"
                  type="radio"
                  name="sortByOg"
                  value="none"
                  onChange={(e) => setChecked("add-update")}
                  checked={checked === "add-update"}
                  style={{ borderRadius: "50%" }}
                />
              </div>
              <div className={styles.label5}>Add new and Update existing </div>
            </div>
            <div className={styles.radioLabel}>
              <div
                style={{
                  width: "42px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {" "}
                <input
                  id="sortByOg"
                  type="radio"
                  name="sortByOg"
                  value="none"
                  onChange={(e) => setChecked("add")}
                  checked={checked === "add"}
                  style={{ borderRadius: "50%" }}
                />
              </div>
              <div className={styles.label5}>Add new contacts only</div>
            </div>
            <div className={styles.radioLabel}>
              <div
                style={{
                  width: "41px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {" "}
                <input
                  id="sortByOg"
                  type="radio"
                  name="sortByOg"
                  value="none"
                  onChange={(e) => setChecked("update")}
                  checked={checked === "update"}
                  style={{ borderRadius: "50%" }}
                />
              </div>
              <div className={styles.label5}>Update existing contacts only</div>
            </div>
          </div>

          <div
            className={styles.headerContainer}
            style={{ border: "none", padding: "0", gap: "0", width: "300px" }}
          >
            <span className={styles.header_5} style={{ margin: "0" }}>
              Check Contact Fields for...
            </span>
            {/* <FlexRow start style={{ paddingTop: "1rem" }}>
              <Checkbox
                checked={checkSingle}
                onChange={(e) => setCheckSingle(e.target.checked)}
              ></Checkbox>{" "}
              <span className={styles.label5}>Single character or letter</span>
            </FlexRow>
            <FlexRow start style={{ paddingTop: "1rem" }}>
              <Checkbox
                checked={checkPrefix}
                onChange={(e) => setCheckPrefix(e.target.checked)}
              ></Checkbox>{" "}
              <span className={styles.label5}>Prefixes</span>
            </FlexRow>
            <FlexRow start style={{ paddingTop: "1rem" }}>
              <Checkbox
                checked={checkSuffix}
                onChange={(e) => setCheckSuffix(e.target.checked)}
              ></Checkbox>{" "}
              <span className={styles.label5}>Suffixes</span>
            </FlexRow> */}
            <FlexRow start style={{ paddingTop: "1rem" }}>
              <Checkbox
                checked={checkEmailSyntax}
                onChange={(e) => setCheckEmailSyntax(e.target.checked)}
              ></Checkbox>{" "}
              <span className={styles.label5}>Wrong Email Syntax</span>
            </FlexRow>
          </div>
        </FlexRow>
      </div>
    </div>
  );
}

// Function to find the key with column: "email"
const findKeyWithEmailColumn = (obj, column) => {
  for (const key in obj) {
    if (obj[key]?.column?.name === column) {
      return {
        name: obj[key]?.column.name,
        label: obj[key]?.column.name,
        id: key,
      }; // Return the first key that matches
    }
  }
  return null; // Return null if no key is found
};

function ChooseMap({
  headers,
  headerMap,
  setHeaderMap,
  setHeaders,
  objectColumns,
}) {
  console.log(headerMap);
  function getFieldOptions() {
    let custom = [];

    custom.push({
      id: "no-import",
      value: "no-import",
      icon: "eye-slash",
      label: "Don't Import",
    });
    custom.push({
      id: "new-column",
      value: "new-column",
      icon: "plus",
      label: "Create New Column",
    });
    for (let f of objectColumns) {
      if (f?.id) {
        if (headerMap && !findKeyWithEmailColumn(headerMap, f?.name)) {
          custom.push({
            id: f?.id,
            value: f?.value,
            icon: f?.icon ? f?.icon : "person",
            label: f?.label ? f?.label : f?.name,
          });
        } else if (!headerMap) {
          custom.push({
            id: f.id,
            value: f?.value,
            icon: f?.icon ? f?.icon : "person",
            label: f?.label ? f?.label : f?.name,
          });
        }
      }
    }

    // let custom = fetchCustomFields.data?.getCurrOrgFields?.map((f) => {
    //   return { id: f.id, value: f.name, label: f.displayName };
    // });
    // return [
    //   {
    //     id: "firstName",
    //     name: "firstName",
    //     displayName: "First Name",
    //     label: "First Name",
    //     value: "firstName",
    //   },
    //   {
    //     id: "lastName",
    //     name: "lastName",
    //     displayName: "Last Name",
    //     label: "Last Name",
    //     value: "lastName",
    //   },
    //   {
    //     id: "email",
    //     name: "email",
    //     displayName: "Email",
    //     label: "Email",
    //     value: "email",
    //   },
    //   ...custom,
    // ];
    return custom;
  }
  const columnOptions = getFieldOptions();

  function updateMap(vals, header) {
    let temp = { ...headerMap };
    if (vals.id === "no-import") {
      temp[header?.name] = {};
    } else {
      if (vals.id === "new-column") {
        temp[header?.name].new = true;
      }
      if (vals.id != "email") {
        temp[header?.name].identity = false;
      } else {
        temp[header?.name].identity = true;
      }
      temp[header?.name].column = objectColumns.find((o) => o?.id === vals?.id);
    }
    setHeaderMap(temp);
  }
  return (
    <div className={styles.uploadFile}>
      <div className={styles.headerContainer}>
        {" "}
        <span className={styles.header_4}>Map Columns</span>
        <span className={styles.label5}>
          Make sure columns from your file are mapped correctly to the Reaction
          contact columns
        </span>
      </div>
      <div className={styles.fileContent}>
        <table className={styles.maptable}>
          <thead>
            <tr>
              <th>Columns in file</th>
              <th></th>
              <th>Columns in Reaction</th>
            </tr>
          </thead>
          <tbody>
            {headers &&
              headers?.length > 0 &&
              headers?.map((header, i) => (
                <tr key={i}>
                  <td>
                    <div className={styles.columninfile}>
                      <Icon blue iconName={"layout-three-columns"}></Icon>
                      {header?.name}
                    </div>
                  </td>
                  <td
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <i
                      className="bi-arrow-right"
                      style={{ fontSize: "1.1rem" }}
                    ></i>
                  </td>
                  <td>
                    <SelectFieldCustom
                      value={
                        headerMap[header?.name]?.column
                          ? headerMap[header?.name]?.column
                          : {
                              id: "no-import",
                              value: "no-import",
                              icon: "plus",
                              label: "Create New Column",
                            }
                      }
                      icon={
                        <i
                          className={`bi-${
                            headerMap[header?.name]?.column
                              ? headerMap[header?.name]?.column?.icon
                              : "eye-slash"
                          }`}
                        ></i>
                      }
                      onChange={(vals) => {
                        updateMap(vals, header);
                      }}
                      options={columnOptions}
                    ></SelectFieldCustom>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

const firstname = /first[\s_.]?name/;
const lastname = /last[\s_.]?name/;
function checkMap(field, headerMap, customFields, index) {
  let fields = {};
  let identityField = false;
  // if (firstname.test(field?.name?.toLowerCase())) {
  //   customFields.find()
  //   fields = {
  //     id: "firstName",
  //     name: "firstName",
  //     displayName: "First Name",
  //     icon: "person-circle",
  //     label: "First Name",
  //     value: "firstName",
  //   };
  // }
  // if (lastname.test(field?.name?.toLowerCase())) {
  //   fields = {
  //     id: "lastName",
  //     name: "lastName",
  //     displayName: "Last Name",
  //     icon: "person-circle",
  //     label: "Last Name",
  //     value: "lastName",
  //   };
  // }
  // if (field?.name?.toLowerCase() === "email") {
  //   fields = {
  //     id: "email",
  //     name: "email",
  //     displayName: "Email",
  //     icon: "at",
  //     label: "Email",
  //     value: "email",
  //   };
  //   identityField = true;
  // }
  // if (field?.name?.toLowerCase() === "unsubscribed") {
  //   fields = {
  //     id: "unsubscribed",
  //     name: "unsubscribed",
  //     displayName: "unsubscribed",
  //     icon: "heart",
  //     label: "Unsubscribed",
  //     value: "unsubscribed",
  //   };
  // }

  if (customFields && customFields?.length > 0) {
    for (let f of customFields) {
      if (
        (f.name === "lastName" && lastname.test(field?.name?.toLowerCase())) ||
        (f.name === "firstName" &&
          firstname.test(field?.name?.toLowerCase())) ||
        f?.name === field?.name?.toLowerCase() ||
        f?.name?.toLowerCase() === field?.name?.toLowerCase() ||
        f?.name?.toLowerCase() === field?.label?.toLowerCase() ||
        f?.displayName?.toLowerCase() === field?.name?.toLowerCase() ||
        f?.name?.toLowerCase() ===
          field?.name?.toLowerCase() + " " + field?.objectname?.toLowerCase() ||
        f?.displayName?.toLowerCase() ===
          field?.name?.toLowerCase() + " " + field?.objectname?.toLowerCase() ||
        f?.salesforceColumn === field?.name
      ) {
        fields = {
          ...f,
          label: f?.label,
          value: f.name,
          salesforceColumn: f?.salesforceColumn,
        };
      }
    }
  }

  if (!fields?.name) {
    fields = {
      id: "no-import",
      value: "no-import",
      icon: "eye-slash",
      label: "Don't Import",
    };
  }

  if (field?.objectname) {
    headerMap[field?.name] = {
      column: fields,
      sfName: field.sfName,
      new: false,
      index: index,
      identity: identityField,
    };
  } else {
    headerMap[field?.name] = {
      column: fields,
      sfName: field.sfName,
      new: false,
      index: index,
      identity: identityField,
    };
  }

  return headerMap;
}

function ChooseFile({
  selectedFile,
  setSelectedFile,
  setHeaders,
  setHeaderMap,
  setData,
  objectColumns,
  setFileName,
  setIdentityFields,
}) {
  const [isDragging, setIsDragging] = useState(false);

  // Handle when a file is selected via the browse button
  //   const handleFileChange = (event) => {
  //     const file = event.target.files[0];
  //     if (file) {
  //       setSelectedFile(file);
  //     }
  //   };

  //handle onChange when you upload file
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedFile(file);
      setFileName(file?.name);
    }

    if (file) {
      const reader = new FileReader();
      reader.onload = function (e) {
        const text = e.target.result;
        const data = csv2json(text);

        // setData(data);
        let heads = Object.keys(data[0]);

        let headMap = {};
        let final = [];
        let i = 0;
        for (let field of heads) {
          headMap[field] = [];
          let newfield = {
            id: i,
            name: field,
            name: field,
            enabled: true,
            cell_style: null,
            canSort: false,
            value: field,
            label: field,
            // cell_style: (val) => <div>{checkFields(val, field)}</div>,
          };
          final.push(newfield);
          checkMap(newfield, headMap, objectColumns, i, setIdentityFields);
          i++;
        }

        setHeaders(final);
        setData(data);
        setHeaderMap(headMap);
      };
      reader.readAsText(e.target.files[0]);
    }
  };

  // Handle the drag over event
  const handleDragOver = (event) => {
    event.preventDefault();
    setIsDragging(true);
  };

  // Handle the drag leave event
  const handleDragLeave = () => {
    setIsDragging(false);
  };

  // Handle the drop event
  const handleDrop = (event) => {
    event.preventDefault();
    setIsDragging(false);
    const file = event.dataTransfer.files[0];
    if (file && file.type === "text/csv") {
      setSelectedFile(file);
    } else {
      alert("Please upload a CSV file");
    }
  };

  // Handle clicking the area to open the file dialog
  const handleBrowseClick = () => {
    document.getElementById("fileInput").click();
  };

  return (
    <div className={styles.uploadFile}>
      <div className={styles.headerContainer}>
        {" "}
        <span className={styles.header_4}>Upload a CSV file</span>
        <span className={styles.label5}>
          Make sure the file includes the contact name and either an email or a
          phone number.
        </span>
      </div>
      <div className={styles.fileContent}>
        <div
          className={`${styles.dropArea} ${isDragging ? "dragging" : ""}`}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
          onClick={handleBrowseClick}
        >
          {selectedFile ? (
            <div className={styles.label5}>{selectedFile.name}</div>
          ) : (
            <div className={styles.drop}>
              <Icon
                iconName={"cloud-upload"}
                style={{ fontSize: "2rem" }}
              ></Icon>
              <div className={styles.header_5}>
                Drag & Drop a CSV file here, or click to browse
              </div>
              <div className={styles.label5}>
                Files up to 10,000 rows are preferred, upload times are slow
                beyond 10,000
              </div>
            </div>
          )}
        </div>
        <input
          type="file"
          id="fileInput"
          accept=".csv"
          style={{ display: "none" }}
          onChange={handleFileChange}
        />
      </div>
    </div>
  );
}
