import { TextFieldSimple } from "components/inputs";
import styles from "./TextSettings.module.scss";
import NumberInputWithSlider from "components/NumberInputWithSlider/NumberInputWithSlider";

export function TextSettings({
  question,
  saveQuestion,
  dependents,
  setUpLogicResolver,
}) {
  function handlePlaceholderChange(placeholder) {
    // let newQuestion = { ...question };
    question.textQuestion.placeholder = placeholder;
    saveQuestion(question);
  }

  function changeCharLimit(limit) {
    if (isNaN(limit)) {
      return;
    }

    if (dependents) {
      for (let dependent of dependents.dependencies) {
        if (dependent?.keepLimitAbove > limit) {
          setUpLogicResolver("lower the limit on", "edit", question, () => {
            question.textQuestion.maxLength = limit;
            saveQuestion(question);
          });

          let curr = question.textQuestion.maxLength;
          question.textQuestion.maxLength = limit;
          saveQuestion(question);
          setTimeout(() => {
            // Let the number input go back
            question.textQuestion.maxLength = curr;
            saveQuestion(question);
          }, 500);
          return;
        }
      }
    }

    question.textQuestion.maxLength = limit;
    saveQuestion(question);
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        gap: "10px",
      }}
    >
      {!question.matrixQuestion && (
        <>
          <div
            className={styles.textField}
            style={{ width: "100%", fontSize: ".85em" }}
          >
            <TextFieldSimple
              value={question.textQuestion?.placeholder}
              placeholder="Placeholder"
              onBlur={handlePlaceholderChange}
              label="Placeholder"
            />
          </div>

          <NumberInputWithSlider
            val={question.textQuestion?.maxLength}
            onChange={changeCharLimit}
            min={10}
            max={10000}
            step={1}
            title={"Character Limit"}
          />
        </>
      )}
    </div>
  );
}
