import styles from "../SettingsAccordion.module.scss";
import { DoughnutType, PieType } from "../../QChart/QuestionChart";
import Checkbox from "components/inputs/input_fields/CheckboxBlue/Checkbox";
import { splitting } from "../SettingsAccordion";
import FlexRow from "components/layouts/FlexRow/FlexRow";
import { fonts, iconshapes } from "assets/functions/Variables";
import FlexCol from "components/layouts/FlexColumn/FlexCol";
import {
  TextTool,
  NumSliderTool,
  SelectTool,
  Color,
  ChartLabel,
  Switch,
} from "../Tools";

export const LegendSettings = ({
  viz,
  title,
  changeSettingsField,
  visible,
  setVisible,
}) => {
  const positionOptions = [
    { label: "Top", value: "top" },
    {
      label: "Left",
      value: "left",
    },
    {
      label: "Bottom",
      value: "bottom",
    },
    {
      label: "Right",
      value: "right",
    },
  ];

  function capitalize(title) {
    return title.charAt(0).toUpperCase() + title.slice(1);
  }

  function generateLegendTitle() {
    if (viz.type === DoughnutType || viz.type === PieType) {
      if (viz.pivotString && viz.pivotString !== "nps") {
        return capitalize(viz.pivotString);
      } else {
        return "Choices";
      }
    }

    if (viz.designSettings.split) {
      return capitalize(viz.designSettings.split);
    }
    return "Choices";
  }

  function handleTitleToggle(val) {
    if (val) {
      let title = generateLegendTitle();
      changeSettingsField("legendTitle", title);
    }
    changeSettingsField("hasLegendTitle", val);
  }

  return (
    <>
      <div
        key={"legend"}
        className={`${styles.header} ${visible ? styles.headervisible : ""}`}
        onClick={!visible ? setVisible : undefined}
      >
        <FlexRow
          onClick={visible ? setVisible : undefined}
          style={{ cursor: "pointer" }}
          className={styles.accordionTitle}
        >
          <div style={{ display: "flex", alignItems: "center", gap: "1em" }}>
            {title} <i style={{ fontSize: "1.2em" }} className="bi bi-map"></i>
          </div>

          <span className={styles.accordionicon}>
            <i className="bi bi-caret-left-fill"></i>
          </span>
        </FlexRow>

        {visible && (
          <div className={styles.body} style={{ gap: "7px" }}>
            <Switch
              checked={viz.designSettings.hasLegend}
              onCheck={(val) => changeSettingsField("hasLegend", val)}
            >
              Legend
            </Switch>
            {viz.designSettings.hasLegend && (
              <>
                {!splitting(viz) && (
                  <FlexCol gap="5px">
                    <ChartLabel>Show</ChartLabel>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "5px",
                        paddingLeft: "10px",
                      }}
                    >
                      <div className={styles.setting2}>
                        <Checkbox
                          checked={viz.designSettings.legendLabel}
                          onChange={(e) =>
                            changeSettingsField("legendLabel", e.target.checked)
                          }
                        />

                        <ChartLabel>Label</ChartLabel>
                      </div>
                      <div className={styles.setting2}>
                        <Checkbox
                          checked={viz.designSettings.legendValue}
                          onChange={(e) =>
                            changeSettingsField("legendValue", e.target.checked)
                          }
                        />

                        <ChartLabel>
                          {viz.designSettings.byPercent ? "Value (%)" : "Value"}
                        </ChartLabel>
                      </div>
                      {!viz.designSettings.byPercent && (
                        <div className={styles.setting2}>
                          <Checkbox
                            checked={viz.designSettings.legendPercent}
                            onChange={(e) =>
                              changeSettingsField(
                                "legendPercent",
                                e.target.checked
                              )
                            }
                          />

                          <ChartLabel>%</ChartLabel>
                        </div>
                      )}
                    </div>
                  </FlexCol>
                )}

                <SelectTool
                  value={positionOptions.find(
                    (opt) => opt.value === viz.designSettings.legendPosition
                  )}
                  onChange={(option) =>
                    changeSettingsField("legendPosition", option.value)
                  }
                  options={positionOptions}
                >
                  Position
                </SelectTool>

                <Color
                  color={
                    viz?.designSettings?.legendFontColor
                      ? viz?.designSettings?.legendFontColor
                      : "#000000"
                  }
                  onChange={(val) =>
                    changeSettingsField("legendFontColor", val)
                  }
                >
                  Font Color
                </Color>

                <Switch
                  checked={viz?.designSettings?.legendWidth}
                  onCheck={(val) =>
                    changeSettingsField("legendWidth", val ? "180px" : false)
                  }
                >
                  Manual Width
                </Switch>

                {viz?.designSettings?.legendWidth && (
                  <NumSliderTool
                    val={viz?.designSettings?.legendWidth}
                    onChange={(val) => changeSettingsField("legendWidth", val)}
                    min={50}
                    max={1000}
                    step={1}
                  >
                    Legend Width
                  </NumSliderTool>
                )}

                <NumSliderTool
                  val={viz?.designSettings?.legendSpacing}
                  onChange={(val) => changeSettingsField("legendSpacing", val)}
                  min={3}
                  max={40}
                  step={1}
                >
                  Spacing
                </NumSliderTool>

                <SelectTool
                  options={fonts}
                  value={
                    viz?.designSettings?.legendTextFont
                      ? fonts.find(
                          (v) => v.value === viz?.designSettings?.legendTextFont
                        )
                      : fonts.find((v) => v.value === "Poppins, sans-serif")
                  }
                  onChange={(v) =>
                    changeSettingsField("legendTextFont", v.value)
                  }
                >
                  Font
                </SelectTool>

                <NumSliderTool
                  val={viz?.designSettings?.legendTextFontSize}
                  onChange={(val) =>
                    changeSettingsField("legendTextFontSize", val)
                  }
                  min={3}
                  max={40}
                  step={1}
                >
                  Font Size
                </NumSliderTool>

                <SelectTool
                  options={iconshapes}
                  value={
                    viz?.designSettings?.legendTextShape
                      ? iconshapes.find(
                          (v) =>
                            v.value === viz?.designSettings?.legendTextShape
                        )
                      : iconshapes.find((v) => v.value === "bi-circle-fill")
                  }
                  onChange={(v) =>
                    changeSettingsField("legendTextShape", v.value)
                  }
                >
                  Shape
                </SelectTool>

                <NumSliderTool
                  val={viz?.designSettings?.legendTextShapeSize}
                  onChange={(val) =>
                    changeSettingsField("legendTextShapeSize", val)
                  }
                  min={10}
                  max={40}
                  step={1}
                >
                  Shape Size
                </NumSliderTool>

                <Switch
                  checked={viz.designSettings.hasLegendTitle}
                  onCheck={handleTitleToggle}
                >
                  Legend Title
                </Switch>

                {viz.designSettings.hasLegendTitle && (
                  <>
                    <TextTool
                      value={viz.designSettings.legendTitle}
                      onChange={(val) =>
                        changeSettingsField("legendTitle", val)
                      }
                    >
                      Title
                    </TextTool>

                    <NumSliderTool
                      val={viz.designSettings.legendFontSize}
                      onChange={(val) =>
                        changeSettingsField("legendFontSize", val)
                      }
                      min={3}
                      max={40}
                      step={1}
                    >
                      Title Font Size
                    </NumSliderTool>

                    <Color
                      color={
                        viz?.designSettings?.legendTitleColor
                          ? viz?.designSettings?.legendTitleColor
                          : "#000000"
                      }
                      onChange={(val) =>
                        changeSettingsField("legendTitleColor", val)
                      }
                    >
                      Title Font Color
                    </Color>
                  </>
                )}
              </>
            )}
          </div>
        )}
      </div>
    </>
  );
};
